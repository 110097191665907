import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import CategoryProductsService from '../../../../../../services/CategoryProduct';
import ProductsService from '../../../../../../services/Products';
import { Button } from '../../../../../../components/Button';
import Square from '../../../../../../components/Square';
import Loader from '../../../../../../components/Loader';
import ButtonsSaveCancel from '../../../../../../components/ButtonsSaveCancel';
import toast from '../../../../../../helpers/toast';
import { generateSlug } from '../../../../../../helpers/generateSlug';
import { Translate } from './translate';
import { Container, InfoContainer, ListContainer } from './style';

export function CategoryForm({
  category, disabled, setCategory, setDisabled, onSave,
}) {
  const translate = Translate();
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [loadingListOfProducts, setLoadingListOfProducts] = useState(true);

  useEffect(() => {
    (async () => {
      await getAllProducts();
      if (id) await getCategoryProduct();
      allowSaveForm();
    })();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      setCategory({ ...category, idProduct: products[0].id });
    }
  }, [products]);

  useEffect(() => {
    allowSaveForm();
  }, [category]);

  async function getCategoryProduct() {
    try {
      const categoryProduct = await CategoryProductsService.findById(id);
      setCategory({ ...categoryProduct, idProduct: categoryProduct.productID });
    } catch (error) {
      toast('danger', error.message);
    }
  }

  async function getAllProducts() {
    try {
      setLoadingListOfProducts(true);
      const productsRes = await ProductsService.findAll({ firstVideo: 'no' });
      setProducts(productsRes);
    } finally {
      setLoadingListOfProducts(false);
    }
  }

  function handleGenerateSlug() {
    setCategory({ ...category, slug: generateSlug(category?.namePt) });
  }

  async function handleSave() {
    onSave();
  }

  function allowSaveForm() {
    const hasNamePt = category.namePt?.length >= 3;
    const hasNameEn = category.nameEn?.length >= 3;
    const hasNameSlug = category.slug?.length >= 1;
    const productChosenIsIncorrect = category.idProduct === '' || category.idProduct === undefined;

    if (hasNamePt && hasNameEn && hasNameSlug && !productChosenIsIncorrect) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  return (
    <>
      {loadingListOfProducts
        ? (<Loader loading={loadingListOfProducts} />)
        : (
          <Container>
            <ListContainer>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textName}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <label htmlFor="name_pt">{translate.labelNamePt}</label>
                      <input
                        className="l-input-w"
                        type="text"
                        id="name_pt"
                        value={category.namePt}
                        onChange={(e) => {
                          setCategory({ ...category, namePt: e.target.value });
                          allowSaveForm();
                        }}
                      />
                    </div>
                    <div className="c-input">
                      <label htmlFor="name_en">{translate.labelNameEn}</label>
                      <input
                        className="l-input-w"
                        type="text"
                        id="name_en"
                        value={category.nameEn}
                        onChange={(e) => {
                          setCategory({ ...category, nameEn: e.target.value });
                          allowSaveForm();
                        }}
                      />
                    </div>
                  </div>
                </InfoContainer>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textSubCategory}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <select
                        id="has_subcategory"
                        value={category.hasSubCategory}
                        onChange={(e) => setCategory({ ...category, hasSubCategory: e.target.value })}
                      >
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>
                    </div>
                  </div>
                </InfoContainer>
                <div className="c-explanation">{translate.warningSubCategory}</div>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    Slug
                  </div>
                  <div className="c-right">
                    <Button color="yellow" onClick={handleGenerateSlug}>Gerar slug</Button>
                    <div className="c-input">
                      <input
                        className="l-input-w"
                        type="text"
                        id="slug"
                        value={category.slug}
                        onChange={(e) => {
                          setCategory({ ...category, slug: e.target.value });
                          allowSaveForm();
                        }}
                      />
                    </div>
                  </div>
                </InfoContainer>
                <div className="c-explanation">{translate.warningSlug}</div>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textOrdering}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <input
                        type="text"
                        id="ordering"
                        value={category.ordering}
                        onChange={(e) => setCategory({ ...category, ordering: e.target.value })}
                      />
                    </div>
                  </div>
                </InfoContainer>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    {translate.textTypeCategory}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <select
                        id="type-category"
                        value={category.typeCategory}
                        onChange={(e) => setCategory({ ...category, typeCategory: e.target.value })}
                      >
                        <option value="multi">Multi-User</option>
                        <option value="single">Single-User</option>
                      </select>
                    </div>
                  </div>
                </InfoContainer>
                <div className="c-explanation">{translate.warningTypeCategory}</div>
              </Square>
              <Square size="large">
                <InfoContainer>
                  <div className="c-left">
                    Produto
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <select
                        type="text"
                        id="product"
                        value={category.idProduct}
                        onChange={(e) => setCategory({ ...category, idProduct: e.target.value })}
                      >
                        {products && (
                          products?.map((product) => (
                            <option value={product.id} key={product.id}>
                              {product.namePt}
                              {' '}
                              -
                              {' '}
                              {product.nameEn}
                            </option>
                          )))}
                      </select>
                    </div>
                  </div>
                </InfoContainer>
                <div className="c-explanation">{translate.warningProduct}</div>
              </Square>
            </ListContainer>
            <ButtonsSaveCancel
              onSave={handleSave}
              goToWhenCancel="/admin/categories"
              disabled={disabled}
            />
          </Container>
        )}
    </>
  );
}

CategoryForm.propTypes = {
  category: PropTypes.object.isRequired,
  setCategory: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
