import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import UsersService from '../../../../services/Users';
import AccessGroupService from '../../../../services/AccessGroup';

import TitlePage from '../../../../components/TitlePage';
import BackButton from '../../../../components/BackButton';
import toast from '../../../../helpers/toast';

import { Translate } from './translate';
import { FormUser } from '../components/FormUser';

export function UserAdd() {
  const translate = Translate();
  const navigate = useNavigate();
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState({
    idClient: id, firstName: '', lastName: '', email: '', admin: 'N', language: 'pt-BR', perfil: '', type: 'normal', daysToTrial: 0, accessGroupID: '',
  });
  const [accessGroup, setAccessGroup] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [createByClientPath, setCreateByClientPath] = useState('');

  useEffect(() => {
    if (id) {
      setCreateByClientPath('client');
      setUserInfo({ ...userInfo, perfil: 'B2B' });
    } else {
      setCreateByClientPath('normal');
      setUserInfo({ ...userInfo, perfil: 'B2C' });
    }
    getAccessGroupList();
  }, []);

  useEffect(() => {
    isAllowedToSaveForm();
  }, [userInfo.firstName, userInfo.lastName, userInfo.email, userInfo.perfil, userInfo.accessGroupID]);

  async function getAccessGroupList() {
    const accessGroups = await AccessGroupService.getAll();
    setAccessGroup(accessGroups);
  }

  async function handleCreateUser(e) {
    e.preventDefault();
    setIsDisabled(true);
    try {
      const {
        idClient, firstName, lastName, email, admin, language, perfil, type, daysToTrial, accessGroupID,
      } = userInfo;
      const response = await UsersService.store(idClient, firstName, lastName, email, admin, language, perfil, type, daysToTrial, accessGroupID);
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      // eslint-disable-next-line no-unused-expressions
      createByClientPath === 'client' ? navigate(`/admin/users/client/${id}`) : navigate('/admin/users');
    }
  }

  function isAllowedToSaveForm() {
    const firstNameIsValid = userInfo.firstName.length >= 3;
    const lastNameIsValid = userInfo.firstName.length >= 3;
    const emailNameIsValid = userInfo.email.length >= 4;
    const perfilIsValid = userInfo.perfil !== '';
    const accessGroupisValid = userInfo.accessGroupID !== '' || (userInfo.perfil !== '' && userInfo.perfil === 'SHIFTER');

    if (firstNameIsValid && lastNameIsValid && emailNameIsValid && perfilIsValid && accessGroupisValid) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton to={createByClientPath === 'client' ? `/admin/users/client/${id}` : '/admin/users'} />
      </TitlePage>
      <FormUser
        user={userInfo}
        setUser={setUserInfo}
        createByClientPath={createByClientPath}
        accessGroup={accessGroup}
        isDisabled={isDisabled}
        onSave={handleCreateUser}
      />
    </>
  );
}
