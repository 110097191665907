import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const Panel = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.grayMenu};
  /* border: 1px solid ${({ theme }) => theme.gray2}; */
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;

  img {
    width: 80px;
  }

  .infos {
    margin-left: 16px;

    .edit-user {
      display: inline-block;
      margin-top: 8px;
      color: ${({ theme }) => theme.yellow};
    }
  }
`;

export const Field = styled.div`
  img {
    width: 80px;
    margin-bottom: 16px;
  }

  strong {
    font-weight: 500;
    margin-bottom: 8px;
  }

  .info {
    display: block;
    color: ${({ theme }) => theme.gray4};
    margin: 0px 0px 8px 16px;

    > strong {
      color: ${({ theme }) => theme.gray4};
    }
  }

  .array-view {
    background-color: ${({ theme }) => theme.grayMenu};
    border: 1px solid ${({ theme }) => theme.gray2};
    border-radius: 4px;
    padding: 16px;
  }
`;

