/* eslint-disable react/no-array-index-key */
import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import { B2BDataBaseFilterContext } from '../../B2BDatabaseFilter.context';
import Modal from '../../../../../components/Modal';
import Loader from '../../../../../components/Loader';
import { ModalContent } from './component/ModalContent';
import IconAdd from '../../../../../styles/logos/add.svg';
import IconClear from '../../../../../styles/logos/icon_clear.svg';
import { Translate } from './translate';
import { Container, InputContainer, ListContainer } from './style';

export function MultiSelectText({ label, keyValue }) {
  const translate = Translate();
  const {
    filterSelected, filtersList, setSearchTerms, isLoading, updateFilterSelectedExact, clearAllLists,
  } = useContext(B2BDataBaseFilterContext);
  const [showList, setShowList] = useState(false);
  const [toogleModal, setToogleModal] = useState(false);
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const searchRef = useRef(null);
  const dropdownRef = useRef(null);
  const itemRef = useRef(null);


  // Add event's click scope outside dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowList(false);
        setLocalSearchTerm('');
      }
    };

    // Adiciona o listener de evento para detectar cliques fora
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // Update SearchTerms after 500s that user writes at search input
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchTerms((prevState) => ({ ...prevState, [keyValue]: localSearchTerm }));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [localSearchTerm]);

  // Insert focus at searchInput
  useEffect(() => {
    if (showList && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showList]);

  function handleSearchTerm(event) {
    setLocalSearchTerm(event.target.value);
  }

  function handleToogleModal() {
    setToogleModal((prevState) => !prevState);
  }

  function handleToogleList() {
    setShowList((prevState) => !prevState);
  }

  function handleSelectItem(item) {
    updateFilterSelectedExact(keyValue, item);
  }

  function getButtonLabel() {
    function joinResults(array) {
      if (array?.length === 0) return 'Search';
      return array?.map((item) => item).join(', ');
    }
    const exactArr = filterSelected[keyValue].exact;
    return joinResults(exactArr);
  }

  function handlePressEnterOnSearchInput(event) {
    if (event.key === 'Enter') {
      handleSelectItem(localSearchTerm);
      setLocalSearchTerm('');
    }
  }

  return (
    <Container ref={dropdownRef}>
      <InputContainer>
        <div className="label-container">
          <label htmlFor={label}>{label}</label>
          <div className="icons-container">
            <img
              src={IconAdd}
              alt="Open Modal"
              onClick={handleToogleModal}
              title="Avanced filters"
            />
            <img
              src={IconClear}
              alt="Clear Options"
              onClick={() => clearAllLists(keyValue)}
              title="Clear all"
            />
          </div>
        </div>
        <button type="button" className="multiselect-btn" onClick={handleToogleList}>
          {getButtonLabel()}
        </button>
      </InputContainer>
      {showList && (
        <ListContainer
          $show={showList}
        >
          <div className="search-container">
            <input
              type="text"
              name={label}
              id={label}
              placeholder="Search"
              value={localSearchTerm}
              onChange={handleSearchTerm}
              onKeyDown={handlePressEnterOnSearchInput}
              ref={searchRef}
            />
            <img
              src={IconClear}
              alt="Clear Options"
              onClick={() => setLocalSearchTerm('')}
            />
          </div>
          {isLoading[keyValue] && <Loader className="loader" loading={isLoading[keyValue]} />}
          {(!isLoading[keyValue] && filtersList?.[keyValue].length > 0) && filtersList?.[keyValue]?.map((item) => (
            <span
              key={item}
              ref={itemRef}
              onClick={() => handleSelectItem(item)}
            >
              {item}
            </span>
          ))}
        </ListContainer>
      )}

      <Modal
        title={`${translate.titleModal} (${label})`}
        onAction={handleToogleModal}
        onClose={handleToogleModal}
        visible={toogleModal}
        btnMessage={translate.btnClose}
      >
        <ModalContent keyValue={keyValue} />
      </Modal>
    </Container>
  );
}
