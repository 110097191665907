import React, { useState, useEffect, useContext } from 'react';
import { B2BDataBaseFilterContext } from '../../B2BDatabaseFilter.context';
import Square from '../../../../../components/Square';
import { Button } from '../../../../../components/Button';
import { MultiSelect } from '../../../../../components/MultiSelect';
import IconHide from '../../../../../styles/logos/icon_hide.svg';
import IconShow from '../../../../../styles/logos/icon_show.svg';
import { MultiSelectText } from '../MultiSelectText';
import { Translate } from './translate';
import { Container, ContainerFields, ContainerSelectedFilters } from './style';

export function Filter() {
  const {
    filtersList, filterSelected, setFilterSelected, filterDescription, filterPreSelectedFilter, applyFilter,
  } = useContext(B2BDataBaseFilterContext);
  const [showFilters, setShowFilters] = useState(true);
  const translate = Translate();

  function handleSubmit() {
    // Runs the function from B2bDatabaseData.context
    applyFilter();
  }

  function joinResults(array) {
    if (array?.length === 0) return 'All';
    return array?.map((item) => item).join(', ');
  }

  function updateFilters() {
    // eslint-disable-next-line no-restricted-syntax
    for (let [item, value] of Object.entries(filterSelected)) {
      const keyItem = item;
      const arrValue = value;
      setFilterSelected((prevState) => ({ ...prevState, [`${keyItem}`]: arrValue }));
    }
  }

  function handletoogleShowFilters() {
    setShowFilters((prevState) => !prevState);
  }

  return (
    <Square size="large">
      <Container>
        <h3>{translate.title}</h3>
        <ContainerFields>

          <MultiSelectText
            label={translate.role}
            keyValue="position"
          />

          <MultiSelect
            options={filtersList?.country?.map((item) => item)}
            label={translate.country}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="country"
          />

          <MultiSelect
            options={filtersList?.state?.map((item) => item)}
            label={translate.state}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="state"
          />

          <MultiSelect
            options={filtersList?.city?.map((item) => item)}
            label={translate.city}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="city"
          />

          <MultiSelectText
            label={translate.sector}
            keyValue="sector"
          />

          <MultiSelectText
            label={translate.company}
            keyValue="company"
          />

          <div className="form-group">
            <label htmlFor="database">{translate.database}</label>
            <select
              id="database"
              value={setFilterSelected.typeBase}
              onChange={(e) => setFilterSelected((prevState) => ({ ...prevState, typeBase: e.target.value }))}
            >
              <option value="">All Data</option>
              <option value="verified">B2B email - 100% verified </option>
              <option value="notVerified">B2C email - Not verified</option>
            </select>
          </div>

          {/* <div className="form-group">
            <label htmlFor="keyword-filter">Search filter</label>
            <input
              id="keyword-filter"
              value={setFilterSelected.searchFilter}
              onChange={(e) => setFilterSelected((prevState) => ({ ...prevState, searchFilter: e.target.value }))}
            />
          </div> */}

          <div className="form-group">
            <Button type="button" color="blue" onClick={handleSubmit}>{translate.btnApply}</Button>
          </div>
        </ContainerFields>
      </Container>

      {/* Filters */}
      <ContainerSelectedFilters $show={showFilters} $preSelected={filterPreSelectedFilter}>
        <div className="filters-head">
          <h4>{filterDescription}</h4>
          <img onClick={handletoogleShowFilters} src={showFilters ? IconHide : IconShow} alt="Toogle Filters" />
        </div>
        <div className="container-filters">
          <hr />
          {/* Cargo/Position filter */}
          {(filterSelected?.position.exact.length > 0
            || filterSelected?.position.contains.length > 0
            || filterSelected?.position.exactNegative.length > 0
            || filterSelected?.position.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.role}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.position.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.position.exact)}</span>
                  </div>
                  )}
                  {filterSelected.position.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.position.contains)}</span>
                  </div>
                  )}
                  {filterSelected.position.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.position.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.position.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.position.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.role}: </span>
                <span className="filter-result">All</span>
              </div>
            )}
          <div>
            <span className="filter-title">{translate.country}: </span>
            <span className="filter-result">{joinResults(filterSelected?.country)}</span>
          </div>
          <div>
            <span className="filter-title">{translate.state}: </span>
            <span className="filter-result">{joinResults(filterSelected?.state)}</span>
          </div>
          <div>
            <span className="filter-title">{translate.city}: </span>
            <span className="filter-result">{joinResults(filterSelected?.city)}</span>
          </div>
          {/* Setor/Sector filter */}
          {(filterSelected?.sector.exact.length > 0
            || filterSelected?.sector.contains.length > 0
            || filterSelected?.sector.exactNegative.length > 0
            || filterSelected?.sector.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.sector}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.sector.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.sector.exact)}</span>
                  </div>
                  )}
                  {filterSelected.sector.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.sector.contains)}</span>
                  </div>
                  )}
                  {filterSelected.sector.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.sector.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.sector.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.sector.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.sector}: </span>
                <span className="filter-result">All</span>
              </div>
            )}
          {/* Empresa/Company filter */}
          {(filterSelected?.company.exact.length > 0
            || filterSelected?.company.contains.length > 0
            || filterSelected?.company.exactNegative.length > 0
            || filterSelected?.company.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.company}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.company.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.company.exact)}</span>
                  </div>
                  )}
                  {filterSelected.company.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.company.contains)}</span>
                  </div>
                  )}
                  {filterSelected.company.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.company.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.company.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.company.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.company}: </span>
                <span className="filter-result">All</span>
              </div>
            )}
          <div>
            <span className="filter-title">{translate.database}: </span>
            <span className="filter-result">{filterSelected.typeBase === '' ? 'All' : filterSelected.typeBase === 'verified' ? 'B2B email - 100% verified' : 'B2C email - Not verified'}</span>
          </div>
          {filterSelected.searchFilter?.length > 0 && (
            <div>
              <span className="filter-title">Search filter: </span>
              <span className="filter-result">{filterSelected.searchFilter}</span>
            </div>
          )}
        </div>
      </ContainerSelectedFilters>
    </Square>
  );
}
