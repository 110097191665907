export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    newProject: language === 'pt-BR' ? 'Novo Projeto' : 'New Project',
    newProjectMsg: language === 'pt-BR'
      ? 'Acesse aqui para obter nova Master Ad Copy'
      : 'Click here to get new Master Ad Copy',
    recents: language === 'pt-BR' ? 'Recentes' : 'Recents',
    noDataMsg: language === 'pt-BR' ? 'Você não possui projetos recentes' : 'You have no recent projects',
    language,
  };
}
