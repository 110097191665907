/* eslint-disable react/no-array-index-key */
import React, { useState, useContext } from 'react';
import { B2BDataBaseFilterContext } from '../../../B2BDatabaseFilter.context';
import IconClose from '../../../../../../styles/logos/icon_close_x.svg';
import { Translate } from './translate';
import { Container, AreaContainer, FilterContainer } from './style';

export function ModalContent({ keyValue }) {
  const translate = Translate();
  const {
    filterSelected, updateFilterSelected, deleteOneItemFromSelectedFilters, deleteAllItemsFromSelectedFilters,
  } = useContext(B2BDataBaseFilterContext);
  const [termPositiveExact, setTermPositiveExact] = useState('');
  const [termPositiveContains, setTermPositiveContains] = useState('');
  const [termNegativeExact, setTermNegativeExact] = useState('');
  const [termNegativeContains, setTermNegativeContains] = useState('');

  function handlePressEnterOnSearchInput(event, value) {
    if (event.key === 'Enter') {
      if (value === 'termPositiveExact') {
        if (!termPositiveExact) return;
        updateFilterSelected({ key: keyValue, subKey: 'exact', value: termPositiveExact });
        setTermPositiveExact('');
      }
      if (value === 'termPositiveContains') {
        if (!termPositiveContains) return;
        updateFilterSelected({ key: keyValue, subKey: 'contains', value: termPositiveContains });
        setTermPositiveContains('');
      }
      if (value === 'termNegativeExact') {
        if (!termNegativeExact) return;
        updateFilterSelected({ key: keyValue, subKey: 'exactNegative', value: termNegativeExact });
        setTermNegativeExact('');
      }
      if (value === 'termNegativeContains') {
        if (!termNegativeContains) return;
        updateFilterSelected({ key: keyValue, subKey: 'containsNegative', value: termNegativeContains });
        setTermNegativeContains('');
      }
    }
  }

  function handleDeleteOne(item, subKey) {
    deleteOneItemFromSelectedFilters(keyValue, subKey, item);
  }

  function handleDeleteAll(subKey) {
    deleteAllItemsFromSelectedFilters(keyValue, subKey);
  }

  return (
    <Container>
      <AreaContainer>
        <FilterContainer>
          <h3>{translate.titleExact}</h3>
          <div className="area-filter">
            <div className="filter-container">
              <div className="group-filter">
                <label htmlFor="positive-exact">{translate.positiveExact}</label>
                <input
                  className="l-green"
                  type="text"
                  id="positive-exact"
                  value={termPositiveExact}
                  onChange={(event) => setTermPositiveExact(event.target.value)}
                  onKeyDown={(event) => handlePressEnterOnSearchInput(event, 'termPositiveExact')}
                  placeholder={translate.placeholder}
                />
              </div>
              <span>{translate.selectedItems}</span>
              <div className="selected-filters">
                {filterSelected[keyValue].exact.map((item, index) => (
                  <div className="item" key={index}>
                    <span>{item}</span>
                    <span className="delete-icon">
                      <img
                        src={IconClose}
                        alt="Delete"
                        onClick={() => handleDeleteOne(item, 'exact')}
                      />
                    </span>
                  </div>
                ))}
              </div>
              <div className="clear-filters">
                <span onClick={() => handleDeleteAll('exact')}>
                  {translate.clear}
                </span>
              </div>
            </div>
            <div className="filter-container">
              <div className="group-filter">
                <label htmlFor="positive-contains">{translate.positiveContains}</label>
                <input
                  className="l-green"
                  type="text"
                  id="positive-contains"
                  value={termPositiveContains}
                  onChange={(event) => setTermPositiveContains(event.target.value)}
                  onKeyDown={(event) => handlePressEnterOnSearchInput(event, 'termPositiveContains')}
                  placeholder={translate.placeholder}
                />
              </div>
              <span>{translate.selectedItems}</span>
              <div className="selected-filters">
                {filterSelected[keyValue].contains.map((item, index) => (
                  <div className="item" key={index}>
                    <span>{item}</span>
                    <span className="delete-icon">
                      <img
                        src={IconClose}
                        alt="Delete"
                        onClick={() => handleDeleteOne(item, 'contains')}
                      />
                    </span>
                  </div>
                ))}
              </div>
              <div className="clear-filters">
                <span onClick={() => handleDeleteAll('contains')}>
                  {translate.clear}
                </span>
              </div>
            </div>
          </div>
        </FilterContainer>
        <FilterContainer>
          <h3>{translate.titleNegative}</h3>
          <div className="area-filter">
            <div className="filter-container">
              <div className="group-filter">
                <label htmlFor="negative-exact">{translate.negativeExact}</label>
                <input
                  className="l-red"
                  type="text"
                  id="negative-exact"
                  value={termNegativeExact}
                  onChange={(event) => setTermNegativeExact(event.target.value)}
                  onKeyDown={(event) => handlePressEnterOnSearchInput(event, 'termNegativeExact')}
                  placeholder={translate.placeholder}
                />
              </div>
              <span>{translate.selectedItems}</span>
              <div className="selected-filters">
                {filterSelected[keyValue].exactNegative.map((item, index) => (
                  <div className="item" key={index}>
                    <span>{item}</span>
                    <span className="delete-icon">
                      <img
                        src={IconClose}
                        alt="Delete"
                        onClick={() => handleDeleteOne(item, 'exactNegative')}
                      />
                    </span>
                  </div>
                ))}
              </div>
              <div className="clear-filters">
                <span onClick={() => handleDeleteAll('exactNegative')}>
                  {translate.clear}
                </span>
              </div>
            </div>
            <div className="filter-container">
              <div className="group-filter">
                <label htmlFor="negative-contains">{translate.negativeContains}</label>
                <input
                  className="l-red"
                  type="text"
                  id="negative-contains"
                  value={termNegativeContains}
                  onChange={(event) => setTermNegativeContains(event.target.value)}
                  onKeyDown={(event) => handlePressEnterOnSearchInput(event, 'termNegativeContains')}
                  placeholder={translate.placeholder}
                />
              </div>
              <span>{translate.selectedItems}</span>
              <div className="selected-filters">
                {filterSelected[keyValue].containsNegative.map((item, index) => (
                  <div className="item" key={index}>
                    <span>{item}</span>
                    <span className="delete-icon">
                      <img
                        src={IconClose}
                        alt="Delete"
                        onClick={() => handleDeleteOne(item, 'containsNegative')}
                      />
                    </span>
                  </div>
                ))}
              </div>
              <div className="clear-filters">
                <span onClick={() => handleDeleteAll('containsNegative')}>
                  {translate.clear}
                </span>
              </div>
            </div>
          </div>
        </FilterContainer>
      </AreaContainer>
    </Container>
  );
}
