import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import TitlePage from '../../../../../components/TitlePage';
import BackButton from '../../../../../components/BackButton';
import Square from '../../../../../components/Square';
import { Badge } from '../../../../../components/Badge';
import { ButtonLink } from '../../../../../components/ButtonLink';
import Date_New from '../../../../../helpers/Dates_New';

import IconEdit from '../../../../../styles/logos/FaEdit.svg';
import IconLink from '../../../../../styles/logos/icon_link.svg';

import { Translate } from './translate';
import {
  Container, TitleContainer, SearchContainer,
} from './style';

function ListUser({
  pageTitle, userList, userListFiltered, placeholderSearchTerm, linkAddButton, linkToBackButton, handleSearchTerm,
}) {
  const translate = Translate();

  function colorBadge(isActive) {
    return isActive === 'Y' ? 'green' : 'red';
  }

  return (
    <>
      <TitlePage title={pageTitle}>
        {linkToBackButton && <BackButton to={linkToBackButton} />}
      </TitlePage>
      <Container>
        {userList
        && (
        <SearchContainer>
          <div className="c-search">
            <label htmlFor="search-term">{translate.search}</label>
            <input type="text" id="search-term" placeholder={placeholderSearchTerm} onChange={handleSearchTerm} />
          </div>
          <div className="c-actions">
            <ButtonLink color="blue" to={linkAddButton}>{translate.labelButtonAddNew}</ButtonLink>
          </div>
        </SearchContainer>
        )}

        {!userList
          ? (<p>{translate.errorMsg}</p>)
          : (userListFiltered && userListFiltered?.map((user) => (
            <Square size="large" key={user.id}>
              <TitleContainer>
                <div className="c-users">
                  <div className="c-users-name">
                    <span className="name">
                      {user.firstName.split(' ')[0]}
                      {' '}
                      {user.lastName.split(' ')[user.lastName.split(' ').length - 1]}
                    </span>
                    <span className="email">
                      {user.email}
                    </span>
                    <span className="name-complete">
                      {user.firstName}
                      {' '}
                      {user.lastName}
                    </span>
                  </div>
                  <div className="c-badges">
                    {user.idKartra && (
                      <Badge>
                        <Link to={`/admin/users/kartra-info/${user.idKartra}`} title="Kartra infos">
                          Kartra
                          <img src={IconLink} alt="Kartra infos" />
                        </Link>
                      </Badge>
                    )}
                    {user.type === 'trial' && (
                      <Badge color="yellow">
                        {`Trial | Exp: ${Date_New.formatDatesMaskWithHour(user.expirationAt).split(' ')[0]}`}
                      </Badge>
                    ) }
                    {user.profile && (
                      <Badge>
                        {user.profile}
                        {user.clientName && ` | ${user.clientName}`}
                      </Badge>
                    )}
                    {user.accessGroupName && (
                      <Badge color="gray">
                        {user.accessGroupName}
                      </Badge>
                    )}
                    <Badge color={colorBadge(user.active)}>
                      {user.active}
                    </Badge>
                  </div>
                </div>
                <div className="c-users-action">
                  <Link to={`/admin/users/edit/${user.id}`} title="Edit User">
                    <img src={IconEdit} alt="Edit User" />
                  </Link>
                </div>
              </TitleContainer>
            </Square>
          )))}
      </Container>
    </>
  );
}

ListUser.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  userList: PropTypes.array.isRequired,
  userListFiltered: PropTypes.array.isRequired,
  placeholderSearchTerm: PropTypes.string.isRequired,
  linkAddButton: PropTypes.string.isRequired,
  linkToBackButton: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  handleSearchTerm: PropTypes.func.isRequired,
};

export default ListUser;
