export function avatarList(type, language) {
  switch (type) {
    case 'B2B':
      if (language === 'pt-BR') {
        return [
          'Fundadores de Empresas com até 10 Colaboradores no Brasil',
          'Fundadores de Empresas com até 10 Colaboradores em São Paulo',
          'Fundadores de Empresas com mais de 51 e até 10.000 colaboradores',
          'Fundadores de Empresas com mais de 51 e até 10.000 colaboradores no Brasil',
          'Fundadores de Empresas com mais de 100 Milhões de Receita no segmento de Serviços Financeiros',
          'CTOs de Empresas com mais de 1000 Colaboradores',
          'CMOs de Empresas com mais de 1000 Colaboradores',
          'Fundadores - CEOs - Presidentes e Vice-Presidentes de Empresas com mais de 5000 Colaboradores nos seguintes segmentos: Serviços financeiros - Telecom e Bancos',
        ];
      }
      return [
        'Founders of Companies with up to 10 Employees in Brazil',
        'Founders of Companies with up to 10 Employees in São Paulo',
        'Founders of Companies with more than 51 and up to 10,000 Employees',
        'Founders of Companies with more than 51 and up to 10,000 Employees in Brazil',
        'Founders of Companies with more than 100 Million in Revenue in the Financial Services sector',
        'CTOs of Companies with more than 1000 Employees',
        'CMOs of Companies with more than 1000 Employees',
        'Founders - CEOs - Presidents and Vice Presidents of Companies with more than 5000 Employees in the following sectors: Financial Services - Telecom and Banks',
      ];

    case 'B2C':
      if (language === 'pt-BR') {
        return [
          'Mercado de massas. Todas as classes sociais.',
          'Jovens de 18 a 30 anos',
          'Pais e Mães',
          'Mulheres de 35 a 50 anos',
          'Homens de 35 a 50 anos',
          'Homens e Mulheres de 25 a 50 anos',
          'Classe A e milionários no geral',
          'Classe A e Classe B',
          'Classe C e Classe D',
          'Classe E e Classe F',
          'Homens e Mulheres de 40 a 50 anos. Classe A e Classe B. Residentes na cidade de São Paulo em bairros ricos.',
        ];
      }
      return [
        'Mass market. All social classes.',
        'Young people aged 18 to 30',
        'Parents',
        'Women aged 35 to 50',
        'Men aged 35 to 50',
        'Men and Women aged 25 to 50',
        'Class A and millionaires in general',
        'Class A and Class B',
        'Class C and Class D',
        'Class E and Class F',
        'Men and Women aged 40 to 50. Class A and Class B. Residents in the city of São Paulo in wealthy neighborhoods.',
      ];



    default:
      return [];
  }
}
