import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';

import { SideBarVideosContext } from '../../../context/SideBarVideosContext';

import VideosRatingService from '../../../services/VideosRating';
import { colorByColorName } from '../../../helpers/colorByColorName';

import Square from '../../Square';
import toast from '../../../helpers/toast';
import { Button } from '../../Button';
import { RatingInfo } from './RatingInfo';
import Modal from '../../Modal';
import { ModalContent } from './ModalContent';

import IconArrow from '../../../styles/logos/icon_sidebararrow.svg';

import { Translate } from './translate';
import { Container, SpanBtn } from './style';

export function Ratings({ idVideo }) {
  const translate = Translate();
  const { product } = useContext(SideBarVideosContext);
  const [overallScore, setOverallScore] = useState(0);
  const [individualRating, setIndividualRating] = useState(0);
  const [amountOfRatings, setAmountOfRatings] = useState(0);
  const [amountOfRatingsByStars, setAmountOfRatingsByStars] = useState(null);
  const [comment, setComment] = useState('');
  const [saveBtnModal, setSaveBtnModal] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    toogleSaveBtnModal();
  }, [individualRating]);

  function toogleModal() {
    // Restart individualRating and comments if close modal
    if (isModalVisible && individualRating > 0) {
      setIndividualRating(0);
      setComment('');
    }
    setIsModalVisible((prevState) => !prevState);
  }

  function toogleSaveBtnModal() {
    if (individualRating > 0) {
      setSaveBtnModal(false);
    } else {
      setSaveBtnModal(true);
    }
  }

  async function handleStoreRating() {
    try {
      const response = await VideosRatingService.store({ description: comment, score: individualRating, videoID: idVideo });
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      toogleModal();
    }
  }

  async function getInfo() {
    if (idVideo) {
      const videoInfos = await VideosRatingService.findOne({ idVideo });
      setOverallScore(videoInfos.overallScore);
      setAmountOfRatings(videoInfos.amountOfRatings);
      setAmountOfRatingsByStars(videoInfos.amountOfRatingsByStars);
    }
  }

  return (
    <>
      <Square size="medium">
        <Container>
          <div className="rating-stars">
            <div className="rating-stars-arrow-container">
              <div className="rating-starts-modal-clicked">
                <div className="rating-starts-score">{overallScore === 0 ? null : overallScore}</div>
                <Rating
                  initialValue={Number(overallScore)}
                  readonly
                  transition
                  size={20}
                  titleSeparator=""
                  allowFraction
                  fillClassName="filled-icons"
                  fillColor={colorByColorName(product.colorName)}
                />
                <img src={IconArrow} alt="Ratings" />
              </div>
              <div className="ratings-hidden-container">
                <RatingInfo
                  value={overallScore}
                  colorForStar={colorByColorName(product.colorName)}
                  color={product.colorName}
                  idVideo={idVideo}
                  amountOfRatings={amountOfRatings}
                  amountOfRatingsByStars={amountOfRatingsByStars}
                />
              </div>
            </div>
            <Link to={`/client/rating-video/${idVideo}`}>
              {amountOfRatings} {amountOfRatings === 1 ? translate.rating : translate.ratings}
            </Link>
          </div>
          <div className="rating-comment">
            <Button>
              <SpanBtn color={product.color} onClick={toogleModal}> {translate.submitRating} </SpanBtn>
            </Button>
          </div>
        </Container>
      </Square>

      <Modal
        visible={isModalVisible}
        onClose={toogleModal}
        onAction={handleStoreRating}
        title={translate.titleModal}
        btnDisabled={saveBtnModal}
        color={colorByColorName(product.colorName)}
      >
        <ModalContent
          color={product.color}
          rating={individualRating}
          setRating={setIndividualRating}
          comment={comment}
          setComment={setComment}
        />
      </Modal>
    </>
  );
}

