import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../../../../components/TitlePage';
import BackButton from '../../../../../components/BackButton';
import toast from '../../../../../helpers/toast';
import ProductsService from '../../../../../services/Products';
import { Translate } from './translate';
import { ProductForm } from '../component/ProductForm';

export function ProductAdd() {
  const translate = Translate();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    namePt: '', nameEn: '', color: '#F1F1F1', colorName: '', active: 'Y', ordering: 0, type: 'academy', slug: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);

  async function save() {
    try {
      await ProductsService.store(product);
      toast('success', 'Created successfully');
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsDisabled(true);
      navigate('/admin/products');
    }
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton />
      </TitlePage>
      <ProductForm
        product={product}
        setProduct={setProduct}
        onSave={save}
      />
    </>
  );
}
