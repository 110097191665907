import React from 'react';

import Loader from '../../../../../components/Loader';
import Square from '../../../../../components/Square';
import { NoDataMessage } from '../../../../../components/NoDataMessage';

import Dates from '../../../../../helpers/Dates_New';

import { Translate } from './translate';
import { Container } from './style';

export function ListViews({ videoList, isLoading }) {
  const translate = Translate();

  return (
    <Container>
      <div className="container-last-videos">
        <h3>{translate.titleListVideos}</h3>
        <div className="container-last-videos-list">
          {(videoList.length === 0 && !isLoading) && (
          <NoDataMessage>{translate.msgNoDataList}</NoDataMessage>
          )}
          {(videoList.length === 0 && isLoading) && (
          <Loader loading={isLoading} />
          )}
          {(videoList.length > 0 && !isLoading) && (
            videoList.map((video) => (
              <Square size="medium" key={video.id}>
                <div className="container-video-date">{Dates.formatDatesMaskWithHour(video.dateAct)}</div>
                <div className="container-video-path">
                  <span className="l-video video-product" style={{ color: video.infos?.productColor }}>
                    {translate.language === 'pt-BR' ? video.infos?.productNamePt : video.infos?.productNameEn}
                  </span>
                  <span className="l-video video-category">
                    {translate.language === 'pt-BR' ? `${video.infos?.categoryNamePt}` : ` ${video.infos?.categoryNameEn}`}
                  </span>
                  {video.infos.subcategoryNamePt && (
                  <span className="l-video video-subcategory">
                    {translate.language === 'pt-BR' ? video.infos.subcategoryNamePt : video.infos?.subcategoryNameEn}
                  </span>
                  )}
                  {video.infos.postNamePt && (
                    <span className="l-video video-post">
                      {translate.language === 'pt-BR' ? video.infos.postNamePt : video.infos?.postNameEn}
                    </span>
                  )}
                </div>
              </Square>
            ))
          )}
        </div>
      </div>
    </Container>
  );
}
