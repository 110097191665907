import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    margin-top: 16px;
    width: 150px;
  }
`;

export const ContainerIDAndName = styled.div`
  display: grid;
  grid-template-columns: 1fr 11fr;
  gap: 16px;
`;

export const ContainerInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(5,1fr);
  gap: 16px;
  margin: 16px 0px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(1,1fr);
  }
`;

export const ContainerRatio = styled.div`
  display: flex;
  gap: 24px;
  margin: 16px 0px;

  > div {
    display: flex;
    align-items: center;
    gap:8px;

    input[type="radio"] {
      border: 1px solid #ccc; /* Cor da borda padrão */
      appearance: none; /* Remove o estilo padrão do radio */
      outline: none; /* Remove o contorno padrão */
      border-radius: 50%;
    }

    input[type="radio"]:checked {
      border: 1px solid ${({ theme }) => theme.fontWhite}; /* Cor da borda quando selecionado */
      background-color: ${({ theme }) => theme.fontWhite} /* Cor de fundo quando selecionado */
  }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  input, select {
    width: 100%;
  }
`;

export const ContainerError = styled.div`
  margin-top: 24px;
  background-color: ${({ theme }) => theme.red};
  padding: 16px;
  border-radius: 8px;
  word-wrap: break-word;
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;



