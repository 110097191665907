import React, { useState, useContext } from 'react';

import { B2BDataBaseFilterContext } from './B2BDatabaseFilter.context';
import { B2BDataBaseDataContext } from './B2BDatabaseData.context';

import { numberFormat } from '../../../../helpers/numberFormat';

import { Filter } from './components/Filter';
import { ItemUser } from './components/ItemUser';
import TitlePage from '../../../../components/TitlePage';
import Loader from '../../../../components/Loader';
import { NoDataMessage } from '../../../../components/NoDataMessage';

import IconDownload from '../../../../styles/logos/download.svg';
import IconArrow from '../../../../styles/logos/arrow.svg';

import { Translate } from './translate';
import {
  Container, TableContainer, Table, OptionsContainer, QuantityContainer, PaginationContainer, ItemModal,
} from './style';


export function B2BDatabaseSearch() {
  const translate = Translate();
  const { filterApplied } = useContext(B2BDataBaseFilterContext);
  const {
    b2bData, isLoadingData, totalData, pagination, setPagination, handlePages, totalPage,
  } = useContext(B2BDataBaseDataContext);


  async function downloadCsv() {
    const headers = `${Object.keys(b2bData[0]).join(',')}\r\n`;
    const rows = b2bData.map((item) => (
      Object.values(item)
        .map((val) => (val !== null && val !== undefined ? val : ''))
        .join(',')
    )).join('\r\n');
    const csvContent = headers + rows;

    // Create Blob com o Csv
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    // Create a temporary download link <a>
    const link = document.createElement('a');
    link.href = url;
    link.download = 'data-shifters.csv';

    // it simulates click at the link to begin download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Container>
      <TitlePage title="B2B Database - Search" />
      <Filter />
      {totalData > 0 && (
      <OptionsContainer>
        <QuantityContainer>
          <div className="container-download" onClick={() => downloadCsv()}>
            <span>Download List</span>
            <img src={IconDownload} alt="download list" title="Download list" />
          </div>
          {totalData > 0 && <ItemModal><strong>{numberFormat(Number(totalData))}</strong> {translate.itemsTable}</ItemModal>}
        </QuantityContainer>
        <PaginationContainer>
          <div className="quantity-items-container">
            <label htmlFor="quantity">{translate.totalItems}: </label>
            <select
              name="quantity"
              value={pagination.quantityItems}
              onChange={(e) => setPagination((prevState) => ({ ...prevState, quantityItems: e.target.value }))}
            >
              <option value="50">50</option>
              <option value="250">250</option>
              <option value="500">500</option>
              <option value="1000">{numberFormat(1000)}</option>
              <option value="2000">{numberFormat(2000)}</option>
            </select>
          </div>
          <div className="pagination-container">
            <span>{translate.pages}: </span>
            {!isLoadingData && <img src={IconArrow} className="arrow left" alt="decrease" onClick={() => handlePages('decrease')} /> }
            <div className="pagination">
              {numberFormat(Number(pagination.currentPage))} {translate.of} {numberFormat(Number(totalPage))}
            </div>
            {!isLoadingData && <img src={IconArrow} className="arrow right" alt="increase" onClick={() => handlePages('increase')} />}
          </div>
        </PaginationContainer>
      </OptionsContainer>
      )}
      {isLoadingData && <Loader loading={isLoadingData} />}
      {(b2bData.length === 0 && !isLoadingData) && (
        <NoDataMessage>
          {translate.msgNoData}
        </NoDataMessage>
      )}
      {(b2bData.length > 0 && !isLoadingData) && (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <td>{translate.emailFirst}</td>
                <td>{translate.emailSecond}</td>
                <td>{translate.nameFirst}</td>
                <td>{translate.nameMiddle}</td>
                <td>{translate.nameLast}</td>
                <td>{translate.country}</td>
                <td>{translate.state}</td>
                <td>{translate.city}</td>
                <td>URL</td>
                <td>{translate.role}</td>
                <td>{translate.sector}</td>
                <td>{translate.company}</td>
                <td>{translate.companyDomain}</td>
                <td>{translate.technology}</td>
                <td>{translate.employeesQuantity}</td>
                <td>{translate.annualRevenue}</td>
                <td>{translate.headquartersRegion}</td>
                <td>{translate.linkedinID}</td>
                <td>{translate.listID}</td>
                <td>{translate.listName}</td>
                <td>{translate.createdAt}</td>
                <td>{translate.registerAt}</td>
              </tr>
            </thead>
            <tbody>
              {b2bData.map((b2dItem) => <ItemUser key={b2dItem.id} data={b2dItem} />)}
            </tbody>
          </Table>
        </TableContainer>
      )}

    </Container>
  );
}
