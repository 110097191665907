import styled from 'styled-components';

export const ContainerInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  gap: 16px;

  button {
    align-self: center;
    height: 55%;
    margin-top: 12px;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
`;

export const ContainerResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;

  hr {
    border: 1px solid ${({ theme }) => theme.gray2};
  }

  h3 {
    margin-bottom: 16px;
  }
`;
