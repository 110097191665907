
export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    items: language === 'pt-BR' ? 'Itens' : 'Items',
    role: language === 'pt-BR' ? 'Cargo' : 'Role',
    city: language === 'pt-BR' ? 'Cidade' : 'City',
    company: language === 'pt-BR' ? 'Empresa' : 'Company',
    domain: language === 'pt-BR' ? 'Domínio' : 'Domain',
    selectedFilter: language === 'pt-BR' ? 'Filtros pré-selecionados' : 'Pre-selected filters',
    appliedFilter: language === 'pt-BR' ? 'Filtros aplicados' : 'Applied filters',
    modalAuthorized: language === 'pt-BR' ? 'Número de itens permitidos para download a cada 7 dias: ' : 'Number of items allowed for download every 7 days: ',
    modalRemaing: language === 'pt-BR' ? 'Total de itens restantes para download: ' : 'Total items remaining for download: ',
    modalUsed: language === 'pt-BR' ? 'Total de itens baixados: ' : 'Total items downloaded: ',
    modalDays: language === 'pt-BR' ? 'Próximo download autorizado em (dias): ' : 'Next download authorized on (days): ',
    itemsTable: language === 'pt-BR' ? 'itens' : 'items',
    of: language === 'pt-BR' ? 'de' : 'of',
    pages: language === 'pt-BR' ? 'Páginas' : 'Pages',
    msgNoData: language === 'pt-BR' ? 'Nenhum dado encontrado com os filtros selecionados' : 'No data found with the selected filters',
    language,
  };
}
