import { styled, css } from 'styled-components';

export const Menu = styled.div`
  flex: 1.4;
  background-color: ${({ theme }) => theme.graySubMenu};
  font-size: 15px;
  margin-left: -20px;
  margin-top: -20px;
  border: 1px solid ${({ theme }) => theme.grayBackground};
  overflow-x: auto;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0;
    width: 100%;
  }

  p {
    text-align: center;
    margin: 32px 0;

  }
`;

export const TitleContainer = styled.div`
  font-weight: 500;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackground};
  text-align: center;
  padding: 16px 0px;
  width: 100%;
  color: ${({ color, theme }) => (color ? theme[color] : theme.fontWhite)}
`;

export const MainTitle = styled.div`
  font-weight: 500;
  font-size: 17px;
  cursor: auto;
  margin-bottom: 16px;
  color: ${({ color, theme }) => (color ? theme[color] : theme.fontWhite)}
`;

export const Category = styled.li`
  > a,
  .submenu-category {
    text-decoration: none;
    display: block;
    padding: 10px 0 10px 16px;
    cursor: pointer;
  }

  > a:hover,
  .submenu-category:hover,
  .submenu-category:hover span {
    background-color: ${({ theme }) => theme.grayBackground};
    color: ${({ color, theme }) => (color ? theme[color] : theme.fontWhite)}
  }

  .submenu-category.highlight {
    background-color: ${({ theme }) => theme.grayBackground};
  }

  .submenu-category.highlight span {
    color: ${({ color, theme }) => (color ? theme[color] : theme.fontWhite)}
  }

  .submenu-category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;


    img {
      width: 16px;
      margin-right: 16px;
      background-color: transparent;
      transition: .4s;
      transform: rotate(0);
    }
  }

  .list-subcategories {
    display: none; // block none
  }
`;

export const SubCategory = styled.li`
  > a,
  .submenu-subcategory {
    text-decoration: none;
    width: 100%;
    display: block;
    padding: 10px 0 10px 24px;
    cursor: pointer;
  }

  > a:hover,
  .submenu-subcategory:hover,
  .submenu-subcategory:hover span {
    background-color: ${({ theme }) => theme.grayBackground};
    color: ${({ color, theme }) => (color ? theme[color] : theme.fontWhite)}
  }

  .submenu-subcategory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;

    img {
      width: 16px;
      margin-right: 16px;
      background-color: transparent;
      transition: .4s;
      transform: rotate(0);
    }
  }

  .list-posts {
    display: none; // block none
  }
`;

export const Post = styled.li`
  > a,
  .submenu-subcategory {
    text-decoration: none;
    width: 100%;
    display: block;
    padding: 10px 0 10px 32px;
    cursor: pointer;
  }

  > a:hover {
    background-color: ${({ theme }) => theme.grayBackground};
    color: ${({ color, theme }) => (color ? theme[color] : theme.fontWhite)}
  }
`;
