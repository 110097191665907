import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AccessGroupService from '../../../../services/AccessGroup';
import UsersService from '../../../../services/Users';
import { FormUser } from '../components/FormUser';
import TitlePage from '../../../../components/TitlePage';
import BackButton from '../../../../components/BackButton';
import toast from '../../../../helpers/toast';
import { Translate } from './translate';

export function UserEdit() {
  const translate = Translate();
  const navigate = useNavigate();
  const { idUser } = useParams();
  const [userInfo, setUserInfo] = useState({});
  const [accessGroup, setAccessGroup] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [createByClientPath, setCreateByClientPath] = useState(false);

  useEffect(() => {
    (async () => {
      await getUserInfo();
      await getAccessGroupList();
    })();
  }, []);

  useEffect(() => {
    isAllowedToSaveForm();
  }, [userInfo.firstName, userInfo.lastName, userInfo.email]);

  async function getAccessGroupList() {
    const accessGroups = await AccessGroupService.getAll();
    setAccessGroup(accessGroups);
  }

  async function getUserInfo() {
    const user = await UsersService.getOne(idUser);
    setUserInfo(user);
  }

  async function handleUpdateUser(e) {
    e.preventDefault();
    try {
      const response = await UsersService.update({
        id: userInfo.id,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        admin: userInfo.admin,
        language: userInfo.language,
        active: userInfo.active,
        type: userInfo.type,
        daysToTrial: Number(userInfo.daysToTrial),
        accessGroupID: userInfo.accessGroupID,
      });
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      navigate(-1);
    }
  }

  function isAllowedToSaveForm() {
    if (userInfo) {
      const firstNameIsValid = userInfo?.firstName?.length >= 3 || false;
      const lastNameIsValid = userInfo?.firstName?.length >= 3 || false;
      const emailNameIsValid = userInfo?.email?.length >= 4 || false;

      if (firstNameIsValid && lastNameIsValid && emailNameIsValid) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }
  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton to="/admin/users" />
      </TitlePage>
      <FormUser
        user={userInfo}
        setUser={setUserInfo}
        createByClientPath="edit"
        accessGroup={accessGroup}
        isDisabled={isDisabled}
        onSave={handleUpdateUser}
      />
    </>
  );
}
