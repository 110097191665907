import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SideBarVideosContext } from '../../context/SideBarVideosContext';
import Loader from '../Loader';
import { ProgressBar } from '../ProgressBar';
import { NoDataMessage } from '../NoDataMessage';
import Arrow from '../../styles/logos/arrow.svg';
import { Translate } from './translate';
import {
  Menu, TitleContainer, MainTitle, Category, SubCategory, Post,
} from './style';

export function MenuSideBarVideos() {
  const translate = Translate();
  const {
    list, isLoadingList, product, percentageWatched,
  } = useContext(SideBarVideosContext);

  function handleSubCategoryList(e) {
    toogleList(e);
    upDownArrowSubCategory(e, 'submenu-category');
    highlightItemSelected(e);
  }

  function handlePostList(e) {
    toogleList(e);
    upDownArrowSubCategory(e, 'submenu-subcategory');
  }

  function toogleList(e) {
    let listSubcategories = '';
    if (e.target.tagName === 'IMG' || e.target.tagName === 'SPAN') {
      listSubcategories = e.target.parentElement.nextElementSibling;
    } else {
      listSubcategories = e.target.nextElementSibling;
    }
    listSubcategories.style.display = listSubcategories.style.display === 'block' ? 'none' : 'block';
  }

  function upDownArrowSubCategory(e, classeAllowed) {
    let arrow = '';
    if (e.target.tagName === 'IMG') {
      arrow = e.target;
    }
    if (e.target.tagName === 'SPAN') {
      arrow = e.target.nextElementSibling;
    }
    if (e.target.classList.contains(classeAllowed)) {
      const imgElement = e.target.children[1];
      arrow = imgElement;
    }
    arrow.style.transform = arrow.style.transform === 'rotate(0deg)' || Boolean(arrow.style.transform) === false ? 'rotate(-180deg)' : 'rotate(0deg)';
  }

  function highlightItemSelected(e) {
    let itemSelected = '';
    if (e.target.tagName === 'IMG' || e.target.tagName === 'SPAN') {
      itemSelected = e.target.parentElement;
    } else {
      itemSelected = e.target;
    }
    itemSelected.style.display = itemSelected.classList.contains('highlight')
      ? itemSelected.classList.remove('highlight')
      : itemSelected.classList.add('highlight');
  }

  return (
    <Menu>
      <TitleContainer>
        <MainTitle color={product.colorName}>{translate.language === 'pt-BR' ? product.name_pt : product.name_en}</MainTitle>
        <ProgressBar value={percentageWatched} color={product.colorName} showPercentage />
      </TitleContainer>
      {(list.length === 0 && !isLoadingList) && (
        <NoDataMessage>No List to show</NoDataMessage>
      )}
      {(isLoadingList)
        ? <Loader loading={isLoadingList} />
        : (
          <ul>
            {list?.map((menu) => (
              menu.category.has_subcategory === 'N'
                ? (
                  <Category color={product.colorName} key={menu.category.id}>
                    <Link to={`/client/${product.type}/${product.id}/${menu.category.video_id}`}>
                      {translate.language === 'pt-BR' ? menu.category.name_pt : menu.category.name_en}
                    </Link>
                  </Category>
                )
                : (
                  menu.category.has_subcategory === 'Y' && (
                  <Category color={product.colorName} key={menu.category.id}>
                    <div className="submenu-category" onClick={(e) => handleSubCategoryList(e)}>
                      <span>{translate.language === 'pt-BR' ? menu.category.name_pt : menu.category.name_en}</span>
                      <img src={Arrow} alt="Arrow" />
                    </div>
                    <ul className="list-subcategories">
                      {menu.category?.subcategory?.map((subcategory) => (
                        subcategory.hasPosts === 'N'
                          ? (
                            <SubCategory color={product.colorName} key={subcategory.id}>
                              <Link to={`/client/${product.type}/${product.id}/${subcategory.video_id}`}>
                                {translate.language === 'pt-BR' ? subcategory.name_pt : subcategory.name_en}
                              </Link>
                            </SubCategory>
                          )
                          : (
                            <SubCategory color={product.colorName} key={subcategory.id}>
                              <div className="submenu-subcategory" onClick={(e) => handlePostList(e)}>
                                <span>{translate.language === 'pt-BR' ? subcategory.name_pt : subcategory.name_en}</span>
                                <img src={Arrow} alt="Arrow" />
                              </div>
                              <ul className="list-posts">
                                {subcategory?.posts?.map((post) => (
                                  <Post color={product.colorName} key={post.id}>
                                    <Link to={`/client/${product.type}/${product.id}/${post.video_id}`}>
                                      {translate.language === 'pt-BR' ? post.name_pt : post.name_en}
                                    </Link>
                                  </Post>
                                ))}
                              </ul>
                            </SubCategory>
                          )
                      ))}
                    </ul>
                  </Category>
                  )
                )
            ))}
          </ul>
        )}
    </Menu>
  );
}
