import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  margin-bottom: 24px;

  * {
    margin: 0;
    padding: 0;
  }

  h3 {
    color: ${({ theme }) => theme.grayLight};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-top: 32px;
      text-align: center;
    }
  }

`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;

  .container-last-videos {
    h3 {
      margin-bottom: 16px;
    }

    .container-last-videos-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
`;
