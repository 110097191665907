/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faTruckMonster } from '@fortawesome/free-solid-svg-icons';
import AccessGroupService from '../../../../services/AccessGroup';
import AccessGroupModuleService from '../../../../services/AccessGroupModule';
import ModuleService from '../../../../services/Modules';
import TitlePage from '../../../../components/TitlePage';
import BackButton from '../../../../components/BackButton';
import Loader from '../../../../components/Loader';
import { AccessGroupForm } from '../components/AccessGroupForm';
import toast from '../../../../helpers/toast';
import { renameAsRestriction } from '../utils/renameAsRestriction';
import { Translate } from '../AccessGroupAdd/translate';

export function AccessGroupEdit() {
  const translate = Translate();
  const { id } = useParams();
  const navigate = useNavigate();
  const [accessGroup, setAccessGroup] = useState({ name: '' });
  const [modules, setModules] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoadingInfos, setIsLoadingInfos] = useState(true);

  // Get Modules from Database
  useEffect(() => {
    getModules();
  }, []);

  // Check inputs form every time that modules's and accessGroup.name's states are modify
  useEffect(() => {
    checkFormToAllowSave();
  }, [modules, accessGroup.name]);

  async function getModules() {
    try {
      setIsLoadingInfos(true);
      const moduleList = await ModuleService.getAll();
      const modulesWithSelectedAdded = moduleList?.map((module) => (
        {
          ...module,
          namePt: renameAsRestriction(module.restriction, module.namePt),
          nameEn: renameAsRestriction(module.restriction, module.nameEn),
          selected: false,
        }));
      const modules = await getModulesById(modulesWithSelectedAdded);
      setModules(modules);
    } finally {
      setIsLoadingInfos(false);
    }
  }

  async function getModulesById(modules) {
    if (id) {
      try {
        const listOfAccessGroupModule = await AccessGroupModuleService.getAllById(id);
        const accessGroupInfo = await AccessGroupService.getOne(id);
        setAccessGroup({ name: accessGroupInfo.name });
        const modulesIds = listOfAccessGroupModule.map((module) => module.moduleId);
        const newModulesWithSelectedVerified = modules.map((module) => ({
          ...module,
          selected: modulesIds.includes(module.id) ? true : false,
        }));
        return newModulesWithSelectedVerified;
      } catch (error) {
        toast('danger', error.message);
        navigate('/admin/access-group');
      }
    }
  }

  async function saveForm(e) {
    e.preventDefault();
    setIsButtonDisabled(true);
    const arrayModulesIDsFiltered = modules.filter((module) => (module.selected === true)).map((module) => module.id);

    try {
      const response = await AccessGroupService.update({ id, name: accessGroup.name, modules: arrayModulesIDsFiltered });
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsButtonDisabled(false);
      navigate('/admin/access-group');
    }
  }

  function checkFormToAllowSave() {
    const modulesToAddCheck = modules.filter((module) => module.selected === true);
    if (accessGroup.name.length >= 3 && modulesToAddCheck.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(faTruckMonster);
    }
  }

  function selectModuleToAdd(e) {
    const idSelected = e.target.value;
    const updateModules = modules.map((module) => (module.id === Number(idSelected) ? { ...module, selected: !module.selected } : { ...module }));
    setModules(updateModules);
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton />
      </TitlePage>
      {isLoadingInfos
        ? <Loader loading={isLoadingInfos} />
        : (
          <AccessGroupForm
            accessGroup={accessGroup}
            modules={modules}
            isButtonDisabled={isButtonDisabled}
            setAccessGroup={setAccessGroup}
            saveForm={saveForm}
            selectModuleToAdd={selectModuleToAdd}
          />
        )}
    </>
  );
}
