import styled from 'styled-components';

export const FormInputsContainer = styled.div`
  > div {
    margin-top: 16px;
    padding: 16px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .input-large {
    width: 350px;
  }

  .c-fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    flex-wrap: wrap;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
    }

    label {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        width: 100%;
        margin-bottom: 8px;
      }
    }

    div {
      display: flex;
      gap: 8px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: column;
        width: 100%;
      }
    }

    select, input {
      border-color: ${({ theme }) => theme.grayLight};

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
        width: 100%;
      }
    }
  }

  .c-explanation {
    font-size: 13px;
    margin-top: 16px;
    color: ${({ theme }) => theme.grayLight};
  }

  .trial-container {
    display: flex;
    align-items: center;
    margin-left: 16px;

    input {
      max-width: 60px;
    }
  }
`;
