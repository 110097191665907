import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { ManageProjectContext } from '../../manageProjects.context';
import { AuthContext } from '../../../../../../context/AuthContext';

import Loader from '../../../../../../components/Loader';

import IconClipboard from '../../../../../../styles/logos/clipboard.svg';
import IconCheck from '../../../../../../styles/logos/check_icon.svg';

import { Translate } from './translate';
import {
  Container, TitleContainer, EntranceContainer, ResponseContainer, ResponseCardsContainer, Card,
} from './style';

export function ProjectInfos() {
  const translate = Translate();
  const { id } = useParams();
  const { setProjectID, projectInfos, isLoadingProjectInfo } = useContext(ManageProjectContext);
  const { userInfo } = useContext(AuthContext);

  const [visibleCards, setVisibleCards] = useState({
    foundationalCopy: false,
    foundationalCopyHumanized: false,
  });

  // CHANGE ID FROM Context function
  useEffect(() => {
    setProjectID(id);
  }, [id]);

  function handleCardsFoundational(containerId, event) {
    if (event.target.tagName === 'IMG') return;
    setVisibleCards((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  }

  function copyContent(event) {
    const element = event.target;
    const infoToCopy = element.parentElement.nextElementSibling.textContent;
    navigator.clipboard.writeText(infoToCopy);
    element.setAttribute('src', IconCheck);

    setTimeout(() => {
      element.setAttribute('src', IconClipboard);
    }, 2000);
  }

  return (
    <>
      {isLoadingProjectInfo && <Loader loading={isLoadingProjectInfo} />}
      {!isLoadingProjectInfo && (
        <Container>
          <TitleContainer>
            <h2>{projectInfos?.name}</h2>
            <p>{translate.doneBy}<span>{projectInfos?.firstName} {projectInfos?.lastName} | {projectInfos?.email}</span></p>
          </TitleContainer>

          <EntranceContainer>
            <h3>Entradas</h3>
            <div className="info-group">
              <label htmlFor="avatar">Avatar</label>
              <div>{projectInfos?.avatar}</div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">Nicho</label>
              <div>{projectInfos?.niche}</div>
            </div>
            <div className="info-group">
              <label htmlFor="avatar">Dados do site</label>
              <div>{projectInfos?.siteData}</div>
            </div>
          </EntranceContainer>

          <ResponseContainer>
            <h3>Saídas</h3>
            <ResponseCardsContainer>
              {/* TODO: Melhorar isso aqui depois */}
              {userInfo.email === 'suporte+111menu@shifters.com.br' && (
              <>
                {(projectInfos?.foundationalCopy && projectInfos?.foundationalCopy.length > 0) && (
                <Card $visible={visibleCards?.foundationalCopy}>
                  <div className="title" onClick={(e) => handleCardsFoundational('foundationalCopy', e)}>
                    <span>Foundational copy</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {projectInfos?.foundationalCopy}
                  </div>
                </Card>
                )}
                {(projectInfos?.foundationalCopyHumanized && projectInfos?.foundationalCopyHumanized.length > 0) && (
                <Card $visible={visibleCards?.foundationalCopyHumanized}>
                  <div className="title" onClick={(e) => handleCardsFoundational('foundationalCopyHumanized', e)}>
                    <span>Foundational copy Humanized</span>
                    <img
                      src={IconClipboard}
                      alt="Copy"
                      onClick={(e) => copyContent(e)}
                    />
                  </div>
                  <div className="infos">
                    {projectInfos?.foundationalCopyHumanized}
                  </div>
                </Card>
                )}
              </>
              )}
              {(projectInfos?.vslSmb && projectInfos?.vslSmb.length > 0) && (
              <Card $visible>
                <div className="title">
                  <span>VSL SMB</span>
                  <img
                    src={IconClipboard}
                    alt="Copy"
                    onClick={(e) => copyContent(e)}
                  />
                </div>
                <div className="infos">
                  {projectInfos?.vslSmb}
                </div>
              </Card>
              )}
              {(projectInfos?.vslEnterprise && projectInfos?.vslEnterprise.length > 0) && (
              <Card $visible>
                <div className="title">
                  <span>VSL Enterprise</span>
                  <img
                    src={IconClipboard}
                    alt="Copy"
                    onClick={(e) => copyContent(e)}
                  />
                </div>
                <div className="infos">
                  {projectInfos?.vslEnterprise}
                </div>
              </Card>
              )}
              {(projectInfos?.salesLetterSmb && projectInfos?.salesLetterSmb.length > 0) && (
              <Card $visible>
                <div className="title">
                  <span>Sales Letter SMB</span>
                  <img
                    src={IconClipboard}
                    alt="Copy"
                    onClick={(e) => copyContent(e)}
                  />
                </div>
                <div className="infos">
                  {projectInfos?.salesLetterSmb}
                </div>
              </Card>
              )}
              {(projectInfos?.salesLetterEnterprise && projectInfos?.salesLetterEnterprise.length > 0) && (
              <Card $visible>
                <div className="title">
                  <span>Sales Letter Enterprise</span>
                  <img
                    src={IconClipboard}
                    alt="Copy"
                    onClick={(e) => copyContent(e)}
                  />
                </div>
                <div className="infos">
                  {projectInfos?.salesLetterEnterprise}
                </div>
              </Card>
              )}
              {(projectInfos?.headlines && projectInfos?.headlines.length > 0) && (
              <Card $visible>
                <div className="title">
                  <span>Headlines</span>
                  <img
                    src={IconClipboard}
                    alt="Copy"
                    onClick={(e) => copyContent(e)}
                  />
                </div>
                <div className="infos">
                  {projectInfos?.headlines}
                </div>
              </Card>
              )}
            </ResponseCardsContainer>
          </ResponseContainer>
        </Container>
      )}
    </>

  );
}
