/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import IconLikeTransparent from '../../../styles/logos/like_transparent.svg';
import { NoDataMessage } from '../../../components/NoDataMessage';
import Loader from '../../../components/Loader';
import IconLike from '../../../styles/logos/like.svg';
import IconLink from '../../../styles/logos/icon_link.svg';
import {
  Container, ContainerVideoArea, CointainerVideo, VideoActionsArea, VideoArea, VideoLikesArea, Likes, VideoLinkArea,
} from './style';
import { Translate } from './translate';
import { FeedContext } from '../../../context/FeedContext';

export function Feed() {
  const translate = Translate();
  const {
    videosFeed, isLoadingVideos, handleLike,
  } = useContext(FeedContext);

  return (
    <Container>
      <h2>{translate.title}</h2>
      <ContainerVideoArea>
        {isLoadingVideos && <Loader loading={isLoadingVideos} />}
        {(!isLoadingVideos && videosFeed?.length > 0) && (
          videosFeed?.map((video) => (
            <CointainerVideo key={video?.id}>
              <VideoArea>
                <div className="video">
                  {(video?.link_video) && parse(video.link_video)}
                </div>
                <p>{translate.language === 'pt-BR' ? video?.title_pt : video?.title_en}</p>
              </VideoArea>
              <VideoActionsArea>
                {video && (
                  <>
                    <VideoLikesArea onClick={() => handleLike(video?.id)}>
                      <div className="container-like">
                        {video?.userLiked
                          ? <img src={IconLike} alt="Like" />
                          : <img src={IconLikeTransparent} alt="Like" />}
                      </div>
                      <Likes>
                        {video?.totalLikes} {video?.totalLikes === 1 ? translate.like : translate.likes}
                      </Likes>
                    </VideoLikesArea>
                    <VideoLinkArea>
                      <Link to={`/client/${video?.product_type}/${video?.id_product}/${video?.id}`}>
                        <span>{translate.messageWholeContent}</span>
                        <img src={IconLink} alt="Open" />
                      </Link>
                    </VideoLinkArea>
                  </>
                )}
              </VideoActionsArea>
            </CointainerVideo>
          ))
        )}
      </ContainerVideoArea>
      {(!isLoadingVideos && videosFeed.length === 0) && (
        <NoDataMessage>Sem dados para gerar</NoDataMessage>
      )}
    </Container>
  );
}
