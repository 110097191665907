import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  h3 {
    color: ${({ theme }) => theme.grayLight}
  }
`;

export const ContainerFields = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-end;
    width: 100%;

    select, input {
      border-color: ${({ theme }) => theme.grayLight};
    }

    select {
      height: 37px;
    }

    input {
      cursor: auto;
    }

    button {
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        margin-top: 16px;
      }
    }
  }

  .multiselect-container {
    position: relative;
    display: inline-block;
    width: 100%;
    align-self: flex-end;
    /* margin: 20px; */
  }

  /* Botão Dropdown */
  .multiselect-btn {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      text-align: left;
      cursor: pointer;
      background-color: transparent;
      font-family: Arial, sans-serif;
      font-size: 14px;
  }

  /* Caixa de Opções */
  .multiselect-options {
      position: absolute;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      background-color: #fff;
      border: 1px solid #ccc;
      border-top: none;
      z-index: 1;
      display: none;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 4px 4px;
  }

/* Estilo dos Rótulos */
.multiselect-options label {
    display: block;
    padding: 8px 10px;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

/* Estilo dos Checkboxes */
.multiselect-options input[type="checkbox"] {
    margin-right: 10px;
}

/* Efeito de Hover para Rótulos */
.multiselect-options label:hover {
    background-color: #f1f1f1;
}

/* Opção Selecionada */
.multiselect-options label.selected {
    background-color: #e7f3ff;
}
`;

export const ContainerSelectedFilters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  margin-top: 16px;
  flex-wrap: wrap;

  hr {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid ${({ theme }) => theme.gray1};
  }

  .filters-head {
    display: flex;
    align-items: center;
    gap: 8px;

    h4 {
      font-weight: 500;
      background-color: ${({ theme }) => theme.gray1};
      color: ${({ theme, $preSelected }) => ($preSelected ? theme.yellow : theme.green)};
      padding: 2px 6px;
      border-radius: 4px;
      cursor: pointer;
    }

    img {
      width: 20px;
    }

    img:hover {
      cursor: pointer;
      filter: invert(67%) sepia(99%) saturate(433%) hue-rotate(360deg) brightness(190%) contrast(109%);
    }
  }

  .container-filters {
    display: ${({ $show }) => ($show ? 'inline-block' : 'none')};
    width: 100%;
  }

  .filter-result {
    color: ${({ theme }) => theme.grayLight};
  }

  .filter-title {
    /* text-decoration: underline; */
    font-weight: 500;
  }

  .filter-subtitles-area {
    padding-left: 8px;
    font-size: 15px;
  }
`;




