import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import IconCloseX from '../../styles/logos/icon_close_x.svg';
import * as S from './styles';
import { Translate } from './translate';

export default function Modal({
  title, children, visible, btnMessage, danger, onClose, onAction, btnDisabled, color,
}) {
  const translate = Translate();

  function handleClose() {
    onClose();
  }

  function handleAction() {
    onAction();
  }

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <S.ContainerBlur>
      <S.ContainerInfo>
        <S.ContainerInfos>
          <div className="title-container">
            <h2>{title}</h2>
            <img src={IconCloseX} alt="Close" onClick={handleClose} />
          </div>
          <div className="children-container">{children}</div>
        </S.ContainerInfos>
        <S.ContainerBtns>
          <button
            className={danger ? 'btn-cancel tertiary' : 'btn-cancel primary'}
            onClick={handleClose}
            type="button"
          >
            {translate.cancelBtn}
          </button>
          <button
            className={danger ? 'btn-tertiary' : 'btn-primary'}
            onClick={() => handleAction()}
            type="button"
            disabled={btnDisabled}
          >
            {btnMessage || translate.saveBtn}
          </button>
        </S.ContainerBtns>
      </S.ContainerInfo>
    </S.ContainerBlur>,
    document.getElementById('modals-root'),
  );
}

Modal.defaultProps = {
  danger: false,
  btnDisabled: false,
};

Modal.propTypes = {
  danger: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  btnMessage: PropTypes.string,
  btnDisabled: PropTypes.bool,
  color: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};
