import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }

  .rating-stars {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 100%;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
    }

    .rating-stars-arrow-container {
      flex: 1;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      &:hover .ratings-hidden-container {
        opacity: 1;
        pointer-events: all;
        transition: .3s ease-in-out;
        z-index: 98;
      }

      .rating-starts-modal-clicked {
        display: flex;
        align-items: center;
        gap: 8px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
          flex-direction: column;
          text-align: center;
        }

        img {
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
      }

      .ratings-hidden-container {
        position: absolute;
        top: 26px;
        left: -50%;
        opacity: 0;

        pointer-events: none;
        width: 400px;
        border: 1px solid ${({ theme }) => theme.grayDark};
        background-color: ${({ theme }) => theme.grayMenu};
        border-radius: 8px;
        padding: 16px;
      }
    }

    a {
      padding: 0;
      margin:0;
      flex: 1;
      font-size: 13px;
      color: ${({ theme }) => theme.grayLight};
      min-width: 120px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        text-align: center;
      }

    }
  }

  .rating-comment {
    text-align: right;

     @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-top: 24px;
    }

    button {
      padding: 8px;
    }
  }
`;

export const SpanBtn = styled.span`
  color: ${({ theme }) => theme.fontWhite};

  &:hover {
    color: ${({ color, theme }) => theme[color] || theme.grayLight}
  }
`;
