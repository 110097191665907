import React, { useState, useContext } from 'react';

import { B2BDataBaseFilterContext } from '../../B2BDatabaseFilter.context';

import Square from '../../../../../../components/Square';
import { Button } from '../../../../../../components/Button';
import { MultiSelect } from '../../../../../../components/MultiSelect';
import { MultiSelectText } from '../MultiSelectText';

import IconHide from '../../../../../../styles/logos/icon_hide.svg';
import IconShow from '../../../../../../styles/logos/icon_show.svg';

import { Translate } from './translate';
import { Container, ContainerFields, ContainerSelectedFilters } from './style';

export function Filter() {
  const translate = Translate();
  const {
    filtersList, filterSelected, setFilterSelected, filterDescription, filterPreSelectedFilter, applyFilter,
  } = useContext(B2BDataBaseFilterContext);
  const [showFilters, setShowFilters] = useState(true);

  function handleSubmit() {
    // Runs the function from B2bDatabaseData.context
    applyFilter();
  }

  function joinResults(array) {
    if (array?.length === 0) return 'All';
    return array?.map((item) => item).join(', ');
  }

  function updateFilters() {
    // eslint-disable-next-line no-restricted-syntax
    for (let [item, value] of Object.entries(filterSelected)) {
      const keyItem = item;
      const arrValue = value;
      setFilterSelected((prevState) => ({ ...prevState, [`${keyItem}`]: arrValue }));
    }
  }

  function handletoogleShowFilters() {
    setShowFilters((prevState) => !prevState);
  }

  return (
    <Square size="large">
      <Container>
        <h3>{translate.title}</h3>
        <ContainerFields>

          <MultiSelectText
            label={translate.emailFirst}
            keyValue="emailFirst"
          />

          <MultiSelectText
            label={translate.emailSecond}
            keyValue="emailSecond"
          />

          <MultiSelectText
            label={translate.nameFirst}
            keyValue="nameFirst"
          />

          <MultiSelectText
            label={translate.nameMiddle}
            keyValue="nameMiddle"
          />

          <MultiSelectText
            label={translate.nameLast}
            keyValue="nameLast"
          />

          <MultiSelect
            options={filtersList?.country?.map((item) => item)}
            label={translate.country}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="country"
          />

          <MultiSelect
            options={filtersList?.state?.map((item) => item)}
            label={translate.state}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="state"
          />

          <MultiSelect
            options={filtersList?.city?.map((item) => item)}
            label={translate.city}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="city"
          />

          <MultiSelectText
            label={translate.url}
            keyValue="url"
          />

          <MultiSelectText
            label={translate.role}
            keyValue="position"
          />

          <MultiSelectText
            label={translate.sector}
            keyValue="sector"
          />

          <MultiSelect
            options={filtersList?.technology?.map((item) => item)}
            label={translate.technology}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="technology"
          />

          <MultiSelect
            options={filtersList?.employeesQuantity?.map((item) => item)}
            label={translate.employeesQuantity}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="employeesQuantity"
          />

          <MultiSelect
            options={filtersList?.annualRevenue?.map((item) => item)}
            label={translate.annualRevenue}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="annualRevenue"
          />

          <MultiSelect
            options={filtersList?.headquartersRegion?.map((item) => item)}
            label={translate.headquartersRegion}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="headquartersRegion"
          />

          <MultiSelectText
            label={translate.company}
            keyValue="company"
          />

          <MultiSelectText
            label={translate.companyDomain}
            keyValue="companyDomain"
          />

          <MultiSelectText
            label={translate.linkedinID}
            keyValue="linkedinID"
          />

          <MultiSelect
            options={filtersList?.listName?.map((item) => item)}
            label={translate.listName}
            placeholder="All"
            onSetFilter={setFilterSelected}
            name="listName"
          />

          <div className="form-group">
            <label htmlFor="database">{translate.listMeta}</label>
            <select
              id="database"
              value={setFilterSelected.listMeta}
              onChange={(e) => setFilterSelected((prevState) => ({ ...prevState, listMeta: e.target.value }))}
            >
              <option value="">All</option>
              <option value="Y">Yes/Sim</option>
              <option value="N">No/Não</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="database">{translate.database}</label>
            <select
              id="database"
              value={setFilterSelected.typeBase}
              onChange={(e) => setFilterSelected((prevState) => ({ ...prevState, typeBase: e.target.value }))}
            >
              <option value="">All Data</option>
              <option value="verified">B2B email - 100% verified </option>
              <option value="notVerified">B2C email - Not verified</option>
            </select>
          </div>

          {/* <div className="form-group">
            <label htmlFor="keyword-filter">Search filter</label>
            <input
              id="keyword-filter"
              value={setFilterSelected.searchFilter}
              onChange={(e) => setFilterSelected((prevState) => ({ ...prevState, searchFilter: e.target.value }))}
            />
          </div> */}

          <div className="form-group">
            <Button type="button" color="blue" onClick={handleSubmit}>{translate.btnApply}</Button>
          </div>
        </ContainerFields>
      </Container>

      {/* Filters */}
      <ContainerSelectedFilters $show={showFilters} $preSelected={filterPreSelectedFilter}>
        <div className="filters-head">
          <h4>{filterDescription}</h4>
          <img onClick={handletoogleShowFilters} src={showFilters ? IconHide : IconShow} alt="Toogle Filters" />
        </div>
        <div className="container-filters">
          <hr />

          {/* Primeiro Email/First email filter */}
          {(filterSelected?.emailFirst.exact.length > 0
            || filterSelected?.emailFirst.contains.length > 0
            || filterSelected?.emailFirst.exactNegative.length > 0
            || filterSelected?.emailFirst.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.emailFirst}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.emailFirst.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.emailFirst.exact)}</span>
                  </div>
                  )}
                  {filterSelected.emailFirst.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.emailFirst.contains)}</span>
                  </div>
                  )}
                  {filterSelected.emailFirst.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.emailFirst.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.emailFirst.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.emailFirst.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.emailFirst}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          {/* Segundo Email/Second email filter */}
          {(filterSelected?.emailSecond.exact.length > 0
            || filterSelected?.emailSecond.contains.length > 0
            || filterSelected?.emailSecond.exactNegative.length > 0
            || filterSelected?.emailSecond.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.emailSecond}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.emailSecond.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.emailSecond.exact)}</span>
                  </div>
                  )}
                  {filterSelected.emailSecond.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.emailSecond.contains)}</span>
                  </div>
                  )}
                  {filterSelected.emailSecond.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.emailSecond.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.emailSecond.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.emailSecond.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.emailSecond}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          {/* Primeiro Nome/First name filter */}
          {(filterSelected?.nameFirst.exact.length > 0
            || filterSelected?.nameFirst.contains.length > 0
            || filterSelected?.nameFirst.exactNegative.length > 0
            || filterSelected?.nameFirst.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.nameFirst}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.nameFirst.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameFirst.exact)}</span>
                  </div>
                  )}
                  {filterSelected.nameFirst.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameFirst.contains)}</span>
                  </div>
                  )}
                  {filterSelected.nameFirst.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameFirst.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.nameFirst.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameFirst.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.nameFirst}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          {/* Meio Nome/Middle name filter */}
          {(filterSelected?.nameMiddle.exact.length > 0
            || filterSelected?.nameMiddle.contains.length > 0
            || filterSelected?.nameMiddle.exactNegative.length > 0
            || filterSelected?.nameMiddle.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.nameMiddle}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.nameMiddle.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameMiddle.exact)}</span>
                  </div>
                  )}
                  {filterSelected.nameMiddle.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameMiddle.contains)}</span>
                  </div>
                  )}
                  {filterSelected.nameMiddle.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameMiddle.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.nameMiddle.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameMiddle.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.nameMiddle}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          {/* Último Nome/Last name filter */}
          {(filterSelected?.nameLast.exact.length > 0
            || filterSelected?.nameLast.contains.length > 0
            || filterSelected?.nameLast.exactNegative.length > 0
            || filterSelected?.nameLast.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.nameLast}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.nameLast.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameLast.exact)}</span>
                  </div>
                  )}
                  {filterSelected.nameLast.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameLast.contains)}</span>
                  </div>
                  )}
                  {filterSelected.nameLast.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameLast.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.nameLast.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.nameLast.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.nameLast}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          <div>
            <span className="filter-title">{translate.country}: </span>
            <span className="filter-result">{joinResults(filterSelected?.country)}</span>
          </div>
          <div>
            <span className="filter-title">{translate.state}: </span>
            <span className="filter-result">{joinResults(filterSelected?.state)}</span>
          </div>
          <div>
            <span className="filter-title">{translate.city}: </span>
            <span className="filter-result">{joinResults(filterSelected?.city)}</span>
          </div>

          {/* Cargo/Position filter */}
          {(filterSelected?.position.exact.length > 0
            || filterSelected?.position.contains.length > 0
            || filterSelected?.position.exactNegative.length > 0
            || filterSelected?.position.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.role}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.position.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.position.exact)}</span>
                  </div>
                  )}
                  {filterSelected.position.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.position.contains)}</span>
                  </div>
                  )}
                  {filterSelected.position.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.position.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.position.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.position.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.role}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          {/* url/url filter */}
          {(filterSelected?.url.exact.length > 0
            || filterSelected?.url.contains.length > 0
            || filterSelected?.url.exactNegative.length > 0
            || filterSelected?.url.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.url}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.url.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.url.exact)}</span>
                  </div>
                  )}
                  {filterSelected.url.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.url.contains)}</span>
                  </div>
                  )}
                  {filterSelected.url.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.url.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.url.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.url.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.url}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          {/* Setor/Sector filter */}
          {(filterSelected?.sector.exact.length > 0
            || filterSelected?.sector.contains.length > 0
            || filterSelected?.sector.exactNegative.length > 0
            || filterSelected?.sector.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.sector}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.sector.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.sector.exact)}</span>
                  </div>
                  )}
                  {filterSelected.sector.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.sector.contains)}</span>
                  </div>
                  )}
                  {filterSelected.sector.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.sector.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.sector.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.sector.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.sector}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          <div>
            <span className="filter-title">{translate.technology}: </span>
            <span className="filter-result">{joinResults(filterSelected?.technology)}</span>
          </div>
          <div>
            <span className="filter-title">{translate.employeesQuantity}: </span>
            <span className="filter-result">{joinResults(filterSelected?.employeesQuantity)}</span>
          </div>
          <div>
            <span className="filter-title">{translate.annualRevenue}: </span>
            <span className="filter-result">{joinResults(filterSelected?.annualRevenue)}</span>
          </div>
          <div>
            <span className="filter-title">{translate.headquartersRegion}: </span>
            <span className="filter-result">{joinResults(filterSelected?.headquartersRegion)}</span>
          </div>

          {/* Empresa/Company filter */}
          {(filterSelected?.company.exact.length > 0
            || filterSelected?.company.contains.length > 0
            || filterSelected?.company.exactNegative.length > 0
            || filterSelected?.company.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.company}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.company.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.company.exact)}</span>
                  </div>
                  )}
                  {filterSelected.company.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.company.contains)}</span>
                  </div>
                  )}
                  {filterSelected.company.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.company.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.company.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.company.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.company}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          {/* Domínio daEmpresa/Company Domain filter */}
          {(filterSelected?.companyDomain.exact.length > 0
            || filterSelected?.companyDomain.contains.length > 0
            || filterSelected?.companyDomain.exactNegative.length > 0
            || filterSelected?.companyDomain.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.companyDomain}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.companyDomain.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.companyDomain.exact)}</span>
                  </div>
                  )}
                  {filterSelected.companyDomain.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.companyDomain.contains)}</span>
                  </div>
                  )}
                  {filterSelected.companyDomain.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.companyDomain.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.companyDomain.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.companyDomain.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.companyDomain}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          {/* Domínio daEmpresa/Company Domain filter */}
          {(filterSelected?.linkedinID.exact.length > 0
            || filterSelected?.linkedinID.contains.length > 0
            || filterSelected?.linkedinID.exactNegative.length > 0
            || filterSelected?.linkedinID.containsNegative.length > 0) ? (
              <div>
                <span className="filter-title">{translate.linkedinID}: </span>
                <div className="filter-subtitles-area">
                  {filterSelected.linkedinID.exact?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.linkedinID.exact)}</span>
                  </div>
                  )}
                  {filterSelected.linkedinID.contains?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.constainsPositive}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.linkedinID.contains)}</span>
                  </div>
                  )}
                  {filterSelected.linkedinID.exactNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.exactNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.linkedinID.exactNegative)}</span>
                  </div>
                  )}
                  {filterSelected.linkedinID.containsNegative?.length > 0 && (
                  <div>
                    <span className="filter-subtitle">{translate.containsNegative}: </span>
                    <span className="filter-result">{joinResults(filterSelected?.linkedinID.containsNegative)}</span>
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div>
                <span className="filter-title">{translate.linkedinID}: </span>
                <span className="filter-result">All</span>
              </div>
            )}

          <div>
            <span className="filter-title">{translate.listName}: </span>
            <span className="filter-result">{joinResults(filterSelected?.listName)}</span>
          </div>
          <div>
            <span className="filter-title">{translate.listMeta}: </span>
            <span className="filter-result">{filterSelected.listMeta === '' ? 'All' : filterSelected.listMeta === 'Y' ? 'Yes/Sim' : 'No/Não'}</span>
          </div>
          <div>
            <span className="filter-title">{translate.database}: </span>
            <span className="filter-result">{filterSelected.typeBase === '' ? 'All' : filterSelected.typeBase === 'verified' ? 'B2B email - 100% verified' : 'B2C email - Not verified'}</span>
          </div>
          {/* {filterSelected.searchFilter?.length > 0 && (
            <div>
              <span className="filter-title">Search filter: </span>
              <span className="filter-result">{filterSelected.searchFilter}</span>
            </div>
          )} */}
        </div>
      </ContainerSelectedFilters>
    </Square>
  );
}
