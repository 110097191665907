import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-top: 16px;

  a {
    width: 100%;
    text-align: center;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;

  .c-search {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 2;

    input {
      width:  100%;
    }
  }

  .c-actions {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.smartphone}) {
      width: 100%;
    }

    a {
      width: 100%;
    }
    input {
      width:  100%;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 19px;
  }

  img:hover {
    cursor: pointer;
    filter: invert(40%) sepia(6%) saturate(233%) hue-rotate(187deg) brightness(95%) contrast(84%)
  }

  .c-users {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.smartphone}) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  }

  .c-users-name {
    width: auto;
    display: flex;
    flex-direction: column;

    .email {
      color: ${({ theme }) => theme.gray5};
      font-size: 14px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.smartphone}) {
        font-size: 12px;
        word-break: break-word;
      }
    }

    .name-complete {
      color: ${({ theme }) => theme.gray3};
      font-size: 14px;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.smartphone}) {
        font-size: 12px;
        word-break: break-word;
      }
    }
  }

  .c-badges {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 24px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      flex-direction: column;
      align-items: flex-start;
      margin-right: 16px;
    }

    span, span a {
      display: flex;
      align-items: center;
      gap: 4px;

      img {
        width: 14px;
      }
    }
  }

  .c-users-actions {
    display: flex;
    gap: 8px;
  }
`;
