export function nicheList(type, language) {
  switch (type) {
    case 'B2B':
      if (language === 'pt-BR') {
        return [
          'Agência de Marketing e Publicidade',
          'Agronegócio',
          'Consultorias e Treinamentos',
          'Ecommerce',
          'Educação e Ensino',
          'Engenharia e Indústria Geral',
          'ESG',
          'Eventos',
          'Financeiro',
          'Governo e Órgãos Públicos',
          'Hardware e Eletrônicos',
          'Imobiliárias',
          'Jurídico e Serviços Relacionados',
          'Mídia e Comunicação',
          'ONGs',
          'Saúde e Estética',
          'Serviços em Geral',
          'Serviços em RH e Coaching',
          'Software e Cloud',
          'Telecomunicação',
          'Turismo e Lazer',
          'Varejo - Alimentação',
          'Varejo - Geral',
          'Varejo - Vestuário',
        ];
      }
      return [
        'Marketing and Advertising Agency',
        'Agribusiness',
        'Consulting and Training',
        'Ecommerce',
        'Education and Teaching',
        'Engineering and General Industry',
        'ESG',
        'Events',
        'Financial',
        'Government and Public Agencies',
        'Hardware and Electronics',
        'Real Estate',
        'Legal and Related Services',
        'Media and Communication',
        'NGOs',
        'Health and Aesthetics',
        'General Services',
        'HR and Coaching Services',
        'Software and Cloud',
        'Telecommunication',
        'Tourism and Leisure',
        'Retail - Food',
        'Retail - General',
        'Retail - Clothing',
      ];

    case 'B2C':
      if (language === 'pt-BR') {
        return [
          'Alimentação e Bebidas',
          'Artesanato e Produtos Feitos à Mão',
          'Artigos Esportivos',
          'Beleza e Cosméticos',
          'Brinquedos e Jogos',
          'Casa e Decoração',
          'Cursos Online e Educação',
          'Eletrônicos e Gadgets',
          'Esportes e Fitness',
          'Fashion e Acessórios',
          'Games e Entretenimento Digital',
          'Hobbies e Colecionáveis',
          'Jardinagem e Paisagismo',
          'Livros e Conteúdo Digital',
          'Maternidade e Bebês',
          'Moda Feminina',
          'Moda Masculina',
          'Moda Plus Size',
          'Música e Instrumentos Musicais',
          'Pets e Produtos para Animais',
          'Produtos Naturais e Orgânicos',
          'Produtos Sustentáveis',
          'Saúde e Bem-estar',
          'Suplementos e Vitaminas',
          'Viagens e Turismo',
        ];
      }
      return [
        'Food and Beverages',
        'Crafts and Handmade Products',
        'Sporting Goods',
        'Beauty and Cosmetics',
        'Toys and Games',
        'Home and Decor',
        'Online Courses and Education',
        'Electronics and Gadgets',
        'Sports and Fitness',
        'Fashion and Accessories',
        'Gaming and Digital Entertainment',
        'Hobbies and Collectibles',
        'Gardening and Landscaping',
        'Books and Digital Content',
        'Maternity and Baby Products',
        'Women\'s Fashion',
        'Men\'s Fashion',
        'Plus Size Fashion',
        'Music and Musical Instruments',
        'Pets and Pet Supplies',
        'Natural and Organic Products',
        'Sustainable Products',
        'Health and Wellness',
        'Supplements and Vitamins',
        'Travel and Tourism',
      ];


    default:
      return [];
  }
}
