export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Editar nome' : 'Edit name',
    btn: language === 'pt-BR' ? 'Salvar' : 'Submit',
    label: language === 'pt-BR' ? 'Nome' : 'Name',
    language,
  };
}
