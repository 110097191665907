import React, { useContext, useEffect, useState } from 'react';
import {
  useParams, useSearchParams, useNavigate,
} from 'react-router-dom';

import TitlePage from '../../../../../../components/TitlePage';
import { Button } from '../../../../../../components/Button';

import { Translate } from './translate';
import { Form, FormGroup } from './style';
import { ManageProjectContext } from '../../manageProjects.context';

export function EditName() {
  const translate = Translate();

  const { name, setName, submitFormEditName } = useContext(ManageProjectContext);
  const { id } = useParams();
  const [queryParams] = useSearchParams();
  const nameParam = queryParams.get('name');

  // const [name, setName] = useState('');
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);

  useEffect(() => {
    toogleDisabledBtn();
  }, []);

  useEffect(() => {
    toogleDisabledBtn();
  }, [name]);

  // UPLOAD PAGE
  useEffect(() => {
    setName(nameParam);
  }, [id, nameParam]);

  function toogleDisabledBtn() {
    const isDisabled = name?.length >= 2 || false;
    setIsDisabledBtn(!isDisabled);
  }

  function submitForm(event) {
    submitFormEditName(event, id);
    setIsDisabledBtn(true);
  }

  return (
    <div>
      <TitlePage title={translate.title} />
      <Form onSubmit={(e) => submitForm(e)}>
        <FormGroup>
          <label htmlFor="new-name">{translate.label}</label>
          <input
            type="text"
            name="new-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Button
            type="submit"
            color="blue"
            disabled={isDisabledBtn}
          >
            {translate.btn}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
}

