export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Novo Projeto' : 'New Project',
    messageProccessInfo: language === 'pt-BR'
      ? 'Estamos organizando suas informações. Isso pode levar até 90 segundos. Por favor, não saia desta tela.'
      : 'We are organizing your information. This may take up to 90 seconds. Please do not leave this screen.',
    btnOne: language === 'pt-BR' ? 'Gerar Master Ad Copy' : 'Generate Master Ad Copy',
    btnTwo: language === 'pt-BR' ? 'Gerar Content Hub' : 'Generate Content Hub',
    questionModel: language === 'pt-BR' ? 'Seu negócio é de ofertas B2C ou B2B?' : 'Is your business B2C or B2B?',
    questionAvatar: language === 'pt-BR' ? 'Qual o seu Avatar?' : 'What is your Avatar?',
    questionNiche: language === 'pt-BR' ? 'Qual o seu nicho?' : 'What is your niche?',
    questionSite: language === 'pt-BR' ? 'Dados do site / Landing Page?' : 'Site / Landing Page data?',
    placeholderAvatar: language === 'pt-BR' ? 'Insira as informações do seu avatar' : 'Enter your avatar\'s information',
    placeholderNiche: language === 'pt-BR' ? 'Insira as informações do seu nicho' : 'Enter your niche\'s information',
    placeholderSite: language === 'pt-BR' ? 'Insira as informações do seu site' : 'Enter your site\'s information',
    btnsAddAll: language === 'pt-BR' ? 'Adicionar todos' : 'Add all',
    btnsExplore: language === 'pt-BR' ? 'Explorar sugestões' : 'Explore suggestions',
    language,
  };
}
