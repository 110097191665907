import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import UsersService from '../../../../services/Users';

import TitlePage from '../../../../components/TitlePage';
import BackButton from '../../../../components/BackButton';
import Loader from '../../../../components/Loader';
import { NoDataMessage } from '../../../../components/NoDataMessage';

import toast from '../../../../helpers/toast';

import { Translate } from './translate';
import { Container, Panel, Field } from './style';

export function KartraInfo() {
  const translate = Translate();
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    getData();
  }, [id]);

  async function getData() {
    setIsLoadingData(true);
    try {
      const userDB = await UsersService.getOneKartra({ id });
      setUser(userDB);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoadingData(false);
    }
  }

  return (
    <Container>
      <TitlePage title="User Info - Kartra channel">
        <BackButton to="/admin/users" />
      </TitlePage>

      {isLoadingData && <Loader loading={isLoadingData} />}
      {(!isLoadingData && Object.values(user).length === 0) && (
        <NoDataMessage>
          {translate.errorMsg}
        </NoDataMessage>
      )}
      {!isLoadingData && (
        <div>
          <Panel>
            <img src="https://d2uolguxr56s4e.cloudfront.net/img/shared/default_user.svg" alt="User" />
            <div className="infos">
              <Field>
                <strong>Perfil: </strong>
                <span>{user.perfil}</span>
              </Field>
              <Field>
                <strong>Language: </strong>
                <span>{user.language}</span>
              </Field>
              <Field>
                <strong>Type: </strong>
                <span>{user.type}</span>
              </Field>
              <Field>
                <strong>Expiration At / Days to Trial: </strong>
                <span>{user.expirationAt && user.expirationAt.split('T')[0]} / {user.daysToTrial} days</span>
              </Field>
              <Link className="edit-user" to={`/admin/users/edit/${user.userId}`} title="Edit User">
                Edit User
              </Link>
            </div>
          </Panel>

          <Field>
            <strong>Kartra ID: </strong>
            <span>{user.kartraId}</span>
          </Field>
          <Field>
            <strong>First name: </strong>
            <span>{user.firstName}</span>
          </Field>
          <Field>
            <strong>Middle name: </strong>
            <span>{user.middleName}</span>
          </Field>
          <Field>
            <strong>Last name: </strong>
            <span>{user.lastName}</span>
          </Field>
          <Field>
            <strong>Last name 2: </strong>
            <span>{user.lastName2}</span>
          </Field>
          <Field>
            <strong>E-mail: </strong>
            <span>{user.email}</span>
          </Field>
          <Field>
            <strong>Phone country code: </strong>
            <span>{user.phoneCountryCode}</span>
          </Field>
          <Field>
            <strong>Phone: </strong>
            <span>{user.phone}</span>
          </Field>
          <Field>
            <strong>Company: </strong>
            <span>{user.company}</span>
          </Field>
          <Field>
            <strong>Address: </strong>
            <span>{user.address}</span>
          </Field>
          <Field>
            <strong>City: </strong>
            <span>{user.city}</span>
          </Field>
          <Field>
            <strong>Zip: </strong>
            <span>{user.zip}</span>
          </Field>
          <Field>
            <strong>State: </strong>
            <span>{user.state}</span>
          </Field>
          <Field>
            <strong>Country: </strong>
            <span>{user.country}</span>
          </Field>
          <Field>
            <strong>Date Joined: </strong>
            <span>{user.dateJoined}</span>
          </Field>
          <Field>
            <strong>Website: </strong>
            <span>{user.website}</span>
          </Field>
          <Field>
            <strong>IP: </strong>
            <span>{user.ip}</span>
          </Field>
          <Field>
            <strong>IP Country: </strong>
            <span>{user.ipCountry}</span>
          </Field>
          <Field>
            <strong>Facebook: </strong>
            <span>{user.facebook}</span>
          </Field>
          <Field>
            <strong>Twitter/X: </strong>
            <span>{user.twitterX}</span>
          </Field>
          <Field>
            <strong>Linkedin: </strong>
            <span>{user.linkedin}</span>
          </Field>
          <Field>
            <strong>Sales Tax Id: </strong>
            <span>{user.salesTaxId}</span>
          </Field>
          <Field>
            <strong>Source: </strong>
            <span>{user.source}</span>
          </Field>
          <Field>
            <strong>Score: </strong>
            <span>{user.score}</span>
          </Field>
          <Field>
            <strong>Referring Id: </strong>
            <span>{user.referringId}</span>
          </Field>
          <Field>
            <strong>Blacklisted: </strong>
            <span>{user.blacklisted}</span>
          </Field>
          <Field>
            <strong>Source Id: </strong>
            <span>{user.sourceId}</span>
          </Field>
          <Field>
            <strong>GRDP Lead Status: </strong>
            <span>{user.gdrpLeadStatus}</span>
            <span className="info">
              <strong>Infos from Kartra docs: </strong>
              The leads GDPR status, possible values: <br />
              0 - For GDPR status off - Your account has GDPR deactivated <br />
              1 - For GDPR status not subject - The leads IP is from a country not subject to GDPR <br />
              2 - For GDPR status accepted - The lead has accepted the GDPR terms <br />
              3 - For GDPR status not accepted - The lead hasnt accepted the GDPR terms <br />
              4 - For GDPR status unknown - The leads location could not be  <br />
              5 - For GDPR status
              <br />
              <Link to="https://support.kartra.com/support/solutions/articles/153000169449-if-lead-buys-a-product" target="__blank">Kartra documentation</Link>
            </span>
          </Field>
          <Field>
            <strong>GRDP Lead Status Date: </strong>
            <span>{user.gdrpLeadStatusDate}</span>
          </Field>
          <Field>
            <strong>GRDP Lead Status IP: </strong>
            <span>{user.gdrpLeadStatusIp}</span>
          </Field>
          <Field>
            <strong>GRDP Lead Comunications: </strong>
            <span>{user.gdrpLeadCommunications}</span>
          </Field>
          <Field>
            <strong>Lead Preferred TimeZone: </strong>
            <span>{user.leadPreferredTimeZone}</span>
          </Field>
          <Field>
            <strong>Custom Fields: </strong>
            <div className="array-view">
              {user.customField?.length > 0 && JSON.parse(user.customField).map((customItem) => (
                <pre>{JSON.stringify(customItem, null, 2)}</pre>
              ))}
            </div>
          </Field>
          <Field>
            <strong>Transation Detail: </strong>
            <div className="array-view">
              <pre>{JSON.stringify(JSON.parse(user.transactionDetails), null, 2)}</pre>
            </div>
          </Field>
        </div>
      )}
    </Container>
  );
}

