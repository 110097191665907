import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  overflow-x: none;
  width: 100%;
`;

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border: 1px solid ${({ theme }) => theme.grayMenu};
  border-radius: 4px;

  thead > tr > td {
    font-weight: 500;
    background-color: ${({ theme }) => theme.grayMenu};
  }

  tbody {
    background-color: ${({ theme }) => theme.gray1};
  }

  thead > tr > td,
  tbody > tr > td {
    font-size: 14px;
    padding: 8px 4px;
    word-wrap: break-word;
  }

  tr:hover {
    background-color: ${({ theme }) => theme.grayMenu};
  }

  // first column
  td:nth-child(1) {
    max-width: 250px;
    padding: 4px 8px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      max-width: 250px;
    }
  }

  tbody > tr > td:nth-child(1) a {
    text-decoration: none;
  }

  tbody > tr > td:nth-child(1) a:hover{
    text-decoration: underline;
    color: ${({ theme }) => theme.yellow};
  }

  tbody tr .l-city {
    width: 100px;
  }

  // not first column
  td:nth-child(n+2) {
    text-align: center;
  }

  .link-container {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 16px;
    }
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.smartphone}) {
    flex-direction: column;
    align-items: space-between;
    width: 100%;
  }
`;

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.smartphone}) {
    margin-bottom: 16px;
  }


  .container-download {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.gray2};
    padding: 8px;
    border-radius: 4px;
    transition: .2s ease-in-out;
  }

  .container-download:hover {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.yellow};
  }

  .container-pagination {
    display: flex;
    gap: 8px;
    align-items:center;
    border-left: 1px solid ${({ theme }) => theme.gray2};
    padding-left: 16px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  span {
    margin-right: 8px;
    color: ${({ theme }) => theme.grayLight};
  }

  .left {
    transform: rotate(90deg);
  }

  .right {
    transform: rotate(-90deg);
  }

  .arrow {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${({ theme }) => theme.graySubMenu};
    border: 1px solid ${({ theme }) => theme.gray1};
    border-radius: 4px;
    padding: 4px 6px;
    transition: .3s ease-in-out;
  }

  .arrow:hover {
    border: 1px solid ${({ theme }) => theme.yellow};
  }


  .pagination {
    background-color: ${({ theme }) => theme.graySubMenu};
    border: 1px solid ${({ theme }) => theme.gray1};
    padding: 8px 16px;
    border-radius: 4px;
  }
`;

export const ItemModal = styled.div`
  strong {
    font-weight: 500;
  }
`;
