class VideosRatingService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findOne({ idVideo }) {
    const path = `${this.baseUrl}/video-rating/${idVideo}`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findAll() {
    const path = `${this.baseUrl}/video-rating`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findAllComments({ idVideo, startFilteredQueryParam }) {
    let path = '';
    if (startFilteredQueryParam) {
      path = `${this.baseUrl}/video-rating/comments/${idVideo}?s=${startFilteredQueryParam}`;
    } else {
      path = `${this.baseUrl}/video-rating/comments/${idVideo}`;
    }

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async store({ description, score, videoID }) {
    const path = `${this.baseUrl}/video-rating`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({ description, score, videoID }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);

    return body;
  }
}

export default new VideosRatingService();
