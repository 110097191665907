export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    language,
    labelOption: language === 'pt-BR' ? 'Escolha uma opção *' : 'Choose an option *',
    labelDescription: language === 'pt-BR' ? 'Descrição *' : 'Description *',
    btnSubmit: language === 'pt-BR' ? 'Cadastrar' : 'Add',
    messageList: language === 'pt-BR' ? 'Nenhuma lista selecionada' : 'No list selected',
    messageNoResults: language === 'pt-BR' ? 'Sem dados para esta opção' : 'No data for this option',
    title: language === 'pt-BR' ? 'Itens cadastrados' : 'Registered items',
  };
}
