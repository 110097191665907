function numberFormat(data, type = 'number', digit = 0) {
  const clientInfo = JSON.parse(localStorage.getItem('clientInfo')) || {};
  const { currency = 'USD', languageTag = 'en-US' } = clientInfo;
  // Lista de moedas: BRL, USD, EUR | https://pt.wikipedia.org/wiki/ISO_4217
  // Language Tags => "pt-BR", ""  https://www.techonthenet.com/js/language_tags.php

  let responseNumber;
  let number = 0;

  if (typeof data === 'string') {
    number = Number(data);
  } else if (data === Infinity || isNaN(data)) {
    number = 0;
  } else {
    number = data;
  }

  switch (type) {
    case 'number':
      responseNumber = new Intl.NumberFormat(languageTag, {
        style: 'decimal',
        minimumFractionDigits: digit, // Número mínimo de casas decimais
        maximumFractionDigits: digit, // Número máximo de casas decimais
      }).format(number);
      break;

    case 'percentage': {
      responseNumber = new Intl.NumberFormat(languageTag, {
        style: 'percent',
        minimumFractionDigits: digit, // Número mínimo de casas decimais
        maximumFractionDigits: digit, // Número máximo de casas decimais
      }).format(number);

      const parts = responseNumber.split(/[^\d,-]+/);
      if (parts[0] === '') {
        responseNumber = `${0}%`;
      }
      break;
    }

    case 'currency': {
      const currencyFormatter = new Intl.NumberFormat(languageTag, {
        style: 'currency',
        currency,
        minimumFractionDigits: digit, // Número mínimo de casas decimais
        maximumFractionDigits: digit,
      });
      const formattedNumber = currencyFormatter.format(number);
      // const parts = formattedNumber.split(/[^\d,-]+/);

      // if (parts[0] === '') {
      //   responseNumber = 0;
      // } else {
      //   responseNumber = formattedNumber;
      // }

      responseNumber = formattedNumber;

      break;
    }

    default:
      responseNumber = new Intl.NumberFormat(languageTag, {
        style: 'decimal',
        minimumFractionDigits: digit, // Número mínimo de casas decimais
        maximumFractionDigits: digit, // Número máximo de casas decimais
      }).format(number);
      break;
  }

  return responseNumber;
}

export { numberFormat };
