class Login {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async post(form) {
    const response = await fetch(`${this.baseUrl}/login`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ email: form.email, password: form.password }),
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(JSON.stringify({
        code: response.status,
        message: body.message,
      }));
    }

    return body;
  }

  async verifyToken(token) {
    const response = await fetch(`${this.baseUrl}/login`, {
      headers: {
        authorization: token,
        'Content-type': 'application/json',
      },
    });

    const body = await response.json();

    if (!response.ok) {
      throw new Error(JSON.stringify({
        code: response.status,
        message: body.message,
      }));
    }
    return response;
  }
}

export default new Login();
