import React, {
  useState, useEffect, useMemo, useTransition,
} from 'react';

import UsersService from '../../../../services/Users';
import toast from '../../../../helpers/toast';
import Loader from '../../../../components/Loader';

import ListUser from '../components/ListUser';

import { Translate } from './translate';

function Users() {
  const translate = Translate();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    try {
      setIsLoading(true);
      const userList = await UsersService.getAll();
      setUsers(userList);
    } catch (err) {
      toast('danger', 'Users not found');
    } finally {
      setIsLoading(false);
    }
  }

  function handleSearchTerm(e) {
    setSearchTerm(e.target.value.toLowerCase());
  }

  const usersListFiltered = useMemo(() => users?.filter((user) => {
    const listFiltered = user.firstName.toLowerCase().includes(searchTerm)
                   || user.lastName.toLowerCase().includes(searchTerm)
                   || user.email.toLowerCase().includes(searchTerm)
                   || user.profile.toLowerCase().includes(searchTerm)
                   || user.clientName?.toLowerCase().includes(searchTerm);
    return listFiltered;
  }));

  return (
    <>
      {isLoading && <Loader loading={isLoading} />}
      {!isLoading && (
        <ListUser
          pageTitle={`${translate.title}`}
          userList={users}
          userListFiltered={usersListFiltered}
          placeholderSearchTerm={translate.placeholderSearch}
          linkAddButton="/admin/users/add"
          linkToBackButton={false}
          handleSearchTerm={handleSearchTerm}
        />
      )}
    </>
  );
}

export default Users;
