import React, { useEffect, useState } from 'react';
import { Rating } from 'react-simple-star-rating';

import Square from '../Square';

import Dates_New from '../../helpers/Dates_New';

import { Translate } from './translate';
import { TitleCommentContainer, Description, TitleVideo } from './style';


export function RatingItem({ rating }) {
  const translate = Translate();

  return (
    <Square size="large" key={rating.id}>
      <TitleCommentContainer>
        <div className="rating-name">{rating.firstName} {rating.lastName}</div>
        <div className="rating-area">
          <div className="rating-score">{rating.score}</div>
          <Rating
            initialValue={Number(rating.score)}
            readonly
            transition
            size={20}
            titleSeparator=""
            allowFraction
            fillClassName="filled-icons"
            fillColor="#FFD100"
          />
        </div>
        <div className="rating-date">{translate.ratedIn}: {Dates_New.formatDatesMask(rating.dateAct)}</div>
      </TitleCommentContainer>
      {rating.description.length > 0 && (
      <Description>{rating.description}</Description>
      )}
      {(rating.titleVideoPT || rating.titleVideoEN) && (
        <TitleVideo>
          <div className="title-container">
            <span>{translate.video}: </span>
            <span className="title">
              {translate.language === 'pt-BR' ? rating.titleVideoPT : rating.titleVideoEN}
            </span>
          </div>
        </TitleVideo>
      )}
    </Square>
  );
}
