import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Content } from './Content';
import { EditName } from './EditName';
import { ProjectInfos } from './ProjectInfos';

export function RouterManageProjects() {
  return (
    <Routes>
      <Route path="" element={<Content />} />
      <Route path="project-info/:id" element={<ProjectInfos />} />
      <Route path="edit-name/:id" element={<EditName />} />
    </Routes>
  );
}
