import React, { useState, useEffect } from 'react';
import TitlePage from '../../../components/TitlePage';
import BackButton from '../../../components/BackButton';
import Square from '../../../components/Square';
import { Badge } from '../../../components/Badge';
import Loader from '../../../components/Loader';
import LogService from '../../../services/Log';
import {
  Container, HeaderSquare, Span, DescriptionContainer,
} from './styles';

export function Logs() {
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function init() {
      await getInfos();
    }

    init();
  }, []);


  async function getInfos() {
    setIsLoading(true);
    const logs = await LogService.findAll();
    setLogs(logs);
    setIsLoading(false);
  }

  return (
    <>
      <TitlePage title="Logs">
        <BackButton to="/admin" />
      </TitlePage>

      {isLoading && <Loader loading={isLoading} />}
      {!isLoading && (
        <Container>
          {logs.length > 0 && logs.map((log) => (
            <Square size="large" key={log.id}>
              <HeaderSquare>
                <Badge color={log.type === 'success' ? 'green' : 'red'}>
                  {log.type.toUpperCase()}
                </Badge>
                <Badge>
                  {new Date(log.dateAct).toString()}
                </Badge>
              </HeaderSquare>
              <div><Span> Type Action: </Span>{log.action}</div>
              <div><Span> User: </Span>{log.user}</div>
              {log.description.length > 0 && (
                <DescriptionContainer>
                  <Span>Description:</Span>
                  <pre>{JSON.stringify(JSON.parse(log.description), null, 2)}</pre>
                </DescriptionContainer>
              )}
            </Square>
          ))}
        </Container>
      )}
    </>
  );
}
