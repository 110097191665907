class B2BDatabase {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async store({ data }) {
    const path = `${this.baseUrl}/b2bdatabase`;

    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('name', data.name);
    formData.append('sector', data.sector);
    formData.append('technology', data.technology);
    formData.append('employeesQuantity', data.employeesQuantity);
    formData.append('annualRevenue', data.annualRevenue);
    formData.append('headquartersRegion', data.headquartersRegion);
    formData.append('typeBase', data.typeBase);
    for (let i = 0; i < data.file.length; i++) {
      formData.append('file', data.file[i]);
    }

    const response = await fetch(path, {
      method: 'POST',
      body: formData,
      headers: {
        authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();
    if (!response.ok) {
      throw new Error(JSON.stringify({
        code: response.status,
        message: body.message,
      }));
    }
    return body;
  }

  async findAllUserView({ filters, pagination }) {
    const path = `${this.baseUrl}/b2bdatabase/user-view`;

    const response = await fetch(path, {
      method: 'POST',
      body: JSON.stringify({ filters, pagination }),
      headers: {
        authorization: localStorage.getItem('token'),
        'content-type': 'application/json',
      },
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findAllAdminView({ filters, pagination }) {
    const path = `${this.baseUrl}/b2bdatabase/admin-view`;

    const response = await fetch(path, {
      method: 'POST',
      body: JSON.stringify({ filters, pagination }),
      headers: {
        authorization: localStorage.getItem('token'),
        'content-type': 'application/json',
      },
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }
}

export default new B2BDatabase();
