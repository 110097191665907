/* eslint-disable array-callback-return */
import React, { useContext, useState } from 'react';
import { B2BDataBaseFilterContext } from './B2BDatabaseFilter.context';
import { B2BDataBaseDataContext } from './B2BDatabaseData.context';
import B2BDatabaseRegisterDownloadService from '../../../services/B2BDatabaseRegisterDownload';
import TitlePage from '../../../components/TitlePage';
import Modal from '../../../components/Modal';
import Loader from '../../../components/Loader';
import { NoDataMessage } from '../../../components/NoDataMessage';
import { Filter } from './components/Filter';
import { ItemUser } from './components/ItemUser';
import toast from '../../../helpers/toast';
import { numberFormat } from '../../../helpers/numberFormat';
import IconDownload from '../../../styles/logos/download.svg';
import IconArrow from '../../../styles/logos/arrow.svg';
import { Translate } from './translate';
import {
  Container, TableContainer, Table, OptionsContainer, QuantityContainer, PaginationContainer, ItemModal,
} from './style';

export function B2BDatabases() {
  const {
    filtersList, filterSelected, setFilterSelected, filterApplied,
  } = useContext(B2BDataBaseFilterContext);
  const {
    b2bData, isLoadingData, totalData, totalPage, currentPage, handlePages,
  } = useContext(B2BDataBaseDataContext);
  const translate = Translate();
  const [toogleModal, setToogleModal] = useState(false);
  const [infoDownload, setInfoDownload] = useState({
    allowed: true,
    amountAuthorized: 0,
    amountRemaing: 0,
    amountUsed: 0,
    daysLeft: 0,
  });


  function handleToogleModal() {
    setToogleModal((prevState) => !prevState);
  }

  async function downloadCsv() {
    const verify = await verifyUserToDownloadCSV();
    if (!verify) return;

    const headers = `${Object.keys(b2bData[0]).join(',')}\r\n`;
    const rows = b2bData.map((item) => (
      Object.values(item)
        .map((val) => (val !== null && val !== undefined ? val : ''))
        .join(',')
    )).join('\r\n');
    const csvContent = headers + rows;

    // Create Blob com o Csv
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    // Create a temporary download link <a>
    const link = document.createElement('a');
    link.href = url;
    link.download = 'data-shifters.csv';

    // it simulates click at the link to begin download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    await registerDownload();
  }

  async function registerDownload() {
    try {
      await B2BDatabaseRegisterDownloadService.store({ filters: filterApplied });
    } catch (error) {
      toast('danger', JSON.stringify(error.message));
    }
  }

  async function verifyUserToDownloadCSV() {
    try {
      const response = await B2BDatabaseRegisterDownloadService.verifyDownload();
      setInfoDownload({
        allowed: response.allowed,
        amountAuthorized: response.amountAuthorized,
        amountRemaing: response.amountRemaing,
        amountUsed: response.amountUsed,
        daysLeft: response.daysLeft,
      });
      if (response.allowed === false) {
        setToogleModal(true);
      }
      return response.allowed;
    } catch (error) {
      toast('danger', JSON.stringify(error.message));
    }
  }

  return (
    <>
      <Modal
        visible={toogleModal}
        onClose={handleToogleModal}
        onAction={handleToogleModal}
        title="Limite excedido"
        btnMessage="OK"
      >
        <ItemModal><strong>{translate.modalAuthorized}</strong>{infoDownload.amountAuthorized}</ItemModal>
        <ItemModal><strong>{translate.modalRemaing}</strong>{infoDownload.amountRemaing}</ItemModal>
        <ItemModal><strong>{translate.modalUsed}</strong>{infoDownload.amountUsed}</ItemModal>
        <ItemModal><strong>{translate.modalDays}</strong>{infoDownload.daysLeft}</ItemModal>
      </Modal>

      <Container>
        <TitlePage title="Lead Intelligence" />
        <Filter
          filtersList={filtersList}
          filterSelected={filterSelected}
          onSetFilterSelected={setFilterSelected}
        />
        {totalData > 0 && (
        <OptionsContainer>
          <QuantityContainer>
            <div className="container-download" onClick={() => downloadCsv()}>
              <span>Download List</span>
              <img src={IconDownload} alt="download list" title="Download list" />
            </div>
            {totalData > 0 && <ItemModal><strong>{numberFormat(Number(totalData))}</strong> {translate.itemsTable}</ItemModal>}
          </QuantityContainer>
          {totalData > 25 && (
            <PaginationContainer>
              <span>{translate.pages}: </span>
              <img src={IconArrow} className="arrow left" alt="decrease" onClick={() => handlePages('decrease')} />
              <div className="pagination">
                {numberFormat(Number(currentPage))} {translate.of} {numberFormat(Number(totalPage))}
              </div>
              <img src={IconArrow} className="arrow right" alt="increase" onClick={() => handlePages('increase')} />
            </PaginationContainer>
          )}
        </OptionsContainer>
        )}
        {isLoadingData && <Loader loading={isLoadingData} />}
        {(!isLoadingData && b2bData.length === 0) && (
        <NoDataMessage>
          {translate.msgNoData}
        </NoDataMessage>
        )}
        {(!isLoadingData && b2bData.length > 0) && (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <td>URL</td>
                <td>{translate.role}</td>
                <td>{translate.company}</td>
                <td>{translate.domain}</td>
                <td>{translate.city}</td>
              </tr>
            </thead>
            {b2bData.length > 0 && (
            <tbody>
              {b2bData?.map((data) => (
                <ItemUser data={data} key={data.url} />
              ))}
            </tbody>
            )}
          </Table>
        </TableContainer>
        )}
      </Container>
    </>
  );
}
