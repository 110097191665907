export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    titleExact: language === 'pt-BR' ? 'Filtro positivo' : 'Positive filter',
    positiveExact: language === 'pt-BR' ? 'Exato' : 'Exact',
    positiveContains: language === 'pt-BR' ? 'Contém' : 'Contains',
    titleNegative: language === 'pt-BR' ? 'Filtro negativo' : 'Negative filter',
    negativeExact: language === 'pt-BR' ? 'Exato' : 'Exact',
    negativeContains: language === 'pt-BR' ? 'Contém' : 'Contains',
    selectedItems: language === 'pt-BR' ? 'Itens selecionados' : 'Selected items',
    clear: language === 'pt-BR' ? 'Limpar tudo' : 'Clear all',
    placeholder: language === 'pt-BR' ? 'Enter para adicionar' : 'Press Enter to add',
    language,
  };
}
