import {
  useEffect, createContext, useMemo, useState, useContext,
} from 'react';
import toast from '../../../helpers/toast';
import B2BDatabaseService from '../../../services/B2BDatabase';
import { B2BDataBaseFilterContext } from './B2BDatabaseFilter.context';

const B2BDataBaseDataContext = createContext();

function B2BDataBaseDataProvider({ children }) {
  const { filterApplied, handleClickApply } = useContext(B2BDataBaseFilterContext);
  const [b2bData, setB2bData] = useState([]);
  const [totalData, settotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [currentPage]);

  useEffect(() => {
    if (handleClickApply) {
      setCurrentPage(1);
      getData();
    }
  }, [handleClickApply]);

  async function getData() {
    try {
      setIsLoadingData(true);
      const response = await B2BDatabaseService.findAllUserView({ filters: filterApplied, pagination: currentPage });
      setB2bData(response.data);
      settotalData(response.total);
      setTotalPage(Number(Math.ceil((response.total / 25))));
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoadingData(false);
    }
  }

  function handlePages(event) {
    if (event !== 'increase' && event !== 'decrease') return;
    if (Number(totalPage.toFixed(0)) === 0) return;
    if (event === 'increase') {
      if (Number(currentPage) === Number(totalPage.toFixed(0))) return;
      setCurrentPage((prevState) => prevState + 1);
    }
    if (event === 'decrease') {
      if (Number(currentPage) === 1) return;
      setCurrentPage((prevState) => prevState - 1);
    }
  }

  const contextValues = useMemo(() => ({
    b2bData,
    isLoadingData,
    totalData,
    totalPage,
    currentPage,
    handlePages,
  }), [
    b2bData,
    isLoadingData,
    totalData,
    totalPage,
    currentPage,
    handlePages,
  ]);

  return (
    <B2BDataBaseDataContext.Provider value={contextValues}>
      {children}
    </B2BDataBaseDataContext.Provider>
  );
}

export { B2BDataBaseDataContext, B2BDataBaseDataProvider };
