import React, { memo } from 'react';
import { ProgressBar } from '../../../../../components/ProgressBar';

export const LoaderContainerMemo = memo(({ count, message }) => (
  <div style={{
    textAlign: 'center',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  }}
  >
    <ProgressBar
      value={count}
      color="blue"
      showPercentage={false}
    />
    <span>{message}</span>
  </div>
));

