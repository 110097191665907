/* eslint-disable array-callback-return */
import { B2BDataBaseFilterProvider } from './B2BDatabaseFilter.context';
import { B2BDataBaseDataProvider } from './B2BDatabaseData.context';
import { B2BDatabases } from './B2BDatabases';

export function B2BDatabasesIndex() {
  return (
    <B2BDataBaseFilterProvider>
      <B2BDataBaseDataProvider>
        <B2BDatabases />
      </B2BDataBaseDataProvider>
    </B2BDataBaseFilterProvider>
  );
}
