import styled, { css } from 'styled-components';

export const ContainerBlur = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(42, 42, 42, 0.6);
  backdrop-filter: blur(7px);
  z-index: 1000;
`;

export const ContainerInfo = styled.div`
  width: 800px;
  background-color: ${(props) => props.theme.grayMenu};
  border: 3px solid ${(props) => props.theme.grayBackground};
  border-radius: ${(props) => props.theme.radius};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${(props) => props.theme.sizes.s32};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
     width: 90%;
     max-height: 90%;
  }
`;

export const ContainerInfos = styled.div`
    .title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;
    }

    img {
      width: 15px;
      cursor: pointer;
      transition: .2s ease-in-out
    }

    img:hover {
      filter: invert(94%) sepia(100%) saturate(727%) hue-rotate(143deg) brightness(510%) contrast(84%);
    }

    .children-container {
      width: 100%;
      word-wrap: break-word;
      margin-bottom: 32px;
    }
`;

export const ContainerBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: ${(props) => props.theme.sizes.s8};

  .btn-tertiary:hover {
    background-color: ${(props) => props.theme.grayMenu} !important;
  }

  ${({ color }) => color?.includes('#') && css`
    background-color: ${({ color }) => color} !important;
  `}

  button {
    padding: ${(props) => props.theme.sizes.s8} ${(props) => props.theme.sizes.s32};
    word-wrap: none;
    transition: .3s ease;
    border: 1px solid transparent;
    border-radius: ${(props) => props.theme.radius};
    font-weight: 400;
    font-size: ${(props) => props.theme.sizes.s16};
    cursor: pointer;
  }

  button[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.grayLight};
  }

  button[disabled]:hover {
    color: ${(props) => props.theme.grayMenu};
    border-color: transparent;
  }
`;
