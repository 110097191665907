export default function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    welcomeText: language === 'pt-BR' ? 'Olá' : 'Welcome',
    titleFeed: language === 'pt-BR' ? 'Feed diário' : 'Daily feed',
    btnWatch: language === 'pt-BR' ? 'Assistir' : 'Watch',
    btnLinkToFeed: language === 'pt-BR' ? 'Clique aqui e acesse seu feed exclusivo' : 'Click here and access your exclusive feed',
    msgTwoMorevideos: language === 'pt-BR' ? '...mais 3 vídeos selecionados para você!' : '...3 more videos selected for you!',
    msgRestrictedAccess: language === 'pt-BR' ? 'Acesso restrito' : 'Restricted access',
    msgAdmContact: language === 'pt-BR' ? 'Entre em contato com o administrador...' : 'Contact the administrator...',
    language,
  };
}
