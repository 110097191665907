import {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ProductsService from '../../../../../services/Products';
import CategoryProductService from '../../../../../services/CategoryProduct';
import SubCategoryProductService from '../../../../../services/SubCategoryProduct';
import VideosService from '../../../../../services/Videos';
import PostsProductService from '../../../../../services/PostsProduct';
import toast from '../../../../../helpers/toast';

export function useVideoEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [video, setVideo] = useState({
    titlePt: '',
    titleEn: '',
    typeVideo: '',
    linkVideo: '',
    descriptionPt: '',
    descriptionEn: '',
    transcriptPt: '',
    transcriptEn: '',
    allowTranscript: '',
    relationType: '',
    productId: undefined,
    postId: undefined,
    subCategoryId: undefined,
    categoryId: undefined,
  });
  const [files, setFiles] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [postsList, setPostsList] = useState([]);
  const [productSelected, setProductSelected] = useState('');
  const [categorySelected, setCategorySelected] = useState('');
  const [subCategorySelected, setSubCategorySelected] = useState('');
  const [postSelected, setPostSelected] = useState('');
  const [isSavingInfo, setIsSavingInfo] = useState(false);
  const [isDisableButtom, setIsDisabledButtom] = useState(true);
  const [assetsList, setAssetList] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [isLoadingInfo, setIsLoadingInfo] = useState(true);

  useEffect(() => {
    async function Init() {
      setIsLoadingInfo(true);
      await loadLists();
      await getVideoById();
      setIsLoadingInfo(false);
    }
    Init();
  }, []);

  useEffect(() => {
    allowSaveVideo();
  }, [video]);

  useEffect(() => {
    if (video.relationType !== '' && productSelected !== '') {
      handleProductOnChange();
    }
  }, [productSelected, video.relationType]);

  useEffect(() => {
    if (video.typeVideo === 'sem video') setVideo({ ...video, linkVideo: '' });
  }, [video.typeVideo]);

  async function getVideoById() {
    try {
      const video = await VideosService.findById(id);
      setVideo((prevState) => ({
        ...prevState,
        titlePt: video.title_pt,
        titleEn: video.title_en,
        typeVideo: video.type_video,
        linkVideo: video.link_video,
        descriptionPt: video.description_pt,
        descriptionEn: video.description_en,
        transcriptPt: video.transcript_pt,
        transcriptEn: video.transcript_en,
        allowTranscript: video.allow_transcript,
        relationType: video.relation_type,
        productId: video.id_product,
        postId: video.relation_type === 'post' ? video.post_id : undefined,
        subCategoryId: video.relation_type === 'subcategory' ? video.subcategory_id : undefined,
        categoryId: video.relation_type === 'category' ? video.category_id : undefined,
      }));
      setProductSelected(video.id_product);
      setAssetList(video.assets);
      if (video.relation_type === 'category') {
        setCategorySelected(video.category_id);
      } else if (video.relation_type === 'subcategory') {
        setSubCategorySelected(video.subcategory_id);
      } else if (video.relation_type === 'post') {
        setPostSelected(video.post_id);
      }
    } catch {}
  }

  async function saveVideo() {
    setIsSavingInfo(true);
    try {
      await VideosService.update(id, video, filesToDelete, files);
      toast('success', 'Video edited successfully');
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsSavingInfo(false);
      navigate(-1);
    }
  }

  async function loadLists() {
    const productList = await ProductsService.findAll({ firstVideo: 'no' });
    if (productList.length > 0) {
      setProductsList(productList);
      const categoryList = await CategoryProductService.findAll(productList[0].id);
      if (categoryList.length > 0) {
        const categoryListFilteredBySubCategoryEqualsToNo = categoryList.filter((category) => category.hasSubCategory === 'N');
        if (categoryListFilteredBySubCategoryEqualsToNo.length > 0) {
          setCategoriesList(categoryListFilteredBySubCategoryEqualsToNo);
          setCategorySelected(categoryListFilteredBySubCategoryEqualsToNo[0].id);
        } else {
          setCategoriesList([]);
          setCategorySelected('');
        }
      } else {
        setCategoriesList([]);
        setCategorySelected('');
      }
    } else {
      setProductsList([]);
      setProductSelected('');
    }
  }

  async function handleProductOnChange() {
    if (video.relationType === 'category') {
      const categoryList = await CategoryProductService.findAll(productSelected);
      if (categoryList.length > 0) {
        const categoryListFilteredBySubCategoryEqualsToNo = categoryList.filter((category) => category.hasSubCategory === 'N');
        if (categoryListFilteredBySubCategoryEqualsToNo.length > 0) {
          setCategoriesList(categoryListFilteredBySubCategoryEqualsToNo);
          const category = video.categoryId || categoryListFilteredBySubCategoryEqualsToNo[0].id;
          setCategorySelected(category);
          setVideo((prevState) => ({
            ...prevState, categoryId: category, subCategoryId: undefined, postId: undefined,
          }));
        } else {
          setCategoriesList([]);
          setCategorySelected(undefined);
          setVideo((prevState) => ({ ...prevState, categoryId: undefined }));
        }
      } else {
        setCategoriesList([]);
        setCategorySelected(undefined);
        setVideo((prevState) => ({ ...prevState, categoryId: undefined }));
      }
    }

    if (video.relationType === 'subcategory') {
      const subCategoryList = await SubCategoryProductService.findAll(productSelected, 'product');
      if (subCategoryList.length > 0) {
        const subCategoryListFilteredByHasPostsEqualsToNo = subCategoryList.filter((subCategory) => subCategory.hasPosts === 'N');
        if (subCategoryListFilteredByHasPostsEqualsToNo.length > 0) {
          setSubCategoriesList(subCategoryListFilteredByHasPostsEqualsToNo);
          const subCategory = video.subCategoryId || subCategoryListFilteredByHasPostsEqualsToNo[0].id;
          setSubCategorySelected(subCategory);
          setVideo((prevState) => ({
            ...prevState, categoryId: undefined, subCategoryId: subCategory, postId: undefined,
          }));
        } else {
          setSubCategoriesList([]);
          setSubCategorySelected(undefined);
          setVideo((prevState) => ({ ...prevState, subCategoryId: undefined }));
        }
      } else {
        setSubCategoriesList([]);
        setSubCategorySelected(undefined);
        setVideo((prevState) => ({ ...prevState, subCategoryId: undefined }));
      }
    }

    if (video.relationType === 'post') {
      const postList = await PostsProductService.findAll(productSelected, 'product');
      if (postList.length > 0) {
        setPostsList(postList);
        const post = video.postId || postList[0].id;
        setPostSelected(post);
        setVideo((prevState) => ({
          ...prevState, categoryId: undefined, subCategoryId: undefined, postId: post,
        }));
      } else {
        setPostsList([]);
        setPostSelected(undefined);
        setVideo((prevState) => ({ ...prevState, postId: undefined }));
      }
    }
  }

  function handleAddToInputOnChange(relationType) {
    setVideo((prevState) => ({ ...prevState, relationType }));
  }

  function handleFiles(files) {
    const arrayofFiles = Array.from(files);
    setFiles(arrayofFiles);
  }

  function allowSaveVideo() {
    const titlePtIsValid = video.titlePt.length >= 3;
    const titleEnIsValid = video.titleEn.length >= 3;

    if (titlePtIsValid && titleEnIsValid && productSelected) {
      setIsDisabledButtom(false);
    } else {
      setIsDisabledButtom(true);
    }
  }

  function handleOptionsSelectedOnChange(type, value) {
    const idReffered = Number(value);
    if (type === 'category') {
      setVideo((prevState) => ({ ...prevState, categoryId: idReffered }));
      setCategorySelected(idReffered);
    }

    if (type === 'subcategory') {
      setVideo((prevState) => ({ ...prevState, subCategoryId: idReffered }));
      setSubCategorySelected(idReffered);
    }

    if (type === 'post') {
      setVideo((prevState) => ({ ...prevState, postId: idReffered }));
      setPostSelected(idReffered);
    }
  }

  function handleDescriptionPt(content) {
    setVideo((prevState) => ({ ...prevState, descriptionPt: content }));
  }

  function handleDescriptionEn(content) {
    setVideo((prevState) => ({ ...prevState, descriptionEn: content }));
  }

  function addFilesToDelete(asset) {
    const alreadyChoosen = filesToDelete.find((file) => file.original_name === asset.original_name);
    if (alreadyChoosen) return;
    const newArray = assetsList.filter((file) => file.original_name !== asset.original_name);
    setAssetList(newArray);
    setFilesToDelete((prevState) => [...prevState, asset]);
  }

  return {
    video,
    setVideo,
    productsList,
    categoriesList,
    subCategoriesList,
    postsList,
    productSelected,
    setProductSelected,
    categorySelected,
    subCategorySelected,
    postSelected,
    handleProductOnChange,
    handleAddToInputOnChange,
    handleOptionsSelectedOnChange,
    handleDescriptionPt,
    handleDescriptionEn,
    handleFiles,
    saveVideo,
    isSavingInfo,
    isDisableButtom,
    assetsList,
    addFilesToDelete,
    isLoadingInfo,
  };
}
