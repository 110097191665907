import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProductsService from '../../../../../services/Products';
import TitlePage from '../../../../../components/TitlePage';
import Square from '../../../../../components/Square';
import Loader from '../../../../../components/Loader';
import { Badge } from '../../../../../components/Badge';
import toast from '../../../../../helpers/toast';
import { ButtonLink } from '../../../../../components/ButtonLink';
import IconListOrder from '../../../../../styles/logos/list_order.svg';
import IconEdit from '../../../../../styles/logos/FaEdit.svg';
import IconTrash from '../../../../../styles/logos/trash-alt.svg';
import { Container, TitleContainer } from './style';
import { Translate } from './translate';

function Products() {
  const translate = Translate();
  const [productsList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  async function getProducts() {
    try {
      setProductList([]);
      setIsLoading(true);
      const products = await ProductsService.findAll({ firstVideo: 'no' });
      setProductList(products);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteProduct(id) {
    try {
      const response = await ProductsService.delete(id);
      setProductList(productsList.filter((product) => product.id !== id));
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    }
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <ButtonLink color="blue" to="/admin/products/add">{translate.addButton}</ButtonLink>
      </TitlePage>

      <Container>
        {(!isLoading && (!productsList || productsList.length === 0)) ? (<p>{translate.msg}</p>) : <Loader loading={isLoading} /> }
        {productsList
          && (productsList.map((product) => (
            <Square size="large" key={product.id}>
              <TitleContainer $color={product.colorName}>
                <div className="c-products">
                  <div className="c-products-name">
                    {product.namePt} | {product.nameEn}
                  </div>
                  <div>
                    <Badge>{product.type}</Badge>
                  </div>
                  <div>
                    <Badge color={product.active === 'Y' ? 'green' : 'red'}>
                      {translate.textActive}
                      {product.active === 'Y' ? translate.textYes : translate.textNo}
                    </Badge>
                  </div>
                </div>
                <div className="c-products-action">
                  <input
                    type="text"
                    value={product.ordering}
                    disabled
                  />
                  <Link to={`/admin/categories/by-product/${product.id}?name=${translate.language === 'pt-BR' ? product.namePt : product.nameEn}`} title="List Categories">
                    <img src={IconListOrder} alt="List Categories" />
                  </Link>
                  <Link to={`/admin/products/${product.id}`} title="Edit Product">
                    <img src={IconEdit} alt="Edit Product" />
                  </Link>
                  <span className="icon-trash" onClick={() => deleteProduct(product.id)} title="Delete Product">
                    <img src={IconTrash} alt="Delete Product" />
                  </span>
                </div>
              </TitleContainer>
            </Square>
          ))
          )}
      </Container>
    </>
  );
}

export default Products;
