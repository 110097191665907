import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  margin: 0;
  position: relative;
`;

export const SideBarArea = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 25%; // Usando width fixo ao invés de flex
  margin-left: -20px;
  margin-top: -20px;
`;

export const RoutesArea = styled.div`
  width: 75%; // Usando width fixo ao invés de flex
  padding-left: 20px;
  min-height: 100vh;
`;



