export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Conteúdos selecionados para você...' : 'Content selected for you...',
    like: language === 'pt-BR' ? 'curtida' : 'like',
    likes: language === 'pt-BR' ? 'curtidas' : 'likes',
    messageWholeContent: language === 'pt-BR' ? 'Conteúdo completo' : 'Full content',
    language,
  };
}
