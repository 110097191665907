import React from 'react';
import { Link, require } from 'react-router-dom';
import { Badge } from '../../Badge';
import IconDownload from '../../../styles/logos/icon_download.svg';
import IconOpen from '../../../styles/logos/icon_open.svg';
import { Translate } from './translate';
import { LinksContainer, ListItem } from './style';

export function Files({ files }) {
  const translate = Translate();

  return (
    <>
      {files.length > 0 && (
        <LinksContainer>
          <h3>{translate.title}</h3>
          {files.map((file) => (
            <ListItem key={file.url}>
              <p>{file.file_name}</p>
              <Badge>
                <Link to={file.url} target="__blank">
                  <img src={IconOpen} alt="Open" />
                  <span>{translate.openMsg}</span>
                </Link>
              </Badge>
              {/*
                //TODO: Arrumar a questão do download do arquivo
              */}
              {/* <Badge>
                <a href={asset.url} download={asset.file_name} target="_blank" rel="noopener noreferrer">
                  <img src={IconDownload} alt="Dowload" />
                  <span>Download</span>
                </a>
              </Badge> */}
            </ListItem>
          ))}
        </LinksContainer>
      )}
    </>
  );
}
