import { VideoAdd } from './VideoAdd';
import { VideoProvider } from './VideoContext';

export function VideoAddIndex() {
  return (
    <VideoProvider>
      <VideoAdd />
    </VideoProvider>
  );
}
