import { styled } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TitleContainer = styled.div`
  margin: 0;
  margin-bottom: 16px;

  h4 {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const ContainerBody = styled.div`
  position: relative;
  height: 87%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .no-access-container {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  .container-video {
    overflow-y: auto;

    > span {
      font-size: 12px;
      color: ${({ theme }) => theme.grayLight}
    }
  }

  .container-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px;
    background-color: ${({ theme }) => theme.grayBackground};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    color: ${({ theme }) => theme.yellow};
    transition: .2s ease-in-out;
  }

  .container-button:hover {
    background-color: ${({ theme }) => theme.yellow};
    color: ${({ theme }) => theme.grayBackground};
  }
`;

export const VideoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackground};
  padding: 16px 0;

  > span {
    font-size: 16px;
    flex: 1;
  }

  > a {
    padding: 0px 8px 0px 0px;
    border: none;
    text-align: right !important;
    align-content: flex-end !important;
    flex: 1;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      display: initial;
    }
  }

  > a:hover {
    color: ${({ theme }) => theme.yellow};
    border: none;
  }
`;
