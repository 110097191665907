import React from 'react';
import { SideBarVideosProvider } from '../../../context/SideBarVideosContext';
import { Video } from '../../../components/Video';
import { MenuSideBarVideos } from '../../../components/MenuSideBarVideos';
import { Container } from './style';

export function Academy() {
  return (
  // <SideBarVideosProvider>
    <Container>
      <MenuSideBarVideos />
      <Video />
    </Container>
  // </SideBarVideosProvider>
  );
}
