import React from 'react';
import { ButtonLink } from '../../components/ButtonLink';

import IconArrow from '../../styles/logos/icon_arrow_2.svg';
import { ContainerPage, MainMessage } from './style';

function Trial() {
  return (
    <ContainerPage>
      <MainMessage>
        <div className="container-title">
          <h1>Seu período de teste terminou!</h1>
          <h3>Não perca mais nenhum benefício — renove agora e continue desfrutando de todas as vantagens exclusivas!</h3>
        </div>
        <div className="container-offer">
          <p>Ao renovar, você mantém acesso a:</p>
          <ul>
            <li>Academy <img alt="arrow" src={IconArrow} /> Crescimento Cruzado</li>
            <li>Academy <img alt="arrow" src={IconArrow} /> Pipe Performance</li>
            <li>Data Services <img alt="arrow" src={IconArrow} /> URL Shifter</li>
            <li>Data Services <img alt="arrow" src={IconArrow} /> B2B Database</li>
            <li>e muito mais...</li>
          </ul>
        </div>
        <div className="container-cta">
          <ButtonLink color="yellow">RENOVAR AGORA</ButtonLink>
          <ButtonLink color="white">FALAR COM UM DE NOSSOS ATENDENTES</ButtonLink>
        </div>
      </MainMessage>
    </ContainerPage>
  );
}

export default Trial;
