import React from 'react';

import { Link } from 'react-router-dom';

import Dates_New from '../../../../../../helpers/Dates_New';

import IconLink from '../../../../../../styles/logos/icon_link.svg';

export function ItemUser({ data }) {
  return (
    <tr>
      <td>{data.emailFirst}</td>
      <td>{data.emailSecond}</td>
      <td>{data.nameFirst}</td>
      <td>{data.nameMiddle}</td>
      <td>{data.nameLast}</td>
      <td>{data.country}</td>
      <td>{data.state}</td>
      <td>{data.city}</td>
      <td>
        <Link to={data.url} target="__blank" className="link-container">
          <img src={IconLink} alt="LinkedIn" />
          {data.url}
        </Link>
      </td>
      <td>{data.role}</td>
      <td>{data.sector}</td>
      <td>{data.company}</td>
      <td>{data.companyDomain}</td>
      <td>{data.technology}</td>
      <td>{data.employeesQuantity}</td>
      <td>{data.annualRevenue}</td>
      <td>{data.headquartersRegion}</td>
      <td>{data.linkedinID}</td>
      <td>{data.listID}</td>
      <td>{data.listName}</td>
      <td>{Dates_New.formatDatesMask(data.createdAt)}</td>
      <td>{Dates_New.formatDatesMaskWithHour(data.registerAt)}</td>
    </tr>
  );
}
