import styled from 'styled-components';

export const Message = styled.p`
  margin-bottom: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap:8px;
`;

export const SquareHeaderContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;

  span {
    background-color: ${({ theme }) => theme.gray1};
    padding: 8px;
    border-radius: 4px;
  }
`;

export const SquareFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  pre {
    color: white;
    background-color: ${({ theme }) => theme.graySubMenu};
    border-radius: 4px;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.gray1}
  }
`;
