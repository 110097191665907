import React, { useEffect, useState } from 'react';

import VideosRatingService from '../../../../services/VideosRating';

import TitlePage from '../../../../components/TitlePage';
import Loader from '../../../../components/Loader';
import { RatingItem } from '../../../../components/RatingItem';
import { NoDataMessage } from '../../../../components/NoDataMessage';
import toast from '../../../../helpers/toast';

import { Translate } from './translate';
import { Container } from './style';

export function Ratings() {
  const translate = Translate();
  const [ratings, setRatings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setIsLoading(true);
    try {
      const ratingsDB = await VideosRatingService.findAll();
      setRatings(ratingsDB);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <TitlePage title="Ratings" />
      {isLoading && <Loader loading={isLoading} />}
      {(!isLoading && ratings.length === 0) && (
        <NoDataMessage>
          {translate.message}
        </NoDataMessage>
      )}
      {(!isLoading && ratings.length > 0) && (
        <Container>
          {ratings.map((rating) => (
            <RatingItem key={rating.id} rating={rating} />
          ))}
        </Container>
      )}
    </>
  );
}
