import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  div {
    margin-bottom: 8px;
  }
`;

export const HeaderSquare = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow-x: hidden;
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.grayLight};
`;

export const DescriptionContainer = styled.span`
  width: 100%;
  /* overflow-x: auto; */

  pre {
    word-wrap: break-word;
    word-break: break-all;
    overflow-x: auto;

  }
`;
