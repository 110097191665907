import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import CategoryService from '../../../../../services/CategoryProduct';
import CategoryUsersService from '../../../../../services/CategoryUsers';
import UsersService from '../../../../../services/Users';

import TitlePage from '../../../../../components/TitlePage';
import BackButton from '../../../../../components/BackButton';
import ButtonsSaveCancel from '../../../../../components/ButtonsSaveCancel';
import Square from '../../../../../components/Square';
import { Button } from '../../../../../components/Button';
import Loader from '../../../../../components/Loader';

import toast from '../../../../../helpers/toast';

import { Translate } from './translate';
import {
  Container, FormContainer, InputContainer,
} from './style';

export function CategoryUsers() {
  const translate = Translate();
  const { id } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState({ namePt: '', nameEn: '' });
  const [users, setUsers] = useState([{
    id: '', firstName: '', lastName: '', email: '', clientName: '',
  }]);
  const [searchInput, setSearchInput] = useState('');
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function initial() {
      await getCategoryInfos();
      await getAllUsers();
    }

    initial();
  }, []);

  async function getCategoryInfos() {
    if (id) {
      const category = await CategoryService.findById(id);
      setCategory(category);
    }
  }

  async function getAllUsers() {
    try {
      setIsLoading(true);
      const usersDB = await UsersService.getAll();
      const usersList = usersDB
        .map((user) => ({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          clientName: user.clientName,
          selected: false,
        }))
        .sort((a, b) => {
          if (a.email < b.email) return -1;
          if (a.email > b.email) return 1;
          return 0;
        });

      const registeredUsersOnCategory = await CategoryUsersService.findById({ idCategory: id });
      const registeredUsersOnCategoryIds = registeredUsersOnCategory.map((users) => users.idUser);

      const users = usersList.map((user) => ({
        ...user,
        selected: !!registeredUsersOnCategoryIds.includes(user.id),
      }));

      setUsers(users);
    } catch {

    } finally {
      setIsLoading(false);
    }
  }

  async function handleSave() {
    setbtnDisabled(true);
    const usersToAdd = users.filter((user) => user.selected === true);
    try {
      const response = await CategoryUsersService.storeAndUpdate({ idCategory: id, listOfUsers: usersToAdd });
      toast('success', response.message);
    } catch (error) {
      toast('success', error.message);
    } finally {
      setbtnDisabled(false);
      navigate(-1);
    }
  }

  function selectUserToAdd(event) {
    const idUser = event.target.value;
    const userEdited = users.map((user) => {
      if (user.id === idUser) {
        return { ...user, selected: !user.selected };
      }
      return { ...user };
    });
    setUsers(userEdited);
  }

  function handleSearchInput(event) {
    setSearchInput(event.target.value.toLowerCase());
  }

  function handleClearSearchInput() {
    setSearchInput('');
  }

  const usersFiltered = useMemo(() => users?.filter((user) => user.firstName?.toLowerCase().includes(searchInput)
                                                           || user.lastName?.toLowerCase().includes(searchInput)
                                                           || user.email?.toLowerCase().includes(searchInput)
                                                           || user.clientName?.toLowerCase().includes(searchInput)));

  return (
    <>
      <TitlePage
        title={`${translate.title} (${translate.language === 'pt-BR' ? category.namePt : category.nameEn})`}
      >
        <BackButton to="/admin/categories" />
      </TitlePage>
      {isLoading && <Loader loading={isLoading} />}
      {!isLoading && (
        <Container>
          <Square size="large">
            <FormContainer>
              <InputContainer>
                <label htmlFor="search">{translate.search}</label>
                <input
                  type="text"
                  name="search"
                  placeholder={translate.placeholder}
                  value={searchInput}
                  onChange={handleSearchInput}
                />
                <Button color="blue" onClick={handleClearSearchInput}>{translate.clear}</Button>
              </InputContainer>
              <div className="c-multiselect-container">
                <div className="c-multiselect-container-fields">
                  <div className="c-multiselect-container-fields-options">
                    <label htmlFor="users">Users</label>
                    <select multiple="multiple" onClick={selectUserToAdd} id="users">
                      {users.length > 0 && usersFiltered.filter((user) => user.selected === false).map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.email} | {user.firstName} {user.lastName} {user.clientName ? `(${user.clientName})` : ''}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="c-multiselect-container-fields-options c-color">
                    <label htmlFor="users-to-add">Users allowed</label>
                    <select multiple="multiple" onClick={selectUserToAdd} id="users-to-add">
                      {users.length > 0 && usersFiltered.filter((user) => user.selected === true).map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.email} | {user.firstName} {user.lastName} {user.clientName ? `(${user.clientName})` : ''}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </FormContainer>
          </Square>
          <ButtonsSaveCancel onSave={handleSave} disabled={btnDisabled} />
        </Container>
      )}
    </>
  );
}
