import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4px;

    .icons-container {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .icons-container img {
      width: 18px;
    }

    .icons-container img:hover {
      cursor: pointer;
      filter: invert(67%) sepia(99%) saturate(433%) hue-rotate(360deg) brightness(190%) contrast(109%);
    }
  }

  .multiselect-btn {
      width: 100%;
      height: 100%;
      max-width: 200px;
      border: 1px solid ${({ theme }) => theme.grayLight};
      border-radius: 4px;
      text-align: left;
      cursor: pointer;
      background-color: transparent;
      font-family: inherit;
      font-size: 16px;
      padding: 8px 10px;

      /* Adicionando essas propriedades */
      white-space: nowrap;     /* Impede a quebra de linha */
      overflow: hidden;        /* Oculta o conteúdo que não cabe */
      text-overflow: ellipsis;
  }
`;

export const ListContainer = styled.div`
  position: absolute;
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.grayLight};
  background-color: ${({ theme }) => theme.grayMenu};
  min-width: 120px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;

  span {
    padding: 8px;
    font-size: 13px;
  }

  span:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.gray2};
  }

  .search-container {
    position: relative;

    input {
      border-radius: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.grayLight}
    }

    input:focus {
      border-bottom: 1px solid ${({ theme }) => theme.grayLight}
    }

    img {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      width: 16px;

      &:hover {
        cursor: pointer;
        filter: invert(67%) sepia(99%) saturate(433%) hue-rotate(360deg) brightness(190%) contrast(109%);
      }
    }
  }

  span img {
    width: 35px !important;
    margin: 0px !important;
  }
`;
