/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Rating } from 'react-simple-star-rating';
import { Link } from 'react-router-dom';
import { ProgressBar } from '../../../ProgressBar';
import { ContainerScore, ContainerProgressBars, ContainerLink } from './style';
import { Translate } from './translate';

export function RatingInfo({
  idVideo, value, colorForStar, color, amountOfRatings, amountOfRatingsByStars,
}) {
  const translate = Translate();

  return (
    <div>
      <ContainerScore>
        <Rating
          initialValue={value}
          readonly
          transition
          size={25}
          titleSeparator=""
          allowFraction
          fillClassName="filled-icons"
          fillColor={colorForStar}
        />
        <h3>{value} {translate.language === 'pt-BR' ? 'de' : 'of'} 5</h3>
      </ContainerScore>
      <div>{amountOfRatings} {amountOfRatings === 1 ? translate.rating : translate.ratings}</div>
      <ContainerProgressBars>
        {amountOfRatingsByStars && (
          Object.entries(amountOfRatingsByStars).map((item, index) => (
            <Link to={`/client/rating-video/${idVideo}?s=${index + 1}`} className="container-link" key={index}>
              <div className="stars">{index + 1} {(index + 1) === 1 ? translate.star : translate.stars}</div>
              <ProgressBar value={item[1].share_percentage} color={color} />
              <div className="percentage">
                {`${item[1].share_percentage}%`}
              </div>
            </Link>
          )).reverse()
        )}
      </ContainerProgressBars>
      <ContainerLink>
        <Link to={`/client/rating-video/${idVideo}`}>
          {translate.reviews}
        </Link>
      </ContainerLink>
    </div>
  );
}
