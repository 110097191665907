/* eslint-disable react/no-array-index-key */
import React, {
  useState, useRef, useEffect, useContext,
} from 'react';

import { AuthContext } from '../../../../context/AuthContext';
import AIPromptServices from '../../../../services/AIPrompt';

import TitlePage from '../../../../components/TitlePage';
import { Button } from '../../../../components/Button';
import toast from '../../../../helpers/toast';

import IconClipboard from '../../../../styles/logos/clipboard.svg';
import IconCheck from '../../../../styles/logos/check_icon.svg';
import IconArrow from '../../../../styles/logos/arrow.svg';
import IconAdd from '../../../../styles/logos/add.svg';

import { nicheList } from './lists/nicheList';
import { avatarList } from './lists/avatarList';
import { LoaderContainerMemo } from './LoaderContainer';

import { Translate } from './translate';
import {
  Container, LoaderContainer, QuestionArea, QuestionContainer, ResponseContainer, Card,
} from './style';

export function AINewProject() {
  const translate = Translate();
  const { userInfo } = useContext(AuthContext);
  const [infos, setInfos] = useState({
    businessModel: 'B2B',
    infoSite: '',
    niche: '',
    avatar: '',
  });
  const [infosSecondStage, setInfosSecondStage] = useState({
    id: '',
    businessModel: '',
    infoSite: '',
    niche: '',
    avatar: '',
    prompts: {},
  });
  const [promptsResponse, setPromptsResponse] = useState({});

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingDataSecondStage, setIsLoadingDataSecondStage] = useState(false);

  const [showButtonSecondSearch, setShowButtonSecondSearch] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  const [countFirstStage, setCountFirstStage] = useState(0);
  const intervalFirstStageRef = useRef(null);
  const [countSecondStage, setCountSecondStage] = useState(0);
  const intervalSecondStageRef = useRef(null);

  const [optionsAvatar, setOptionsAvatar] = useState(avatarList(infos.businessModel, translate.language));
  const [optionsNiche, setOptionsNiche] = useState(nicheList(infos.businessModel, translate.language));

  const [visibleOptions, setVisibleOptions] = useState({
    avatar: false,
    niche: false,
  });

  const [visibleCards, setVisibleCards] = useState({
    foundationalCopy: false,
    foundationalCopyHumanized: false,
  });

  // CLEAR ALL EVENTS OF THE PAGE
  useEffect(() => () => {
    clearInterval(intervalFirstStageRef.current);
    clearInterval(intervalSecondStageRef.current);
  }, []);

  // MONITORS BTN TO ENABLED AND DISBLED
  useEffect(() => {
    toogleBtn();
  }, [infos]);

  // CHANGE AVATARS AND NICHE LISTS AS CHANGE BUSINESS MODEL USESATE AND CLEAR INFOS
  useEffect(() => {
    setOptionsAvatar(avatarList(infos.businessModel, translate.language));
    setOptionsNiche(nicheList(infos.businessModel, translate.language));
    setInfos((prev) => ({ ...prev, avatar: '', niche: '' }));
    setVisibleOptions({
      avatar: false,
      niche: false,
    });
  }, [infos.businessModel]);

  async function getDataFirstStage() {
    // Clear values
    setIsLoadingData(true);
    setPromptsResponse({});
    setCountFirstStage(0);

    // Init counter
    intervalFirstStageRef.current = setInterval(() => {
      setCountFirstStage((prevCount) => prevCount + 1);
    }, 1000);

    // Get datas
    try {
      const responsePromptsDb = await AIPromptServices.postPrompt({ infos });
      setPromptsResponse((prev) => ({
        ...prev,
        ...responsePromptsDb.prompts,
        id: responsePromptsDb.id,
      }));

      setInfosSecondStage({ ...infos, ...responsePromptsDb });
      setInfos({
        infoSite: '',
        niche: '',
        avatar: '',
      });

      // Enable second btns
      setShowButtonSecondSearch(true);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('first-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);

      // Clear values
      clearInterval(intervalFirstStageRef.current);
      setCountFirstStage(0);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoadingData(false);
    }
  }

  async function getDataSecondStage() {
    // Clear values
    setIsLoadingDataSecondStage(true);

    // Init counter
    intervalSecondStageRef.current = setInterval(() => {
      setCountSecondStage((prevCount) => prevCount + 1);
    }, 1000);

    // Get datas
    try {
      const promptResponseDb = await AIPromptServices.postPrompt2({
        idProject: infosSecondStage.id,
        initialInfos: {
          infoSite: infosSecondStage.infoSite,
          niche: infosSecondStage.niche,
          avatar: infosSecondStage.avatar,
        },
        promptsInfo: { ...infosSecondStage.prompts },
      });

      setPromptsResponse((prev) => ({
        ...prev,
        salesLetterSMB: promptResponseDb.prompts?.salesLetterSMB,
        salesLetterEnterprise: promptResponseDb.prompts?.salesLetterEnterprise,
        headlines: promptResponseDb.prompts?.headlines,
      }));

      // Disalble second btn
      setShowButtonSecondSearch(false);

      // Takes the user to top of results
      setTimeout(() => {
        const element = document.getElementById('second-stage-response');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth', // Para uma rolagem suave
            block: 'start', // Alinha o elemento ao topo da viewport
          });
        }
      }, 100);

      // Clear values
      clearInterval(intervalSecondStageRef.current);
      setCountSecondStage(0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDataSecondStage(false);
    }
  }

  function copyContent(event) {
    const element = event.target;
    const infoToCopy = element.parentElement.nextElementSibling.textContent;
    navigator.clipboard.writeText(infoToCopy);
    element.setAttribute('src', IconCheck);

    setTimeout(() => {
      element.setAttribute('src', IconClipboard);
    }, 2000);
  }

  function toogleBtn() {
    const fieldsFilled = infos.infoSite.length > 0 && infos.niche.length > 0 && infos.avatar.length > 0;
    if (fieldsFilled) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }

  function editFormValues(event) {
    setInfos((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }

  function handleOptionsArea(containerId) {
    setVisibleOptions((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  }

  function handleCardsFoundational(containerId, e) {
    if (event.target.tagName === 'IMG') return;
    setVisibleCards((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  }

  function toogleSelectedOptions(event, type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      const checked = event.target.checked;
      const value = event.target.value;

      if (checked) {
        // ADD INTO STRING
        const stringTurnedIntoArray = infos[key].length === 0 ? [] : infos[key].split(', ');
        if (stringTurnedIntoArray?.includes(value)) return;
        stringTurnedIntoArray.push(value);
        setInfos((prev) => ({ ...prev, [key]: stringTurnedIntoArray.join(', ') }));
      } else {
        // REMOVE FROM STRING
        const stringTurnedIntoArray = infos[key].length === 0 ? [] : infos[key].split(', ');
        const filteredArray = stringTurnedIntoArray.filter((item) => item !== value);
        setInfos((prev) => ({ ...prev, [key]: filteredArray.join(', ') }));
      }
    }
  }

  function addAllOptions(type) {
    if (type === 'avatar' || type === 'niche') {
      const key = type;
      const arrOfOptions = type === 'avatar' ? optionsAvatar : optionsNiche;
      setInfos((prev) => ({ ...prev, [key]: arrOfOptions.join(', ') }));
    }
  }

  return (
    <>
      <TitlePage title={translate.title} />
      <Container>
        <QuestionArea>
          <QuestionContainer>
            <label htmlFor="business-model">{translate.questionModel}</label>
            <div className="radio-area">
              <label>
                <input
                  type="radio"
                  name="businessModel"
                  value="B2B"
                  onChange={(e) => editFormValues(e)}
                  defaultChecked
                />
                B2B
              </label>
              <label>
                <input
                  type="radio"
                  name="businessModel"
                  value="B2C"
                  onChange={(e) => editFormValues(e)}
                />
                B2C
              </label>
            </div>
          </QuestionContainer>
          <QuestionContainer $open={visibleOptions.avatar}>
            <label htmlFor="avatar">{translate.questionAvatar}</label>
            <textarea
              name="avatar"
              value={infos.avatar}
              onChange={(e) => editFormValues(e)}
              placeholder={translate.placeholderAvatar}
            />
            <div className="btns-area">
              <div
                onClick={() => handleOptionsArea('avatar')}
                className="btns-action open-modal"
              >
                Explorar sugestões
                <img src={IconArrow} alt="Arrow" />
              </div>
              <div
                onClick={() => addAllOptions('avatar')}
                className="btns-action"
              >
                Adicionar todos
                <img src={IconAdd} alt="Add" />
              </div>
            </div>
            <div className="options-area">
              {optionsAvatar.map((item) => (
                <label key={item}>
                  <input
                    type="checkbox"
                    name={item}
                    value={item}
                    onChange={(e) => toogleSelectedOptions(e, 'avatar')}
                  /> {item}
                </label>
              ))}
            </div>
          </QuestionContainer>
          <QuestionContainer $open={visibleOptions.niche}>
            <label htmlFor="niche">{translate.questionNiche}</label>
            <textarea
              name="niche"
              value={infos.niche}
              onChange={(e) => editFormValues(e)}
              placeholder={translate.placeholderNiche}
            />
            <div className="btns-area">
              <div
                onClick={() => handleOptionsArea('niche')}
                className="btns-action open-modal"
              >
                Explorar sugestões
                <img src={IconArrow} alt="Arrow" />
              </div>
              <div
                onClick={() => addAllOptions('niche')}
                className="btns-action"
              >
                Adicionar todos
                <img src={IconAdd} alt="Add" />
              </div>
            </div>
            <div className="options-area">
              {optionsNiche.map((item) => (
                <label key={item}>
                  <input
                    type="checkbox"
                    name={item}
                    value={item}
                    onChange={(e) => toogleSelectedOptions(e, 'niche')}
                  />
                  {item}
                </label>
              ))}
            </div>
          </QuestionContainer>
          <QuestionContainer>
            <label htmlFor="infoSite">{translate.questionSite}</label>
            <textarea
              name="infoSite"
              value={infos.infoSite}
              onChange={(e) => editFormValues(e)}
              placeholder={translate.placeholderSite}
            />
          </QuestionContainer>
        </QuestionArea>
        {isLoadingData
          ? (
            <LoaderContainer>
              <LoaderContainerMemo
                count={countFirstStage}
                message={translate.messageProccessInfo}
                className="progress_bar-area"
              />
            </LoaderContainer>
          )
          : (
            <Button
              color="blue"
              onClick={getDataFirstStage}
              disabled={isBtnDisabled}
            >
              {translate.btnOne}
            </Button>
          )}
        {(!isLoadingData && Object.keys(promptsResponse).length > 0) && (
        <ResponseContainer id="first-stage-response">
          {userInfo.email === 'suporte+111menu@shifters.com.br' && (
            <>
              {(promptsResponse?.offer && promptsResponse?.offer.length > 0) && (
              <Card $visible={visibleCards.foundationalCopy}>
                <div
                  className="title"
                  onClick={(e) => handleCardsFoundational('foundationalCopy', e)}
                >
                  <span>Foundational copy</span>
                  <img
                    src={IconClipboard}
                    alt="Copy"
                    onClick={(e) => copyContent(e)}
                  />
                </div>
                <div className="infos">
                  {promptsResponse?.offer}
                </div>
              </Card>
              )}
              {(promptsResponse?.offerHumanized && promptsResponse?.offerHumanized.length > 0) && (
              <Card $visible={visibleCards.foundationalCopyHumanized}>
                <div
                  className="title"
                  onClick={(e) => handleCardsFoundational('foundationalCopyHumanized', e)}
                >
                  <span>Foundational copy humanized</span>
                  <img
                    src={IconClipboard}
                    alt="Copy"
                    onClick={(e) => copyContent(e)}
                  />
                </div>
                <div className="infos">
                  {promptsResponse?.offerHumanized}
                </div>
              </Card>
              )}
            </>
          )}
          {(promptsResponse?.vslSMB && promptsResponse?.vslSMB.length > 0) && (
          <Card $visible>
            <div className="title">
              <span>VSL SMB</span>
              <img
                src={IconClipboard}
                alt="Copy"
                onClick={(e) => copyContent(e)}
              />
            </div>
            <div className="infos">
              {promptsResponse?.vslSMB}
            </div>
          </Card>
          )}
          {(promptsResponse?.vslEnterprise && promptsResponse?.vslEnterprise.length > 0) && (
          <Card $visible>
            <div className="title">
              <span>VSL Enterprise</span>
              <img
                src={IconClipboard}
                alt="Copy"
                onClick={(e) => copyContent(e)}
              />
            </div>
            <div className="infos">
              {promptsResponse?.vslEnterprise}
            </div>
          </Card>
          )}
          {(promptsResponse?.salesLetterSMB && promptsResponse?.salesLetterSMB.length > 0) && (
          <Card id="second-stage-response" $visible>
            <div className="title">
              <span>Sales Letter SMB</span>
              <img
                src={IconClipboard}
                alt="Copy"
                onClick={(e) => copyContent(e)}
              />
            </div>
            <div className="infos">
              {promptsResponse?.salesLetterSMB}
            </div>
          </Card>
          )}
          {(promptsResponse?.salesLetterEnterprise && promptsResponse?.salesLetterEnterprise.length > 0) && (
          <Card $visible>
            <div className="title">
              <span>Sales Letter Enterprise</span>
              <img
                src={IconClipboard}
                alt="Copy"
                onClick={(e) => copyContent(e)}
              />
            </div>
            <div className="infos">
              {promptsResponse?.salesLetterEnterprise}
            </div>
          </Card>
          )}
          {(promptsResponse?.headlines && promptsResponse?.headlines.length > 0) && (
          <Card $visible>
            <div className="title">
              <span>Headlines</span>
              <img
                src={IconClipboard}
                alt="Copy"
                onClick={(e) => copyContent(e)}
              />
            </div>
            <div className="infos">
              {promptsResponse?.headlines}
            </div>
          </Card>
          )}
        </ResponseContainer>
        )}

        {isLoadingDataSecondStage
          ? (
            <LoaderContainer>
              <LoaderContainerMemo
                count={countSecondStage}
                message={translate.messageProccessInfo}
                className="progress_bar-area"
              />
            </LoaderContainer>
          )
          : (
            (showButtonSecondSearch && !isLoadingDataSecondStage) && (
              <Button
                color="blue"
                onClick={getDataSecondStage}
              >
                {translate.btnTwo}
              </Button>
            )
          )}



      </Container>
    </>
  );
}
