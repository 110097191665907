/* eslint-disable no-param-reassign */
import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import AiPromptServices from '../../../../services/AIPrompt';

import toast from '../../../../helpers/toast';

const ManageProjectContext = createContext();

function ManageProjectProvider({ children }) {
  const navigate = useNavigate();

  // STATES SIDEBAR
  const [listOfPrompts, setListOfPrompts] = useState([]);
  const [isLoadingPromptList, setIsLoandingPromptList] = useState(true);

  // STATES RECENTS
  const [listOfRecentsPrompts, setListOfRecentsPrompts] = useState([]);
  const [isLoadingRecentsPromptList, setIsLoandingRecentsPromptList] = useState(true);

  // STATE edit-name page
  const [name, setName] = useState('');

  // STATE project Selected
  const [projectID, setProjectID] = useState(null);
  const [projectInfos, setProjectInfos] = useState(null);
  const [isLoadingProjectInfo, setIsLoadingProjectInfo] = useState(true);

  // GET SIDEBAR ITEMS
  useEffect(() => {
    getListOfPrompts();
    getListOfRecentsPrompts();

    return () => {
      setListOfPrompts([]);
      setListOfRecentsPrompts([]);
    };
  }, []);

  // UPDATE PROJECT INFOS
  useEffect(() => {
    if (projectID) {
      getProjectInfos();
    }

    return () => {
      setProjectInfos(null);
    };
  }, [projectID]);

  async function getListOfPrompts() {
    setIsLoandingPromptList(true);
    const promptsDB = await AiPromptServices.getPrompts();
    setListOfPrompts(promptsDB);
    setIsLoandingPromptList(false);
  }

  async function getListOfRecentsPrompts() {
    setIsLoandingRecentsPromptList(true);
    const promptsDB = await AiPromptServices.getRecentsPrompts();
    setListOfRecentsPrompts(promptsDB);
    setIsLoandingRecentsPromptList(false);
  }

  async function submitFormEditName(event, id) {
    event.preventDefault();

    if (!id) {
      toast('danger', 'ID is required');
      return;
    }

    try {
      const response = await AiPromptServices.updateName({ id, newName: name });
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      updateListWithNewNameUpdated({ id, name });
      navigate('/client/ai-lab/manage-projects');
    }
  }

  async function getProjectInfos() {
    setIsLoadingProjectInfo(true);
    try {
      const projectInfosDB = await AiPromptServices.findOne({ id: projectID });
      setProjectInfos(projectInfosDB);
    } catch (error) {
      toast('danger', error.message);
      navigate('/client/ai-lab/manage-projects');
    } finally {
      setIsLoadingProjectInfo(false);
    }
  }

  function updateListWithNewNameUpdated({ id, name }) {
    const promptsList = listOfPrompts.map((item) => {
      if (Number(item.id) === Number(id)) {
        item.name = name;
      }
      return item;
    });
    const promptsRecentList = listOfRecentsPrompts.map((item) => {
      if (Number(item.id) === Number(id)) {
        item.name = name;
      }
      return item;
    });
    setListOfPrompts(promptsList);
    setListOfRecentsPrompts(promptsRecentList);
  }

  const contextValues = useMemo(() => ({
    listOfPrompts,
    listOfRecentsPrompts,
    isLoadingPromptList,
    isLoadingRecentsPromptList,
    projectInfos,
    name,
    isLoadingProjectInfo,
    setName,
    submitFormEditName,
    setProjectID,
  }), [
    listOfPrompts,
    listOfRecentsPrompts,
    isLoadingPromptList,
    isLoadingRecentsPromptList,
    projectInfos,
    name,
    isLoadingProjectInfo,
    setName,
    submitFormEditName,
    setProjectID,
  ]);

  return (
    <ManageProjectContext.Provider value={contextValues}>
      {children}
    </ManageProjectContext.Provider>
  );
}

export { ManageProjectContext, ManageProjectProvider };
