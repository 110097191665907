import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CategoryProductService from '../../../../../services/CategoryProduct';
import SubCategoryProductService from '../../../../../services/SubCategoryProduct';
import ProductsService from '../../../../../services/Products';
import PostsProductService from '../../../../../services/PostsProduct';
import TitlePage from '../../../../../components/TitlePage';
import Loader from '../../../../../components/Loader';
import BackButton from '../../../../../components/BackButton';
import Square from '../../../../../components/Square';
import ButtonsSaveCancel from '../../../../../components/ButtonsSaveCancel';
import { Button } from '../../../../../components/Button';
import toast from '../../../../../helpers/toast';
import { generateSlug } from '../../../../../helpers/generateSlug';
import { Translate } from './translate';
import {
  Container, InfoContainer, ListContainer,
} from './style';

function PostsEdit() {
  const translate = Translate();
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState({
    namePt: '', nameEn: '', ordering: '', slug: '', idProduct: null, idCategory: null, idSubCategory: null,
  });
  const [productsList, setProductList] = useState([]);
  const [productSelected, setProductSelected] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategorySelected, setSubCategorySelected] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoadingLists, setIsLoadingLists] = useState(true);

  useEffect(() => {
    loadPostInfo();
  }, []);

  useEffect(() => {
    verifyFormToSave();
    loadAllLists(post.idProduct, post.idCategory, post.idSubCategory);
  }, [post.idProduct, post.idCategory, post.idSubCategory]);

  useEffect(() => {
    verifyFormToSave();
  }, [post, subCategorySelected]);

  async function loadPostInfo() {
    const post = await PostsProductService.findById(id);
    setPost({
      ...post,
      namePt: post.name_pt,
      nameEn: post.name_en,
      ordering: post.ordering,
      slug: post.slug,
      idProduct: post.product_id,
      idCategory: post.id_category,
      idSubCategory: post.id_subcategory,
    });
  }

  async function loadAllLists(idProduct, idCategory, idSubCategory) {
    try {
      setIsLoadingLists(true);
      const products = await ProductsService.findAll({ firstVideo: 'yes' });
      setProductList(products);
      setProductSelected(idProduct);

      const categoriesSeletedByIdProduct = await findAllCategoriesByProduct(idProduct);
      setCategoryList(categoriesSeletedByIdProduct);
      setCategorySelected(idCategory);

      const subCategoriesListByIdProductAndIDCategory = await findAllSubCategoriesByProductAndCategory(idProduct, idCategory);
      setSubCategoryList(subCategoriesListByIdProductAndIDCategory);
      setSubCategorySelected(idSubCategory);
    } catch {} finally {
      setIsLoadingLists(false);
    }
  }

  async function findAllCategoriesByProduct(id) {
    const categoriesSeletedByIdProduct = await CategoryProductService.findAll(id);
    return categoriesSeletedByIdProduct;
  }

  async function findAllSubCategoriesByProductAndCategory(idProduct, idCategory) {
    const subCategories = await SubCategoryProductService.findAllByProductAndCategory(idProduct, idCategory);
    const subCategoriesFiltered = subCategories.filter((subCategory) => subCategory.hasPosts === 'Y');
    return subCategoriesFiltered;
  }

  async function handleProductOnChange(idProduct) {
    setProductSelected(idProduct);

    const categoriesSeletedByIdProduct = await findAllCategoriesByProduct(idProduct);
    if (categoriesSeletedByIdProduct.length > 0) {
      setCategoryList(categoriesSeletedByIdProduct);
      setCategorySelected(categoriesSeletedByIdProduct[0].id);

      const subCategoriesListByIdProductAndIDCategory = await findAllSubCategoriesByProductAndCategory(idProduct, categoriesSeletedByIdProduct[0].id);
      if (subCategoriesListByIdProductAndIDCategory.length > 0) {
        setSubCategoryList(subCategoriesListByIdProductAndIDCategory);
        setSubCategorySelected(subCategoriesListByIdProductAndIDCategory[0].id);
      } else {
        setSubCategoryList([]);
        setSubCategorySelected(null);
      }
    } else {
      setCategoryList([]);
      setCategorySelected(null);
      setSubCategoryList([]);
      setSubCategorySelected(null);
    }
  }

  async function handleCategoryOnChange(idCategory) {
    setCategorySelected(idCategory);

    const subCategoriesListByIdProductAndIDCategory = await findAllSubCategoriesByProductAndCategory(productSelected, idCategory);
    if (subCategoriesListByIdProductAndIDCategory.length > 0) {
      setSubCategoryList(subCategoriesListByIdProductAndIDCategory);
      setSubCategorySelected(subCategoriesListByIdProductAndIDCategory[0].id);
    } else {
      setSubCategoryList([]);
      setSubCategorySelected(null);
    }
  }

  async function save() {
    try {
      setIsDisabled(true);
      await PostsProductService.update(id, {
        namePt: post.namePt,
        nameEn: post.nameEn,
        ordering: post.ordering,
        slug: post.slug,
        idSubCategory: Number(subCategorySelected),
      });
      toast('success', 'Post Updated Succesfully');
    } catch (error) {
      toast('danger', error.message);
    } finally {
      navigate('/admin/posts');
    }
  }

  function verifyFormToSave() {
    const namePtIsValid = post.namePt.length >= 3;
    const nameEnIsValid = post.nameEn.length >= 3;
    const slugIsValid = post.slug.length >= 3;
    const subCategoryIsValid = Boolean(subCategorySelected);

    if (namePtIsValid && nameEnIsValid && slugIsValid && subCategoryIsValid) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  function handleGenerateSlug() {
    setPost({ ...post, slug: generateSlug(post?.namePt) });
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton />
      </TitlePage>
      <Container>
        <ListContainer>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textName}
              </div>
              <div className="c-right">
                <div className="c-input">
                  <label htmlFor="name_pt">{translate.labelNamePt}</label>
                  <input
                    className="l-input-large"
                    type="text"
                    id="name_pt"
                    value={post.namePt}
                    onChange={(e) => {
                      setPost({ ...post, namePt: e.target.value });
                    }}
                  />
                </div>
                <div className="c-input">
                  <label htmlFor="name_en">{translate.labelNameEn}</label>
                  <input
                    type="text"
                    className="l-input-large"
                    id="name_en"
                    value={post.nameEn}
                    onChange={(e) => {
                      setPost({ ...post, nameEn: e.target.value });
                    }}
                  />
                </div>
              </div>
            </InfoContainer>
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                Slug
              </div>
              <div className="c-right">
                <Button color="yellow" onClick={handleGenerateSlug}>Gerar slug</Button>
                <div className="c-input">
                  <input
                    className="l-input-w"
                    type="text"
                    id="slug"
                    value={post.slug}
                    onChange={(e) => {
                      setPost({ ...post, slug: e.target.value });
                      verifyFormToSave();
                    }}
                  />
                </div>
              </div>
            </InfoContainer>
          </Square>
          <Square size="large">
            <InfoContainer>
              <div className="c-left">
                {translate.textOrdering}
              </div>
              <div className="c-right">
                <div className="c-input">
                  <input
                    type="text"
                    id="ordering"
                    value={post.ordering}
                    onChange={(e) => setPost({ ...post, ordering: e.target.value })}
                  />
                </div>
              </div>
            </InfoContainer>
          </Square>
          <Square size="large">
            {isLoadingLists ? <Loader loading={isLoadingLists} /> : (
              <>
                <InfoContainer>
                  <div className="c-left">
                    {translate.textProduct}
                  </div>
                  <div className="c-right">
                    <div className="c-input">
                      <label htmlFor="products">{translate.labelProduct}</label>
                      <select
                        type="text"
                        id="products"
                        value={productSelected}
                        onChange={(e) => handleProductOnChange(e.target.value)}
                      >
                        {productsList?.map((product) => (
                          <option value={product.id} key={product.id}>
                            {translate.language === 'pt-BR' ? product.namePt : product.nameEn}
                          </option>
                        ))}
                      </select>
                    </div>
                    {categoryList.length <= 0 ? null : (
                      <div className="c-input">
                        <label htmlFor="categories">{translate.labelCategory}</label>
                        <select
                          type="text"
                          id="categories"
                          value={categorySelected}
                          onChange={(e) => handleCategoryOnChange(e.target.value)}
                        >
                          {categoryList.length > 0 && categoryList?.map((category) => <option value={category.id} key={category.id}>{category.namePt}</option>)}
                        </select>
                      </div>
                    )}
                    {subCategoryList.length <= 0 ? null : (
                      <div className="c-input">
                        <label htmlFor="sub_category">{translate.labelSubCategory}</label>
                        <select
                          type="text"
                          id="sub_category"
                          value={subCategorySelected}
                          onChange={(e) => setSubCategorySelected(e.target.value)}
                        >
                          {subCategoryList.length > 0 && subCategoryList?.map((subCategory) => <option value={subCategory.id} key={subCategory.id}>{subCategory.name_pt}</option>)}
                        </select>
                      </div>
                    )}
                  </div>
                </InfoContainer>
                <div className="c-explanation">{translate.warningProduct}</div>
              </>
            ) }

          </Square>
        </ListContainer>
        <ButtonsSaveCancel
          onSave={save}
          goToWhenCancel="/admin/posts"
          disabled={isDisabled}
          smallText={isDisabled ? translate.warnText : ''}
        />
      </Container>
    </>
  );
}

export default PostsEdit;
