import {
  useState, useEffect, createContext, useMemo,
} from 'react';
import { PropTypes } from 'prop-types';
import ProductsService from '../../services/Products';

const ProductsContext = createContext();

function ProductsProvider({ children }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getAllProducts();
  }, []);

  async function getAllProducts() {
    const productsList = await ProductsService.findAll({ firstVideo: 'yes' });
    const productsFiltered = productsList.length > 0 ? productsList?.filter((product) => product.active === 'Y') : [];
    setProducts(productsFiltered);
  }

  const contextValues = useMemo(() => ({ products }), [products]);

  return (
    /* eslint-disable-next-line react/jsx-no-constructed-context-values */
    <ProductsContext.Provider value={contextValues}>
      {children}
    </ProductsContext.Provider>
  );
}

ProductsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ProductsContext, ProductsProvider };
