import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TitlePage from '../../../../../components/TitlePage';
import BackButton from '../../../../../components/BackButton';
import toast from '../../../../../helpers/toast';
import ProductService from '../../../../../services/Products';
import { Translate } from './translate';
import { ProductForm } from '../component/ProductForm';

export function ProductEdit() {
  const translate = Translate();
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await getProducts();
    })();
  }, []);

  async function getProducts() {
    try {
      setIsLoading(true);
      const productRes = await ProductService.findOne(id);
      setProduct({
        ...productRes,
        namePt: productRes.name_pt,
        nameEn: productRes.name_en,
      });
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateProduct() {
    try {
      const response = await ProductService.update(id, product);
      toast('success', response.message);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      navigate('/admin/products');
    }
  }

  return (
    <>
      <TitlePage title={translate.title}>
        <BackButton />
      </TitlePage>
      {isLoading
        ? 'Loading infos...'
        : <ProductForm product={product} setProduct={setProduct} onSave={updateProduct} />}
    </>
  );
}
