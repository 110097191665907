export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Filtros' : 'Filters',
    database: language === 'pt-BR' ? 'Base de dados' : 'Databases',
    emailFirst: language === 'pt-BR' ? '1º E-mail' : '1st E-mail',
    emailSecond: language === 'pt-BR' ? '2º E-mail' : '2nd E-mail',
    nameFirst: language === 'pt-BR' ? 'Nome' : 'Name',
    nameMiddle: language === 'pt-BR' ? 'Nome meio' : 'Mid. name',
    nameLast: language === 'pt-BR' ? 'Últ. nome' : 'Last name',
    url: language === 'pt-BR' ? 'URL' : 'URL',
    annualRevenue: language === 'pt-BR' ? 'Receita' : 'Revenue',
    headquartersRegion: language === 'pt-BR' ? 'Sede' : 'Headquarters',
    selectedFilter: language === 'pt-BR' ? 'Filtros pré-selecionados' : 'Pre-selected filters',
    role: language === 'pt-BR' ? 'Cargo' : 'Role',
    country: language === 'pt-BR' ? 'País' : 'Country',
    state: language === 'pt-BR' ? 'Estado' : 'State',
    city: language === 'pt-BR' ? 'Cidade' : 'City',
    sector: language === 'pt-BR' ? 'Setor' : 'Sector',
    company: language === 'pt-BR' ? 'Empresa' : 'Company',
    companyDomain: language === 'pt-BR' ? 'Domínio' : 'Domain',
    linkedinID: language === 'pt-BR' ? 'Linkedin ID' : 'Linkedin ID',
    listName: language === 'pt-BR' ? 'Nome lista' : 'List name',
    listMeta: language === 'pt-BR' ? 'Base Meta' : 'Meta data',
    technology: language === 'pt-BR' ? 'Tecnologia' : 'Technology',
    employeesQuantity: language === 'pt-BR' ? 'Funcionários' : 'Employees',
    exactPositive: language === 'pt-BR' ? 'Exato positivo' : 'Exact positive',
    constainsPositive: language === 'pt-BR' ? 'Contém positivo' : 'Contains positive',
    exactNegative: language === 'pt-BR' ? 'Exato negativo' : 'Exact negative',
    containsNegative: language === 'pt-BR' ? 'Contém negativo' : 'Contains negative',
    btnApply: language === 'pt-BR' ? 'Aplicar' : 'Apply',
    language,
  };
}
