import styled, { css } from 'styled-components';

export const PageContainer = styled.div`
  transition: 0.4s ease-in-out;
  padding-left: ${({ $toogle }) => ($toogle ? '260px' : '0px')};
  overflow-x: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 0px;
  }
`;
