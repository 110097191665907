import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    font-weight: 500;
  }

  span {
    color: ${({ theme }) => theme.gray4}
  }
`;

export const EntranceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  h3 {
    font-weight: 500;
  }

  .info-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border:1px solid ${({ theme }) => theme.gray1};
    border-radius: 4px;

    label {
      background-color: ${({ theme }) => theme.gray1};
      width: 100%;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      padding: 8px;
      font-weight: 500;
    }

    div {
      background-color: ${({ theme }) => theme.grayMenu};
      width: 100%;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 8px;
      // Important to format texts in each card
      white-space: pre-wrap;
      word-wrap: break-word;
      max-height: 200px;
      overflow-y: auto;
    }
  }
`;

export const ResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 32px;

  h3 {
    font-weight: 500;
  }
`;

export const ResponseCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 32px 16px;
`;


export const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.gray1};
  border-radius: 4px;
  height: ${({ $visible }) => ($visible ? '550px' : '70px')};
  /* max-height: ${({ $visible }) => ($visible ? '550px' : '40px')}; */
  display: flex;  /* Adicione isso */
  flex-direction: column;  /* Adicione isso */

    .title {
      background-color:${({ theme }) => theme.gray1};
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      padding: 8px;
      position: relative;

      span {
        cursor: ${({ $visible }) => ($visible ? 'normal' : 'pointer')};
        font-weight: 500;
      }

      img {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 3%;
        transition: 0.2 ease-in-out;
        transform: translateY(-50%);
        filter: invert(99%) sepia(7%) saturate(312%) hue-rotate(195deg) brightness(113%) contrast(89%);
        width: 13px;
      }

      img:hover {
        filter: invert(73%) sepia(0%) saturate(169%) hue-rotate(196deg) brightness(91%) contrast(85%);
      }
    }

    .infos {
      overflow-y: scroll;
      flex: 1;  /* Substitua height: 100% por flex: 1 */
      padding: 16px;
      background-color:${({ theme }) => theme.grayMenu};
      height: inherit;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;

      // Important to format texts in each card
      white-space: pre-wrap;
      word-wrap: break-word;
    }
`;

