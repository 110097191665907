import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AreaContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  height: 100%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
      overflow: auto;
      font-size: 13px;
    }
`;

export const FilterContainer = styled.div`
  flex: 1;

  h3 {
    font-weight: 500;
  }

  hr {
    margin: 0;
    margin-top: 8px;
    border: 1px solid ${({ theme }) => theme.gray1};
  }

  .area-filter {
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    input, .selected-filters {
      border: 1px solid ${({ theme }) => theme.gray2};
    }

    .l-red {
      border-color: ${({ theme }) => theme.red};
    }

    .l-green {
      border-color: ${({ theme }) => theme.green};
    }

    input {
      margin-bottom: 8px;
    }

    .selected-filters {
      height: 250px;
      overflow-y: auto;
      max-width: 170px;
      width: 100%;

      @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
        height: 80px;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px;
        font-size: 13px;
        width: 100%;

        &:hover {
        background-color: ${({ theme }) => theme.gray2};
      }

        .delete-icon {

          &:hover {
            cursor: pointer;
          }

          img {
            width: 13px;

            &:hover {
              filter: invert(39%) sepia(80%) saturate(4270%) hue-rotate(1deg) brightness(100%) contrast(99%);
            }
          }
        }
      }
    }

    .group-filter {
      display: flex;
      flex-direction: column;
    }

    .clear-filters {
      margin-top: 4px;
      font-size: 11px;
      cursor: pointer;

      span:hover {
        color: ${({ theme }) => theme.yellow};
        transform: scale(1.1);
      }


    }
  }
`;
