import {
  useEffect, createContext, useMemo, useState, useContext,
} from 'react';
import toast from '../../../../helpers/toast';
import B2BDatabaseService from '../../../../services/B2BDatabase';
import { B2BDataBaseFilterContext } from './B2BDatabaseFilter.context';

const B2BDataBaseDataContext = createContext();

function B2BDataBaseDataProvider({ children }) {
  const { filterApplied, handleClickApply } = useContext(B2BDataBaseFilterContext);
  const [b2bData, setB2bData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [pagination, setPagination] = useState({ currentPage: 1, quantityItems: 50 });
  const [totalPage, setTotalPage] = useState(0);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setPagination(((prevState) => ({ ...prevState, currentPage: 1 })));
  }, [pagination.quantityItems]);

  useEffect(() => {
    getData();
  }, [pagination.currentPage, pagination.quantityItems]);

  useEffect(() => {
    if (handleClickApply) {
      setPagination(((prevState) => ({ ...prevState, currentPage: 1 })));
      getData();
    }
  }, [handleClickApply]);

  function handlePages(event) {
    if (event !== 'increase' && event !== 'decrease') return;
    if (Number(totalPage.toFixed(0)) === 0) return;
    if (event === 'increase') {
      if (Number(pagination.currentPage) === Number(totalPage.toFixed(0))) return;
      setPagination((prevState) => ({ ...prevState, currentPage: prevState.currentPage + 1 }));
    }
    if (event === 'decrease') {
      if (Number(pagination.currentPage) === 1) return;
      setPagination((prevState) => ({ ...prevState, currentPage: prevState.currentPage - 1 }));
    }
  }

  async function getData() {
    try {
      setIsLoadingData(true);
      const response = await B2BDatabaseService.findAllAdminView({ filters: filterApplied, pagination });
      setB2bData(response.data);
      setTotalData(Number(response.total));
      setTotalPage(Number(Math.ceil((response.total / pagination.quantityItems))));
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoadingData(false);
    }
  }

  const contextValues = useMemo(() => ({
    b2bData,
    totalData,
    isLoadingData,
    pagination,
    totalPage,
    handlePages,
    setPagination,
  }), [
    b2bData,
    totalData,
    isLoadingData,
    pagination,
    totalPage,
    handlePages,
    setPagination,
  ]);

  return (
    <B2BDataBaseDataContext.Provider value={contextValues}>
      {children}
    </B2BDataBaseDataContext.Provider>
  );
}

export { B2BDataBaseDataContext, B2BDataBaseDataProvider };
