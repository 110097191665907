import {
  createContext, useState, useEffect, useMemo,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ProductsService from '../../../../../services/Products';
import CategoryProductService from '../../../../../services/CategoryProduct';
import SubCategoryProductService from '../../../../../services/SubCategoryProduct';
import VideosService from '../../../../../services/Videos';
import PostsProductService from '../../../../../services/PostsProduct';
import toast from '../../../../../helpers/toast';

const VideoContext = createContext();

function VideoProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [video, setVideo] = useState({
    titlePt: new URLSearchParams(location.search).get('title_pt') || '',
    titleEn: new URLSearchParams(location.search).get('title_en') || '',
    typeVideo: 'vimeo',
    linkVideo: '',
    descriptionPt: '',
    descriptionEn: '',
    transcriptPt: '',
    transcriptEn: '',
    allowTranscript: 'Y',
    relationType: new URLSearchParams(location.search).get('type') || 'category', // "category", "subCategory", "post"
    postId: undefined,
    subCategoryId: undefined,
    categoryId: undefined,
  });
  const typeParam = new URLSearchParams(location.search).get('type');
  const productParam = new URLSearchParams(location.search).get('product');
  const categoryParam = new URLSearchParams(location.search).get('category');
  const subcategoryParam = new URLSearchParams(location.search).get('subcategory');
  const postParam = new URLSearchParams(location.search).get('post');
  const [files, setFiles] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [postsList, setPostsList] = useState([]);
  const [productSelected, setProductSelected] = useState(productParam || '');
  const [categorySelected, setCategorySelected] = useState(categoryParam || '');
  const [subCategorySelected, setSubCategorySelected] = useState(subcategoryParam || '');
  const [postSelected, setPostSelected] = useState(postParam || '');
  const [isSavingInfo, setIsSavingInfo] = useState(false);
  const [isDisableButtom, setIsDisabledButtom] = useState(true);
  const [isLoadingInfo, setIsLoadingInfo] = useState(true);

  useEffect(() => {
    async function init() {
      setIsLoadingInfo(true);
      try {
        if (typeParam) {
          await firstLoadOfListsWithParams();
        } else {
          await firstLoadOfLists();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingInfo(false);
      }
    }
    // setTimeout(() => setIsLoadingInfo(true), 1000);

    init();
  }, []);

  useEffect(() => {
    allowSaveVideo();
  }, [video]);

  useEffect(() => {
    handleProductOnChange();
  }, [productSelected, video.relationType]);

  useEffect(() => {
    if (video.typeVideo === 'sem video') setVideo({ ...video, linkVideo: '' });
  }, [video.typeVideo]);

  async function saveVideo() {
    setIsSavingInfo(true);
    try {
      await VideosService.store(video, files);
      toast('success', 'Video created');
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsSavingInfo(false);
      navigate(-1);
    }
  }

  async function firstLoadOfLists() {
    const productList = await ProductsService.findAll({ firstVideo: 'no' });
    if (productList.length > 0) {
      setProductsList(productList);
      const idProduct = productList[0].id;
      setProductSelected(idProduct);
      const categoryList = await CategoryProductService.findAll(idProduct);
      if (categoryList.length > 0) {
        const categoryListFilteredBySubCategoryEqualsToNo = categoryList.filter((category) => category.hasSubCategory === 'N');
        if (categoryListFilteredBySubCategoryEqualsToNo.length > 0) {
          setCategoriesList(categoryListFilteredBySubCategoryEqualsToNo);
          const idCategory = categoryListFilteredBySubCategoryEqualsToNo[0].id;
          setCategorySelected(idCategory);
          setVideo({
            ...video, categoryId: idCategory, subCategoryId: undefined, postId: undefined,
          });
        } else {
          setCategoriesList([]);
          setCategorySelected(undefined);
        }
      } else {
        setCategoriesList([]);
        setCategorySelected(undefined);
      }
    } else {
      setProductsList([]);
      setProductSelected(undefined);
    }
  }

  async function firstLoadOfListsWithParams() {
    const productList = await ProductsService.findAll({ firstVideo: 'no' });
    if (productList.length > 0) {
      setProductsList(productList);
      const idProduct = productParam || productList[0].id;
      setProductSelected(idProduct);

      if (typeParam === 'category') {
        const categoryList = await CategoryProductService.findAll(idProduct);
        if (categoryList.length > 0) {
          const categoryListFilteredBySubCategoryEqualsToNo = categoryList.filter((category) => category.hasSubCategory === 'N');
          if (categoryListFilteredBySubCategoryEqualsToNo.length > 0) {
            setCategoriesList(categoryListFilteredBySubCategoryEqualsToNo);
            const idCategory = categoryParam || categoryListFilteredBySubCategoryEqualsToNo[0].id;
            setCategorySelected(idCategory);
            setVideo({
              ...video, categoryId: idCategory, subCategoryId: undefined, postId: undefined,
            });
          } else {
            setCategoriesList([]);
            setCategorySelected(undefined);
          }
        } else {
          setCategoriesList([]);
          setCategorySelected(undefined);
        }
      }

      if (typeParam === 'subcategory') {
        const subCategoryList = await SubCategoryProductService.findAll(idProduct, 'product');
        if (subCategoryList.length > 0) {
          const subCategoryListFilteredByHasPostsEqualsToNo = subCategoryList.filter((subCategory) => subCategory.hasPosts === 'N');
          if (subCategoryListFilteredByHasPostsEqualsToNo.length > 0) {
            setSubCategoriesList(subCategoryListFilteredByHasPostsEqualsToNo);
            const subCategoryId = subcategoryParam || subCategoryListFilteredByHasPostsEqualsToNo[0].id;
            setSubCategorySelected(subCategoryId);
            setVideo({
              ...video, categoryId: undefined, subCategoryId, postId: undefined,
            });
          } else {
            setSubCategoriesList([]);
            setSubCategorySelected(undefined);
            setVideo({ ...video, subCategoryId: undefined });
          }
        } else {
          setSubCategoriesList([]);
          setSubCategorySelected(undefined);
          setVideo({ ...video, subCategoryId: undefined });
        }
      }

      if (typeParam === 'post') {
        const postList = await PostsProductService.findAll(idProduct, 'product');
        if (postList.length > 0) {
          setPostsList(postList);
          const postId = postParam || postList[0].id;
          setPostSelected(postId);
          setVideo({
            ...video, categoryId: undefined, subCategoryId: undefined, postId,
          });
        } else {
          setPostsList([]);
          setPostSelected(undefined);
          setVideo({ ...video, postId: undefined });
        }
      }
    } else {
      setProductsList([]);
      setProductSelected(undefined);
    }
  }

  async function handleProductOnChange(id = undefined) {
    const idProduct = id ? Number(id) : productSelected;
    setProductSelected(idProduct);

    if (video.relationType === 'category') {
      const categoryList = await CategoryProductService.findAll(idProduct);
      if (categoryList.length > 0) {
        const categoryListFilteredBySubCategoryEqualsToNo = categoryList.filter((category) => category.hasSubCategory === 'N');
        if (categoryListFilteredBySubCategoryEqualsToNo.length > 0) {
          setCategoriesList(categoryListFilteredBySubCategoryEqualsToNo);
          setCategorySelected(categoryListFilteredBySubCategoryEqualsToNo[0].id);
          setVideo({
            ...video, categoryId: categoryListFilteredBySubCategoryEqualsToNo[0].id, subCategoryId: undefined, postId: undefined,
          });
        } else {
          setCategoriesList([]);
          setCategorySelected(undefined);
          setVideo({ ...video, categoryId: undefined });
        }
      } else {
        setCategoriesList([]);
        setCategorySelected(undefined);
        setVideo({ ...video, categoryId: undefined });
      }
    }

    if (video.relationType === 'subcategory') {
      const subCategoryList = await SubCategoryProductService.findAll(idProduct, 'product');
      if (subCategoryList.length > 0) {
        const subCategoryListFilteredByHasPostsEqualsToNo = subCategoryList.filter((subCategory) => subCategory.hasPosts === 'N');
        if (subCategoryListFilteredByHasPostsEqualsToNo.length > 0) {
          setSubCategoriesList(subCategoryListFilteredByHasPostsEqualsToNo);
          setSubCategorySelected(subCategoryListFilteredByHasPostsEqualsToNo[0].id);
          setVideo({
            ...video, categoryId: undefined, subCategoryId: subCategoryListFilteredByHasPostsEqualsToNo[0].id, postId: undefined,
          });
        } else {
          setSubCategoriesList([]);
          setSubCategorySelected(undefined);
          setVideo({ ...video, subCategoryId: undefined });
        }
      } else {
        setSubCategoriesList([]);
        setSubCategorySelected(undefined);
        setVideo({ ...video, subCategoryId: undefined });
      }
    }

    if (video.relationType === 'post') {
      const postList = await PostsProductService.findAll(idProduct, 'product');
      if (postList.length > 0) {
        setPostsList(postList);
        setPostSelected(postList[0].id);
        setVideo({
          ...video, categoryId: undefined, subCategoryId: undefined, postId: postList[0].id,
        });
      } else {
        setPostsList([]);
        setPostSelected(undefined);
        setVideo({ ...video, postId: undefined });
      }
    }
  }

  function handleAddToInputOnChange(relationType) {
    setVideo({ ...video, relationType });
  }

  function handleFiles(files) {
    const arrayofFiles = Array.from(files);
    setFiles(arrayofFiles);
  }

  function allowSaveVideo() {
    const titlePtIsValid = video.titlePt.length >= 3;
    const titleEnIsValid = video.titleEn.length >= 3;

    if (titlePtIsValid && titleEnIsValid && productSelected) {
      setIsDisabledButtom(false);
    } else {
      setIsDisabledButtom(true);
    }
  }

  function handleOptionsSelectedOnChange(type, value) {
    const idReffered = Number(value);
    if (type === 'category') {
      setVideo({ ...video, categoryId: idReffered });
      setCategorySelected(idReffered);
    }

    if (type === 'subcategory') {
      setVideo({ ...video, subCategoryId: idReffered });
      setSubCategorySelected(idReffered);
    }

    if (type === 'post') {
      setVideo({ ...video, postId: idReffered });
      setPostSelected(idReffered);
    }
  }

  function handleDescriptionPt(content) {
    setVideo({ ...video, descriptionPt: content });
  }

  function handleDescriptionEn(content) {
    setVideo({ ...video, descriptionEn: content });
  }

  const contextValues = useMemo(() => ({
    video,
    setVideo,
    productsList,
    categoriesList,
    subCategoriesList,
    postsList,
    productSelected,
    categorySelected,
    subCategorySelected,
    postSelected,
    handleProductOnChange,
    handleAddToInputOnChange,
    handleOptionsSelectedOnChange,
    handleDescriptionPt,
    handleDescriptionEn,
    handleFiles,
    saveVideo,
    isSavingInfo,
    isDisableButtom,
    isLoadingInfo,
  }), [video,
    setVideo,
    productsList,
    categoriesList,
    subCategoriesList,
    postsList,
    productSelected,
    categorySelected,
    subCategorySelected,
    postSelected,
    handleProductOnChange,
    handleAddToInputOnChange,
    handleOptionsSelectedOnChange,
    handleDescriptionPt,
    handleDescriptionEn,
    handleFiles,
    saveVideo,
    isSavingInfo,
    isDisableButtom,
    isLoadingInfo]);
  return (
    <VideoContext.Provider value={contextValues}>
      {children}
    </VideoContext.Provider>
  );
}

export { VideoContext, VideoProvider };
