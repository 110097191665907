class B2BDatabaseEmployeesQuantity {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findAll() {
    const path = `${this.baseUrl}/b2bdatabase/employees-quantity`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'GET',
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async store({ name }) {
    const path = `${this.baseUrl}/b2bdatabase/employees-quantity`;

    const response = await fetch(path, {
      headers: {
        'Content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify({
        name,
      }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }
}

export default new B2BDatabaseEmployeesQuantity();
