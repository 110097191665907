import styled from 'styled-components';

export const ContainerPage = styled.div`
  display: flex;
  min-height: 100vh;
  place-items: center;
  justify-content: center;
`;

export const MainMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  div {
    text-align: center;
  }

  .container-title {
    margin-bottom: 32px;

    h3 {
      color: ${({ theme }) => theme.gray5};
    }
  }

  .container-offer {
    font-size: 20px;

    p {
      margin-bottom: 8px;
      color: ${({ theme }) => theme.yellow};
    }

    ul li {
      list-style: none;

      img {
        width: 16px;
      }
    }
  }

  .container-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    gap: 16px;

    a {
      width: 100%;
      text-align: center;
      font-size: 20px;
    }
  }
`;
