import React from 'react';

import { ManageProjectProvider } from './manageProjects.context';

import { Sidebar } from './sidebar';
import { RouterManageProjects } from './pages/RouterManageProjects';

import { Container, SideBarArea, RoutesArea } from './style';

export function AIManageProjects() {
  return (
    <ManageProjectProvider>
      <Container>
        <SideBarArea>
          <Sidebar />
        </SideBarArea>
        <RoutesArea>
          <RouterManageProjects />
        </RoutesArea>
      </Container>
    </ManageProjectProvider>
  );
}
