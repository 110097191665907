import React from 'react';
import { Link } from 'react-router-dom';
import IconLink from '../../../../../styles/logos/icon_link.svg';

export function ItemUser({ data }) {
  return (
    <tr>
      <td>
        <Link to={data.url} target="__blank" className="link-container">
          <img src={IconLink} alt="LinkedIn" />
          {data.url.slice(0, 27)}
        </Link>
      </td>
      <td>
        {data.jobTitle}
      </td>
      <td>
        {data.companyName}
      </td>
      <td>
        {data.companyDomain}
      </td>
      <td className="l-city">
        {data.city}
      </td>
    </tr>
  );
}
