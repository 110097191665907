
import React, { useState, useEffect } from 'react';
import TitlePage from '../../../../components/TitlePage';
import Square from '../../../../components/Square';
import Loader from '../../../../components/Loader';
import { NoDataMessage } from '../../../../components/NoDataMessage';
import toast from '../../../../helpers/toast';
import Dates_New from '../../../../helpers/Dates_New';
import B2BDatabaseLogsService from '../../../../services/B2BDatabaseLogs';
import { Translate } from './translate';
import {
  Message, Container, SquareHeaderContainer, SquareFiltersContainer,
} from './style';

export function B2BDatabaseLog() {
  const translate = Translate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      setIsLoading(true);
      const response = await B2BDatabaseLogsService.findAll();
      setData(response);
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <>
      <TitlePage title="B2B Database - Logs" />
      <Message>{translate.msg}</Message>
      <Container>
        {isLoading && <Loader loading={isLoading} />}
        {(data.length === 0 && !isLoading) && <NoDataMessage>{translate.msgNoData}</NoDataMessage>}
        {(data.length > 0 && !isLoading) && (
          data.map((item) => (
            <Square size="medium" key={item.id}>
              <SquareHeaderContainer>
                <span>{item.email}</span>
                <span>{item.profile}</span>
                <span>{Dates_New.formatDatesMaskWithHour(item.dateAct)}</span>
              </SquareHeaderContainer>
              <SquareFiltersContainer>
                <h4>{translate.filtersTitle}</h4>
                <pre>
                  {JSON.stringify(JSON.parse(item.filters), null, 6)}
                </pre>
              </SquareFiltersContainer>
            </Square>
          ))
        )}
      </Container>
    </>
  );
}

