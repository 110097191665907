class B2BDatabase {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH;
  }

  async findPositions({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/position`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findSector({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/sector`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findCompany({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/company`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findCompanyDomain({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/company-domain`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findLinkedinID({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/linkedin-id`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findFirstEmail({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/email-first`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findSecondEmail({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/email-second`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findFirstName({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/name-first`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findMiddleName({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/name-middle`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findLastName({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/name-last`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findUrl({ searchTerm, hideTerms }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/url`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ searchTerm, hideTerms }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findRegions({ country, state, city }) {
    const path = `${this.baseUrl}/b2bdatabase/filters/regions`;

    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify({ country, state, city }),
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findTechnology() {
    const path = `${this.baseUrl}/b2bdatabase/filters/technology`;

    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findListName() {
    const path = `${this.baseUrl}/b2bdatabase/filters/list-name`;

    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findEmployeesQuantity() {
    const path = `${this.baseUrl}/b2bdatabase/filters/employees-quantity`;

    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findHeadquartersRegion() {
    const path = `${this.baseUrl}/b2bdatabase/filters/headquarters-region`;

    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }

  async findAnnualRevenue() {
    const path = `${this.baseUrl}/b2bdatabase/filters/annual-revenue`;

    const response = await fetch(path, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: localStorage.getItem('token'),
      },
    });

    const body = await response.json();
    if (!response.ok) throw new Error(`${response.status} - ${body.message}`);
    return body;
  }
}

export default new B2BDatabase();
