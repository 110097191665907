
import React, { useEffect, useState } from 'react';

import B2BDatabaseService from '../../../../services/B2BDatabase';
import B2BDatabaseSectorService from '../../../../services/B2BDatabaseSector';
import B2BDatabaseTechnologyService from '../../../../services/B2BDatabaseTechnology';
import B2BDatabaseAnnualRevenueService from '../../../../services/B2BDatabaseAnnualRevenue';
import B2BDatabaseEmployeesQuantityService from '../../../../services/B2BDatabaseEmployeesQuantity';
import B2BDatabaseHeadquartersRegionService from '../../../../services/B2BDatabaseHeadquartersRegion';

import TitlePage from '../../../../components/TitlePage';
import { Button } from '../../../../components/Button';
import Loader from '../../../../components/Loader';

import { Translate } from './translate';
import {
  Container, ContainerIDAndName, ContainerInputs, ContainerRatio, FormContainer, ContainerLoading, ContainerError,
} from './style';
import toast from '../../../../helpers/toast';

export function B2BDatabaseAddList() {
  const translate = Translate();
  const [isDisableBtn, setIsDisabledBtn] = useState(true);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [list, setList] = useState({
    sector: [],
    technology: [],
    employeesQuantity: [],
    annualRevenue: [],
    headquartersRegion: [],
  });
  const [filters, setFilters] = useState({
    id: '',
    name: '',
    sector: '',
    technology: '',
    employeesQuantity: '',
    annualRevenue: '',
    headquartersRegion: '',
    typeBase: 'notVerified',
    file: [],
  });
  const [error, setError] = useState(false);
  const [errorContent, setErrorContent] = useState({ code: '', message: '' });

  useEffect(() => {
    getDatas();

    return () => {
      setList({
        sector: [],
        technology: [],
        employeesQuantity: [],
        annualRevenue: [],
        headquartersRegion: [],
      });
    };
  }, []);

  useEffect(() => {
    handleToogleBtn();
  }, [filters]);

  async function getDatas() {
    try {
      const [
        dataSector,
        dataTechnology,
        dataAnnualRevenue,
        dataEmployeesQuantity,
        dataHeadquartersRegion,
      ] = await Promise.allSettled([
        B2BDatabaseSectorService.findAll(),
        B2BDatabaseTechnologyService.findAll(),
        B2BDatabaseAnnualRevenueService.findAll(),
        B2BDatabaseEmployeesQuantityService.findAll(),
        B2BDatabaseHeadquartersRegionService.findAll(),
      ]);

      setList((prevState) => ({
        ...prevState,
        sector: dataSector?.value.map((item) => ({ id: item.id, name: item.name })),
        technology: dataTechnology?.value.map((item) => ({ id: item.id, name: item.name })),
        annualRevenue: dataAnnualRevenue?.value.map((item) => ({ id: item.id, name: item.name })),
        employeesQuantity: dataEmployeesQuantity?.value.map((item) => ({ id: item.id, name: item.name })),
        headquartersRegion: dataHeadquartersRegion?.value.map((item) => ({ id: item.id, name: item.name })),
      }));
    } catch (error) {
      toast('danger', error.message);
    }
  }

  async function handleSubmmit() {
    clearError();
    setIsLoadingContent(true);
    try {
      await B2BDatabaseService.store({ data: filters });
      toast('success', 'Csv imported successfully');
    } catch (error) {
      const errorObj = JSON.parse(error.message);
      const message = errorObj.code === 409 ? handleWrongFieldsIfError(errorObj.message) : errorObj.message;
      setError(true);
      setErrorContent({ code: errorObj.code, message });
    } finally {
      setIsLoadingContent(false);
    }
  }

  function handleFile(file) {
    const fileArr = Array.from(file);
    setFilters((prevState) => ({ ...prevState, file: fileArr }));
  }

  function handleOptionChange(event) {
    setFilters((prevState) => ({ ...prevState, typeBase: event.target.value }));
  }

  function handleToogleBtn() {
    if (filters.id && filters.name && filters.typeBase) {
      setIsDisabledBtn(false);
    } else {
      setIsDisabledBtn(true);
    }
  }

  function clearError() {
    setError(false);
    setErrorContent({ code: '', message: '' });
  }

  function handleWrongFieldsIfError(string) {
    const itensWrong = [];
    const arr = JSON.parse(string);
    const newArr = new Set(arr);
    newArr.forEach((field) => itensWrong.push(field));
    return JSON.stringify(itensWrong);
  }

  return (
    <Container>
      <TitlePage title="B2B Database - Add list" />
      <ContainerIDAndName>
        <FormContainer>
          <label htmlFor="id">ID *</label>
          <input
            type="text"
            id="id"
            name="id"
            alt="ID Base"
            value={filters.id}
            onChange={(event) => setFilters((prevState) => ({ ...prevState, id: event.target.value }))}
          />
        </FormContainer>
        <FormContainer>
          <label htmlFor="name">{translate.labelDataName}</label>
          <input
            type="text"
            id="name"
            name="name"
            alt="Base name"
            value={filters.name}
            onChange={(event) => setFilters((prevState) => ({ ...prevState, name: event.target.value }))}
          />
        </FormContainer>
      </ContainerIDAndName>
      <ContainerInputs>
        <FormContainer>
          <label htmlFor="sector">{translate.labelSector}</label>
          <select
            name="sector"
            id="sector"
            value={filters.sector}
            onChange={(event) => setFilters((prevState) => ({ ...prevState, sector: event.target.value }))}
          >
            <option value="">{translate.optionSelect}</option>
            {list.sector?.map((item) => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}
          </select>
        </FormContainer>
        <FormContainer>
          <label htmlFor="tecnology">{translate.labelTecnology}</label>
          <select
            name="tecnology"
            id="tecnology"
            value={filters.tecnology}
            onChange={(event) => setFilters((prevState) => ({ ...prevState, technology: event.target.value }))}
          >
            <option value="">{translate.optionSelect}</option>
            {list.technology?.map((item) => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}
          </select>
        </FormContainer>
        <FormContainer>
          <label htmlFor="employeesQuantity">{translate.labelEmployeesQty}</label>
          <select
            name="employeesQuantity"
            id="employeesQuantity"
            value={filters.employeesQuantity}
            onChange={(event) => setFilters((prevState) => ({ ...prevState, employeesQuantity: event.target.value }))}
          >
            <option value="">{translate.optionSelect}</option>
            {list.employeesQuantity?.map((item) => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}
          </select>
        </FormContainer>
        <FormContainer>
          <label htmlFor="annualRevenue">{translate.labelAnnualRevenue}</label>
          <select
            name="annualRevenue"
            id="annualRevenue"
            value={filters.annualRevenue}
            onChange={(event) => setFilters((prevState) => ({ ...prevState, annualRevenue: event.target.value }))}
          >
            <option value="">{translate.optionSelect}</option>
            {list.annualRevenue?.map((item) => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}
          </select>
        </FormContainer>
        <FormContainer>
          <label htmlFor="headquartersRegion">{translate.labelHeadquartersRegion}</label>
          <select
            name="headquartersRegion"
            id="headquartersRegion"
            value={filters.headquartersRegion}
            onChange={(event) => setFilters((prevState) => ({ ...prevState, headquartersRegion: event.target.value }))}
          >
            <option value="">{translate.optionSelect}</option>
            {list.headquartersRegion?.map((item) => (
              <option value={item.id} key={item.id}>{item.name}</option>
            ))}
          </select>
        </FormContainer>
      </ContainerInputs>
      <ContainerRatio>
        <div>
          <label htmlFor="type2">{translate.labelNotVerifiedData}</label>
          <input
            type="radio"
            value="notVerified"
            checked={filters.typeBase === 'notVerified'}
            onChange={handleOptionChange}
          />
        </div>
        <div>
          <label htmlFor="type1">{translate.labelVerifiedData}</label>
          <input
            type="radio"
            value="verified"
            checked={filters.typeBase === 'verified'}
            onChange={handleOptionChange}
          />
        </div>
      </ContainerRatio>
      <FormContainer>
        <input
          type="file"
          name="file"
          onChange={(event) => handleFile(event.target.files)}
        />
      </FormContainer>
      <Button
        color="white"
        disabled={isDisableBtn || isLoadingContent}
        onClick={handleSubmmit}
      >
        {translate.btnSubmit}
      </Button>

      {isLoadingContent && (
        <ContainerLoading>
          <Loader loading={isLoadingContent} />
          <p>{translate.msgLoading}</p>
        </ContainerLoading>
      )}

      {error && (
        <ContainerError>
          {JSON.stringify(errorContent.code)} - {JSON.stringify(errorContent.message)}
        </ContainerError>
      )}
    </Container>
  );
}

