import React, { useEffect, useState } from 'react';

import { useParams, useLocation } from 'react-router-dom';

import VideosRatingService from '../../../services/VideosRating';

import TitlePage from '../../../components/TitlePage';
import Square from '../../../components/Square';
import Loader from '../../../components/Loader';
import { RatingItem } from '../../../components/RatingItem';
import { NoDataMessage } from '../../../components/NoDataMessage';

import { Translate } from './translate';
import { Container } from './style';

export function RatingsVideos() {
  const translate = Translate();
  const location = useLocation();
  const { idVideo } = useParams();
  const [ratings, setRatings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const startFilteredQueryParam = new URLSearchParams(location.search).get('s');

  useEffect(() => {
    getAllComments();
  }, []);

  async function getAllComments() {
    setIsLoading(true);
    if (idVideo) {
      const ratingsDB = await VideosRatingService.findAllComments({ idVideo, startFilteredQueryParam });
      setRatings(ratingsDB);
    }
    setIsLoading(false);
  }

  return (
    <>
      <TitlePage title={translate.title} />
      {isLoading && <Loader loading={isLoading} />}
      {(!isLoading && ratings.length === 0) && (
        <NoDataMessage>{translate.message}</NoDataMessage>
      )}
      {(!isLoading && ratings.length > 0) && (
        <Container>
          {ratings && (
            ratings.map((rating) => (
              <RatingItem key={rating.id} rating={rating} />
            ))
          )}
        </Container>
      )}
    </>
  );
}
