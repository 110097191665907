import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CategoryProductService from '../../../../../services/CategoryProduct';
import SubCategoryProductService from '../../../../../services/SubCategoryProduct';
import ProductsService from '../../../../../services/Products';
import PostsProductService from '../../../../../services/PostsProduct';
import toast from '../../../../../helpers/toast';
import { generateSlug } from '../../../../../helpers/generateSlug';

function usePostAdd() {
  const navigate = useNavigate();

  const [post, setPost] = useState({
    namePt: '', nameEn: '', ordering: 0, slug: '', idSubCategory: '',
  });
  const [productsList, setProductList] = useState([]);
  const [productSelected, setProductSelected] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategorySelected, setSubCategorySelected] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoadingContent, setIsLoadingContent] = useState(true);

  useEffect(() => {
    LoadAndSetsLists();
  }, []);

  useEffect(() => {
    verifyFormToSave();
  }, [post]);

  useEffect(() => {
    findAllCategoriesByProduct(productSelected);
  }, [productSelected]);

  useEffect(() => {
    findAllSubCategoriesByProductAndCategory(productSelected, categorySelected);
  }, [categorySelected]);

  async function LoadAndSetsLists() {
    const productsList = await ProductsService.findAll({ firstVideo: 'yes' });
    if (productsList.length > 0) {
      setProductList(productsList);
      setProductSelected(productsList[0].id);
      findAllCategoriesByProduct(productsList[0].id);
    } else {
      setProductList([]);
      setProductSelected(null);
    }
    setIsLoadingContent(false);
  }

  async function findAllCategoriesByProduct(id) {
    const categoriesByProduct = await CategoryProductService.findAll(id);
    if (categoriesByProduct.length > 0) {
      setCategorySelected(categoriesByProduct[0].id);
      setCategoryList(categoriesByProduct);
      findAllSubCategoriesByProductAndCategory(productSelected, categoriesByProduct[0].id);
    } else {
      setCategorySelected(null);
      setCategoryList([]);
    }
  }

  async function findAllSubCategoriesByProductAndCategory(idProduct, idCategory) {
    const subCategories = await SubCategoryProductService.findAllByProductAndCategory(idProduct, idCategory);
    const subCategoriesFiltered = subCategories.filter((subCategory) => subCategory.hasPosts === 'Y');
    setSubCategoryList(subCategoriesFiltered);
    if (subCategoriesFiltered.length > 0) {
      setSubCategorySelected(subCategoriesFiltered[0].id.toString());
      setPost({ ...post, idSubCategory: subCategoriesFiltered[0].id.toString() });
    } else {
      setSubCategorySelected(null);
      setPost({ ...post, idSubCategory: null });
      setSubCategoryList([]);
    }
  }

  async function save() {
    try {
      setIsDisabled(true);
      await PostsProductService.store(post);
      toast('success', 'Post Created Succesfully');
    } catch (error) {
      toast('danger', error.message);
    } finally {
      navigate('/admin/posts');
    }
  }

  function verifyFormToSave() {
    const namePtIsValid = post.namePt.length >= 3;
    const nameEnIsValid = post.nameEn.length >= 3;
    const slugIsValid = post.slug.length > 0;
    const subCategoryIsValid = Boolean(post.idSubCategory);

    if (namePtIsValid && nameEnIsValid && slugIsValid && subCategoryIsValid) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  function handleGenerateSlug() {
    setPost({ ...post, slug: generateSlug(post?.namePt) });
  }

  return {
    post,
    setPost,
    productsList,
    productSelected,
    setProductSelected,
    categoryList,
    categorySelected,
    setCategorySelected,
    subCategoryList,
    subCategorySelected,
    setSubCategorySelected,
    isDisabled,
    verifyFormToSave,
    handleGenerateSlug,
    save,
    isLoadingContent,
  };
}

export default usePostAdd;
