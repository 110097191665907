export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    title: language === 'pt-BR' ? 'Filtros' : 'Filters',
    database: language === 'pt-BR' ? 'Base de dados' : 'Databases',
    selectedFilter: language === 'pt-BR' ? 'Filtros pré-selecionados' : 'Pre-selected filters',
    role: language === 'pt-BR' ? 'Cargo' : 'Role',
    country: language === 'pt-BR' ? 'País' : 'Country',
    state: language === 'pt-BR' ? 'Estado' : 'State',
    city: language === 'pt-BR' ? 'Cidade' : 'City',
    sector: language === 'pt-BR' ? 'Setor' : 'Sector',
    company: language === 'pt-BR' ? 'Empresa' : 'Company',
    exactPositive: language === 'pt-BR' ? 'Exato positivo' : 'Exact positive',
    constainsPositive: language === 'pt-BR' ? 'Contém positivo' : 'Contains positive',
    exactNegative: language === 'pt-BR' ? 'Exato negativo' : 'Exact negative',
    containsNegative: language === 'pt-BR' ? 'Contém negativo' : 'Contains negative',
    btnApply: language === 'pt-BR' ? 'Aplicar' : 'Apply',
  };
}
