import {
  useEffect, createContext, useMemo, useState,
} from 'react';
import B2BDatabaseFiltersService from '../../../../services/B2BDatabaseFilters';
import { Translate } from './translate';

const B2BDataBaseFilterContext = createContext();

function B2BDataBaseFilterProvider({ children }) {
  const translate = Translate();
  const [filtersList, setFiltersList] = useState({
    emailFirst: [],
    emailSecond: [],
    nameFirst: [],
    nameMiddle: [],
    nameLast: [],
    country: [],
    state: [],
    city: [],
    url: [],
    position: [],
    sector: [],
    technology: [],
    employeesQuantity: [],
    annualRevenue: [],
    headquartersRegion: [],
    company: [],
    companyDomain: [],
    linkedinID: [],
    listName: [],
    createdAt: [],
    typeBase: '',
  });
  const [filterSelected, setFilterSelected] = useState({
    emailFirst: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    emailSecond: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    nameFirst: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    nameMiddle: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    nameLast: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    country: [],
    state: [],
    city: [],
    url: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    position: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    sector: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    technology: [],
    employeesQuantity: [],
    annualRevenue: [],
    headquartersRegion: [],
    company: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    companyDomain: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    linkedinID: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    listName: [],
    listMeta: '',
    typeBase: '',
  });
  const [filterApplied, setFilterApplied] = useState({
    emailFirst: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    emailSecond: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    nameFirst: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    nameMiddle: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    nameLast: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    country: [],
    state: [],
    city: [],
    url: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    position: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    sector: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    technology: [],
    employeesQuantity: [],
    annualRevenue: [],
    headquartersRegion: [],
    company: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    companyDomain: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    linkedinID: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    listName: [],
    listMeta: '',
    typeBase: '',
  });
  const [searchTerms, setSearchTerms] = useState({
    emailFirst: '',
    emailSecond: '',
    nameFirst: '',
    nameMiddle: '',
    nameLast: '',
    url: '',
    position: '',
    sector: '',
    company: '',
    companyDomain: '',
    linkedinID: '',
  });
  const [isLoading, setIsLoading] = useState({
    emailFirst: false,
    emailSecond: false,
    nameFirst: false,
    nameMiddle: false,
    nameLast: false,
    url: false,
    position: false,
    sector: false,
    company: false,
    companyDomain: false,
    linkedinID: false,
  });
  const [filterDescription, setFilterDescription] = useState(translate.selectedFilter);
  const [filterPreSelectedFilter, setFilterPreSelectedFilter] = useState(true);
  const [handleClickApply, setHandleClickApply] = useState(false);

  useEffect(() => {
    getTechnologyList();
    getRegionsList();
    getEmployeesQuantityList();
    getAnnualRevenueList();
    getHeadquartersRegionList();
    getListNameList();
  }, []);

  useEffect(() => {
    updateStatesAndCitiesList();
  }, [filterSelected.country]);

  useEffect(() => {
    updateCitiesList();
  }, [filterSelected.state]);

  // Fill the list of email First's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.emailFirst) {
        setFiltersList((prevState) => ({ ...prevState, emailFirst: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, emailFirst: true }));
        const list = await B2BDatabaseFiltersService.findFirstEmail({
          searchTerm: searchTerms.emailFirst,
          hideTerms: filterSelected.emailFirst.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, emailFirst: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, emailFirst: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, emailFirst: [] }));
    };
  }, [searchTerms.emailFirst]);

  // Fill the list of email Second's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.emailSecond) {
        setFiltersList((prevState) => ({ ...prevState, emailSecond: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, emailSecond: true }));
        const list = await B2BDatabaseFiltersService.findSecondEmail({
          searchTerm: searchTerms.emailSecond,
          hideTerms: filterSelected.emailSecond.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, emailSecond: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, emailSecond: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, emailSecond: [] }));
    };
  }, [searchTerms.emailSecond]);

  // Fill the list of name First's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.nameFirst) {
        setFiltersList((prevState) => ({ ...prevState, nameFirst: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, nameFirst: true }));
        const list = await B2BDatabaseFiltersService.findFirstName({
          searchTerm: searchTerms.nameFirst,
          hideTerms: filterSelected.nameFirst.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, nameFirst: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, nameFirst: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, nameFirst: [] }));
    };
  }, [searchTerms.nameFirst]);

  // Fill the list of name Middle's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.nameMiddle) {
        setFiltersList((prevState) => ({ ...prevState, nameMiddle: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, nameMiddle: true }));
        const list = await B2BDatabaseFiltersService.findMiddleName({
          searchTerm: searchTerms.nameMiddle,
          hideTerms: filterSelected.nameMiddle.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, nameMiddle: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, nameMiddle: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, nameMiddle: [] }));
    };
  }, [searchTerms.nameMiddle]);

  // Fill the list of name Last's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.nameLast) {
        setFiltersList((prevState) => ({ ...prevState, nameLast: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, nameLast: true }));
        const list = await B2BDatabaseFiltersService.findLastName({
          searchTerm: searchTerms.nameLast,
          hideTerms: filterSelected.nameLast.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, nameLast: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, nameLast: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, nameLast: [] }));
    };
  }, [searchTerms.nameLast]);

  // Fill the list of Url's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.url) {
        setFiltersList((prevState) => ({ ...prevState, url: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, url: true }));
        const list = await B2BDatabaseFiltersService.findUrl({
          searchTerm: searchTerms.url,
          hideTerms: filterSelected.url.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, url: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, url: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, url: [] }));
    };
  }, [searchTerms.url]);

  // Fill the list of position's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.position) {
        setFiltersList((prevState) => ({ ...prevState, position: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, position: true }));
        const list = await B2BDatabaseFiltersService.findPositions({
          searchTerm: searchTerms.position,
          hideTerms: filterSelected.position.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, position: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, position: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, position: [] }));
    };
  }, [searchTerms.position]);

  // Fill the list of sector's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.sector) {
        setFiltersList((prevState) => ({ ...prevState, sector: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, sector: true }));
        const list = await B2BDatabaseFiltersService.findSector({
          searchTerm: searchTerms.sector,
          hideTerms: filterSelected.sector.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, sector: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, sector: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, sector: [] }));
    };
  }, [searchTerms.sector]);

  // Fill the list of company's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.company) {
        setFiltersList((prevState) => ({ ...prevState, company: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, company: true }));
        const list = await B2BDatabaseFiltersService.findCompany({
          searchTerm: searchTerms.company,
          hideTerms: filterSelected.company.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, company: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, company: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, company: [] }));
    };
  }, [searchTerms.company]);

  // Fill the list of company Domain's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.companyDomain) {
        setFiltersList((prevState) => ({ ...prevState, companyDomain: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, companyDomain: true }));
        const list = await B2BDatabaseFiltersService.findCompanyDomain({
          searchTerm: searchTerms.companyDomain,
          hideTerms: filterSelected.companyDomain.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, companyDomain: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, companyDomain: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, companyDomain: [] }));
    };
  }, [searchTerms.companyDomain]);

  // Fill the list of linkedin ID's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.linkedinID) {
        setFiltersList((prevState) => ({ ...prevState, linkedinID: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, linkedinID: true }));
        const list = await B2BDatabaseFiltersService.findLinkedinID({
          searchTerm: searchTerms.linkedinID,
          hideTerms: filterSelected.linkedinID.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, linkedinID: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, linkedinID: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, linkedinID: [] }));
    };
  }, [searchTerms.linkedinID]);

  // Change filter name
  useEffect(() => {
    setHandleClickApply(false);
    setFilterDescription(translate.selectedFilter);
    setFilterPreSelectedFilter(true);
  }, [filterSelected]);

  function applyFilter() {
    setFilterApplied(filterSelected);
    setFilterDescription(translate.appliedFilter);
    setFilterPreSelectedFilter(false);
    setHandleClickApply(true);
  }

  async function getRegionsList() {
    try {
      const regions = await B2BDatabaseFiltersService.findRegions({
        country: filtersList.country,
        state: filterSelected.state,
        city: filterSelected.city,
      });
      setFiltersList((prevState) => ({
        ...prevState,
        country: regions.country,
        state: regions.state,
        city: regions.city,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function getTechnologyList() {
    try {
      const technologiesList = await B2BDatabaseFiltersService.findTechnology();
      setFiltersList((prevState) => ({
        ...prevState,
        technology: technologiesList,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function getEmployeesQuantityList() {
    try {
      const employeesQuantityList = await B2BDatabaseFiltersService.findEmployeesQuantity();
      setFiltersList((prevState) => ({
        ...prevState,
        employeesQuantity: employeesQuantityList,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function getAnnualRevenueList() {
    try {
      const annualRevenueList = await B2BDatabaseFiltersService.findAnnualRevenue();
      setFiltersList((prevState) => ({
        ...prevState,
        annualRevenue: annualRevenueList,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function getHeadquartersRegionList() {
    try {
      const headquartersRegionList = await B2BDatabaseFiltersService.findHeadquartersRegion();
      setFiltersList((prevState) => ({
        ...prevState,
        headquartersRegion: headquartersRegionList,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function getListNameList() {
    try {
      const listNameList = await B2BDatabaseFiltersService.findListName();
      setFiltersList((prevState) => ({
        ...prevState,
        listName: listNameList,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function updateStatesAndCitiesList() {
    try {
      const regions = await B2BDatabaseFiltersService.findRegions({
        country: filterSelected.country,
        state: [],
        city: [],
      });
      setFiltersList((prevState) => ({
        ...prevState,
        state: regions.state,
        city: regions.city,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function updateCitiesList() {
    try {
      const regions = await B2BDatabaseFiltersService.findRegions({
        country: filterSelected.country,
        state: filterSelected.state,
        city: [],
      });
      setFiltersList((prevState) => ({
        ...prevState,
        city: regions.city,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  function updateFilterSelectedExact(key, item) {
    // Includes value at selected exact list
    const newFilterList = JSON.parse(JSON.stringify(filterSelected));
    newFilterList[key].exact.push(item);
    setFilterSelected(newFilterList);

    // Delete value at filter list
    const newArr = filtersList[key].filter((it) => it !== item);
    setFiltersList((prevState) => ({ ...prevState, [key]: newArr }));
  }

  function updateFilterSelected({ key, subKey, value }) {
    // Includes value at selected exact list
    const newFilterList = JSON.parse(JSON.stringify(filterSelected));
    newFilterList[key][subKey].push(value);
    setFilterSelected(newFilterList);
  }

  function deleteOneItemFromSelectedFilters(key, subKey, value) {
    const arrayDeletedValue = filterSelected[key][subKey].filter((item) => item !== value);
    const newObj = { ...filterSelected, [key]: { ...filterSelected[key], [subKey]: arrayDeletedValue } };
    setFilterSelected(newObj);
  }

  function deleteAllItemsFromSelectedFilters(key, subKey) {
    const newObj = { ...filterSelected, [key]: { ...filterSelected[key], [subKey]: [] } };
    setFilterSelected(newObj);
  }

  function clearAllLists(key) {
    setFilterSelected((prevState) => ({
      ...prevState,
      [key]: {
        exact: [],
        contains: [],
        exactNegative: [],
        containsNegative: [],
      },
    }));
  }

  const contextValues = useMemo(() => ({
    filtersList,
    filterSelected,
    filterApplied,
    setFilterSelected,
    filterPreSelectedFilter,
    setSearchTerms,
    isLoading,
    updateFilterSelectedExact,
    updateFilterSelected,
    deleteOneItemFromSelectedFilters,
    deleteAllItemsFromSelectedFilters,
    clearAllLists,
    filterDescription,
    applyFilter,
    handleClickApply,
  }), [
    filtersList,
    filterSelected,
    filterApplied,
    setFilterSelected,
    filterPreSelectedFilter,
    setSearchTerms,
    isLoading,
    updateFilterSelectedExact,
    updateFilterSelected,
    deleteOneItemFromSelectedFilters,
    deleteAllItemsFromSelectedFilters,
    clearAllLists,
    filterDescription,
    applyFilter,
    handleClickApply,
  ]);

  return (
    <B2BDataBaseFilterContext.Provider value={contextValues}>
      {children}
    </B2BDataBaseFilterContext.Provider>
  );
}

export { B2BDataBaseFilterContext, B2BDataBaseFilterProvider };
