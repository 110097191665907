import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 32px;

  h2{
    margin-bottom: 16px;
  }
`;

export const ContainerVideoArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  }
`;

export const CointainerVideo = styled.div`
  background-color: ${({ theme }) => theme.grayMenu};
  border-radius: 8px;
  padding: 16px;
`;

export const VideoArea = styled.div`
  width: 100%;

  .video {
    height: 200px;
    /* width: 100%; */

    > div {
      height: inherit !important;
      border-radius: 4px !important;
    }

    iframe {
      height: 200px !important;
      border-radius: 4px !important;
      max-width: 100%;
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
    margin-top: 4px;
  }
`;

export const VideoActionsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

export const VideoLikesArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover .container-like {
    transform: scale(1.3);
  }

  .container-like {
    cursor: pointer;
    transition: 0.2s;

    img {
      width: 15px;
    }
  }
`;

export const Likes = styled.div`
  color: ${({ theme }) => theme.gray4};
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;

export const VideoLinkArea = styled.div`
  a {
    display: flex;
    align-items: center;
    gap:4px;
    color: ${({ theme }) => theme.fontWhite};
    font-size: 14px;

    img {
      width: 13px;
    }
  }

  &:hover span, a:hover {
    color: ${({ theme }) => theme.yellow}
  }
`;
