/* eslint-disable array-callback-return */
import { B2BDataBaseFilterProvider } from './B2BDatabaseFilter.context';
import { B2BDataBaseDataProvider } from './B2BDatabaseData.context';
import { B2BDatabaseSearch } from './B2BDatabaseSearch';

export function B2BDatabaseSearchIndex() {
  return (
    <B2BDataBaseFilterProvider>
      <B2BDataBaseDataProvider>
        <B2BDatabaseSearch />
      </B2BDataBaseDataProvider>
    </B2BDataBaseFilterProvider>
  );
}
