export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    items: language === 'pt-BR' ? 'Itens' : 'Items',
    emailFirst: language === 'pt-BR' ? '1º E-mail' : '1st E-mail',
    emailSecond: language === 'pt-BR' ? '2º E-mail' : '2nd E-mail',
    nameFirst: language === 'pt-BR' ? 'Nome' : 'Name',
    nameMiddle: language === 'pt-BR' ? 'Nome meio' : 'Mid. name',
    nameLast: language === 'pt-BR' ? 'Último nome' : 'Last name',
    role: language === 'pt-BR' ? 'Cargo' : 'Role',
    sector: language === 'pt-BR' ? 'Setor' : 'Sector',
    country: language === 'pt-BR' ? 'País' : 'Country',
    state: language === 'pt-BR' ? 'Estado' : 'State',
    city: language === 'pt-BR' ? 'Cidade' : 'City',
    company: language === 'pt-BR' ? 'Empresa' : 'Company',
    companyDomain: language === 'pt-BR' ? 'Domínio' : 'Domain',
    domain: language === 'pt-BR' ? 'Domínio' : 'Domain',
    employeesQuantity: language === 'pt-BR' ? 'Funcionários' : 'Employees',
    annualRevenue: language === 'pt-BR' ? 'Receita' : 'Revenue',
    headquartersRegion: language === 'pt-BR' ? 'Sede' : 'Headquarters',
    createdAt: language === 'pt-BR' ? 'Criada em' : 'Created at',
    registerAt: language === 'pt-BR' ? 'Registrada em' : 'Registered at',
    technology: language === 'pt-BR' ? 'Tecnologia' : 'Technology',
    linkedinID: language === 'pt-BR' ? 'Linkedin ID' : 'Linkedin ID',
    listID: language === 'pt-BR' ? 'ID Lista' : 'List ID',
    listName: language === 'pt-BR' ? 'Nome lista' : 'List name',
    listMeta: language === 'pt-BR' ? 'Base Meta' : 'Meta data',
    selectedFilter: language === 'pt-BR' ? 'Filtros pré-selecionados' : 'Pre-selected filters',
    appliedFilter: language === 'pt-BR' ? 'Filtros aplicados' : 'Applied filters',
    modalAuthorized: language === 'pt-BR' ? 'Número de itens permitidos para download a cada 7 dias: ' : 'Number of items allowed for download every 7 days: ',
    modalRemaing: language === 'pt-BR' ? 'Total de itens restantes para download: ' : 'Total items remaining for download: ',
    modalUsed: language === 'pt-BR' ? 'Total de itens baixados: ' : 'Total items downloaded: ',
    modalDays: language === 'pt-BR' ? 'Próximo download autorizado em (dias): ' : 'Next download authorized on (days): ',
    itemsTable: language === 'pt-BR' ? 'itens' : 'items',
    totalItems: language === 'pt-BR' ? 'Total de itens' : 'total items',
    msgNoData: language === 'pt-BR' ? 'Nenhum dado encontrado com os filtros selecionados' : 'No data found with the selected filters',
    of: language === 'pt-BR' ? 'de' : 'of',
    pages: language === 'pt-BR' ? 'Páginas' : 'Pages',
    language,
  };
}
