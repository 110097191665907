import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-top: 16px;

  a {
    width: 100%;
    text-align: center;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
    flex-direction: column;
  }

  .c-products {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
      align-self: flex-start;
      margin-bottom: 16px;
    }
  }

  .c-products-name {
    /* width: 200px; */
    border-left: 5px solid ${({ theme, $color }) => (!$color ? theme.fontWhite : theme[$color])};
    padding-left: 8px;
  }

  .c-products-action {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}){
      align-self: flex-end;
    }

    img, a, span {
      width: 20px;
      height: 20px;
    }

    img:hover {
      cursor: pointer;
      filter: invert(40%) sepia(6%) saturate(233%) hue-rotate(187deg) brightness(95%) contrast(84%)
    }

    input {
      max-width: 40px !important;
      border: 1px solid ${({ theme }) => theme.grayLight};
    }
  }
`;
